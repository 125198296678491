// In ProtectedRoute.js
//import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

import Spinner from 'react-bootstrap/Spinner';
import logo_white from '../assets/images/logo_white.png';
import logo_navy from '../assets/images/logo_navy.png';


import { Container, Row, Col } from 'react-bootstrap';
import Loading from '../components/common/Loading';

import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
//import SignIn from './SignIn';
//import SignUp from './SignUp';
//import StyledModalClose from './StyledModalClose';
import { StyledModal, StyledButton, StyledParagraph, StyledH4 } from '../components/common/StyledBootstrap';
import { useNavigate } from 'react-router-dom';
//import PublicHome from './OldPublicHome';
import { useTheme } from 'styled-components';

/*const SignInSignUpModal = ({ show, handleClose }) => {
    const navigate = useNavigate();

    return (
        <StyledModalClose show={show} onHide={handleClose} centered title={"Sign In To Continue..."}>
            <StyledModal.Body>
                <StyledParagraph>
                    Please sign in before continuing. If you don't have an account, please create one.
                </StyledParagraph>
            </StyledModal.Body>
            <StyledModal.Footer>
                <StyledButton onClick={() => navigate('/signin')} className='mx-3'>
                    Sign In
                </StyledButton>
                <StyledButton onClick={() => navigate('/signup')}>
                    Sign Up
                </StyledButton>

            </StyledModal.Footer>
        </StyledModalClose>
    );
};*/



export const ProtectedRoute = ({ children, requiredStage = null, signin = null }) => {
    const { token, accountStage } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();


    //const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const themeMode = useTheme();

    /*const handleShowModal = () => {
        setShowModal(true)
    };
    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/home');
    };*/

    useEffect(() => {
        if (!token) {
            //setIsLoading(true); // Show loading when no token
            // Simulate a delay to show the message and loading component
            const timeout = setTimeout(() => {
                setIsLoading(false); // Turn off loading after delay
            }, 1000); // Adjust delay time as needed

            return () => clearTimeout(timeout); // Clean up timeout on unmount or state change
        }
    }, [token]); // Run effect when token changes

    // if (signin && token)
    // {
    //     return <Navigate to="/dashboard" replace />;
    // }

    if (!signin && isLoading && !token) {
        // While loading, show a loading component or message
        return <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
            <Row className='justify-content-center text-center'>
                <div >
                    {/*<img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" />*/}
                    <StyledH4 className='mt-3'>Taking you to log in...</StyledH4>
                    <Spinner animation="grow"></Spinner>
                </div>
            </Row>
        </Container>; // Example loading component
    }

    if (!signin && !token && !isLoading) {
        // Redirect to sign in if not authenticated
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }

    /*if (accountStage > requiredStage) {
        return <Navigate to="/dashboard" replace />;
    }*/

    // if (requiredStage !== null && accountStage !== requiredStage) {
    //     // Redirect to an appropriate page based on account stage

    //     if (accountStage == '1') {
    //         return <Navigate to="/finish-registration" replace />;
    //     }
    //     if (accountStage == '3') {
    //         return <Navigate to="/dashboard" replace />;
    //     }

    //}

    return children;
};
