import { useTheme } from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// From my app
import { RegistrationContainer } from "../components/common/Containers";
import FormInput from "../components/forms/FormInput";
import { Form } from "../components/forms/Form";
import { PrimaryButton } from "../components/common/Buttons";
import { RegistrationHeader, Subtitle, PromptWrapper, Prompt } from "../components/forms/MiscComponents";
import { myAxiosInstance } from "../api/axiosConfig";


function PasswordResetEmail() {
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [emailSent, setEmailSent] = useState(false);

    const [loading, setLoading] = useState(false);

    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Email validation function
    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { email: "" };
    
        // Frontend validation
        if (!email.trim()) {
            newErrors.email = "Email is required";
        } else if (!isValidEmail(email)) {
            newErrors.email = "Enter a valid email address";
        }
    
        if (newErrors.email) {
            setErrors(newErrors);
            return; // Stop execution if frontend validation fails
        }
    
        try {
            setLoading(true);

            // Send API request
            const response = await myAxiosInstance.post('/prospinity-auth/password/reset/', { "email": email });

            // Display email sent message
            setEmailSent(true);

        } catch (error) {
            // Simple endpoint - a single error
            setErrors({ ...newErrors, email: "Something went wrong. Please try again later." });
        
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <RegistrationContainer isMobile={isMobile}>
            <RegistrationHeader isMobile={isMobile}>RESET PASSWORD</RegistrationHeader>
            {emailSent ? 
                <Subtitle theme={theme}>
                    We've sent an email to {email} with a link that you can use to reset your password.
                </Subtitle>
                :
                <>
                    <Subtitle theme={theme}>Please enter your email address to reset your password.</Subtitle>

                    <Form onSubmit={handleSubmit}>
                        <FormInput 
                            label="Email address" 
                            type="email" 
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setErrors((prev) => ({ ...prev, email: "" })); // Clear error when typing
                            }}
                            error={errors.email}
                        />

                        <PrimaryButton type="submit" disabled={loading} style={{ padding: "1rem", marginTop: "2rem" }} >{loading ? "Sending reset instructions..." : "RESET PASSWORD"}</PrimaryButton>
                    </Form>
                </>
            }
            <PromptWrapper emailSent={emailSent}>
                <Prompt theme={theme}>
                    Return to <Link to="/join/signin">Login</Link>
                </Prompt>
            </PromptWrapper>
            
        </RegistrationContainer>
    );
}

export default PasswordResetEmail;
