import React, { useEffect, useState, useRef } from 'react';
import { useTheme, styled } from 'styled-components';
import { useErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from 'react-responsive';

// From My Application
import { useAuth } from "../contexts/AuthContext";
import { myAxiosInstance } from "../api/axiosConfig";
import Loading from "../components/common/Loading";
import { MainContainer, FlexContainer, StyledHeader } from '../components/common/Containers';
import Banner from "../components/common/Banners";
import TaskProgress from '../components/home/Tasks';
import ThreeThingsSection from '../components/home/ThreeThingsSection';
import MainPoolPreview from '../components/pools/PoolPreview';
import { HomeUserPreviewCard } from "../components/users/UserPreviewCard";


const Home = () => {

    // Auth context
    const { reload, setReload, emailVerified, universityEmailVerified, userFirstName } = useAuth();

    // States
    const [isLoading, setIsLoading] = useState(true);
    const [homeData, setHomeData] = useState(null);

    // Errors
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Get the api data
    useEffect(() => {
      const getHoneData = async () => {
        try {
          const response = await myAxiosInstance.get("/api/home/");
          setHomeData(response.data); // Store the data
        } catch (error) {
            showBoundary(error);
        } finally {
            setIsLoading(false);
        }
      };

      getHoneData();
    }, [reload]);

    const getCurrentDate = () => {
        const options = { weekday: 'long', month: 'short', day: 'numeric' };
        return new Date().toLocaleDateString('en-US', options).toUpperCase();
    };

    if (isLoading) {
      return <Loading />;
    }

    return (
        <MainContainer theme={theme}>
            {/* Top Section */}
            <FlexContainer justify="space-between" align="center" wrap="wrap" gap="1rem" >
                {/* Welcome Section */}
                <FlexContainer direction="column" align="flex-start">
                    <p
                        style={{
                            fontSize: '0.875rem',
                            fontWeight: '500',
                            color: theme.textSecondary,
                            lineHeight: '1.25rem',
                            marginBottom: '0.8rem',
                        }}
                    >
                        {getCurrentDate()}
                    </p>
                    <StyledHeader theme={theme}>Welcome Back, {userFirstName}</StyledHeader>
  
                </FlexContainer>

                {/* Stats Section */}
                <FlexContainer gap="2rem" align="flex-start" wrap="wrap">
                    <Stat label="Your Pools" value={homeData?.user_details?.number_of_pools ?? "0"}  theme={theme} />
                    <Stat label="Portfolio Size" value={homeData?.user_details?.portfolio_count ?? "0"} theme={theme} />
                    <Stat label="Net Balance" value={`$${homeData?.user_details?.total_balance}` ?? "$0"} theme={theme} />
                </FlexContainer>
            </FlexContainer>

            {/* Banner */}
            <Banner bannerType={"not-matched"}/>

            {/* Tasks */}
            <TaskProgress />

            {/* 3 things to know */}
            <ThreeThingsSection />

            {/* The Pool */}
            {/* <MainPoolPreview /> */}

            {/* People You Might know */}
            <PeopleYouMightKnow initialUsers={homeData?.daily_picks ?? []} />

        </MainContainer>
    );
};

const Stat = ({ label, value , theme}) => (
    <FlexContainer direction="column" align="flex-start">
        <span
            style={{
                fontFamily: "Inter, sans-serif",
                fontSize: '1.5rem',
                fontWeight: '500',
                color: theme.textPrimary,
                lineHeight: '2rem',
                marginBottom: "0.1rem",
            }}
        >
            {value}
        </span>
        <span
            style={{
                fontSize: '0.875rem',
                fontWeight: '400',
                color: theme.textSecondary,
                lineHeight: '1.25rem',
            }}
        >
            {label}
        </span>
    </FlexContainer>
);


const PeopleYouMightKnow = ({ initialUsers }) => {
  const [users, setUsers] = useState(initialUsers); // Start with 4 users from /api/home
  const [page, setPage] = useState(2); // Start lazy loading from page 2
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Stops when no more users
  
  // Responsive
  const isNotDesktop = useMediaQuery({ minWidth: 1024 });

  // For Lazy Loading
  const sentinelRef = useRef(null);

  const loadMoreUsers = async () => {
    if (!hasMore || loading) return; // Stop if max pages reached or already loading

    setLoading(true);
    try {
      const response = await myAxiosInstance.get(`/api/daily-picks/?page=${page}`);
      if (response.data.length === 0) {
        setHasMore(false); // Stop loading if no more users
      } else {
        setUsers(prevUsers => [...prevUsers, ...response.data.results]); // Append new users
        setPage(prevPage => prevPage + 1); // Move to next page
      }
    } catch (error) {
      console.error("Error loading more users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!sentinelRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreUsers();
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(sentinelRef.current);

    return () => observer.disconnect();
  }, [users]);

  // ✅ Separate users explicitly into Left & Right columns
  const leftColumnUsers = users.filter((_, index) => index % 2 !== 0);
  const rightColumnUsers = users.filter((_, index) => index % 2 === 0);

  return (
    <div style={{ marginTop: "3rem" }}>
      <StyledHeader style={{ marginBottom: "1.5rem" }}>
        PEOPLE YOU <br /> MIGHT KNOW
      </StyledHeader>

      {/* Desktop: Two Columns (Left & Right) */}
      {isNotDesktop ? (
        <ZigZagContainer>
          <Column>
            {leftColumnUsers.map((user, index) => (
              <HomeUserPreviewCard key={`left-${index}`} user={user} id={index === leftColumnUsers.length - 1 ? "last-user" : null} />
            ))}
          </Column>

          <RightColumn>
            {rightColumnUsers.map((user, index) => (
              <HomeUserPreviewCard key={`right-${index}`} user={user} />
            ))}
          </RightColumn>
        </ZigZagContainer>
      ) : (
        // Mobile: Single Column Layout
        <SingleColumn>
          {users.map((user, index) => (
            <HomeUserPreviewCard key={`mobile-${index}`} user={user} id={index === users.length - 1 ? "last-user" : null} />
          ))}
        </SingleColumn>
      )}

      {/* Invisible sentinel div for lazy loading */}
      <div ref={sentinelRef} style={{ height: "1px", width: "100%", visibility: "hidden" }}></div>


      {/* Centered Lazy Loading Spinner */}
      {loading && (
        <div style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2rem",
        }}>
          <Loading />
        </div>
      )}
    </div>
  );
};


const ZigZagContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem; /* ✅ Spacing between left & right columns */
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem; /* ✅ Normal spacing */
`;

const RightColumn = styled(Column)`
  margin-top: -6rem; /* ✅ Offset Right Column by 2rem */
`;

const SingleColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem; /* ✅ Mobile: Even spacing */
`;


export default Home;




// import PoolPreviewCard, {HomePoolPreviewCard} from "./PoolPreviewCard";
// import React, { useEffect, useState, useCallback } from 'react';
// import axios from 'axios';
// import { Row, Col, Card, Container } from "react-bootstrap";
// import { useAuth } from "./AuthContext";
// import Loading from "./Loading";
// import { useErrorBoundary } from "react-error-boundary";
// import { StyledCard, StyledH5, StyledH4, StyledParagraph, StyledButton } from "./StyledBootstrap";
// import { myAxiosInstance } from "./axiosConfig";
// import { Link } from "react-router-dom";
// import UserPreviewCard from "./UserPreviewCard";
// import { useMediaQuery } from 'react-responsive';
// import { useNavigate } from "react-router-dom";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import RequestPreviewCard from "./RequestPreviewCard";


// const Home = () => {

//     const navigate = useNavigate();
//     const HorizontalSettings = {
//         dots: true,
//         infinite: true,
//         slidesToShow: 3,
//         slidesToScroll: 2,
//         autoplay: true,
//         speed: 7000,
//         autoplaySpeed: 7000,
//         cssEase: "linear",
//         arrows: false
//     };

//     const VerticalSettings = {
//         dots: true,
//         infinite: true,
//         slidesToShow: 5,
//         slidesToScroll: 1,
//         slidesPerRow: 2,
//         autoplay: true,
//         autoplaySpeed: 7000,

//         vertical: true,
//         speed: 5000,
//         infinite: true,
//         cssEase: "linear",

//     };

//     const themeMode = useTheme();

//     const [dataArray, setDataArray] = useState([]);
//     const [suggestedPoolArray, setSuggestedPoolArray] = useState([]);
//     const [suggestedUserArray, setSuggestedUserArray] = useState([]);
//     const [updatesArray, setUpdatesArray] = useState([]);
//     const [meetingsArray, setMeetingsArray] = useState([]);
//     const [isBottomLoading, setIsBottomLoading] = useState(true); // make this true
//     const [homeUserDetails, setHomeUserDetails] = useState(null);
//     const { reload, setReload, emailVerified, universityEmailVerified, poolingRequested, setPoolingRequested, poolingActivated } = useAuth();
//     const [bottomReload, setBottomReload] = useState(1);

//     const [resentEmailVerification, setResentEmailVerification] = useState(0);
//     const [resentUniversityEmailVerification, setResentUniversityEmailVerification] = useState(0);

//     const [activatePoolingButtonLoading, setActivatePoolingButtonLoading] = useState(false);


//     const handleActivatePoolingClick = async () => {
//         try {
//             setActivatePoolingButtonLoading(true);
//             const response = await myAxiosInstance.put('/api/enable-pooling/', { pooling_requested: true });
//             setPoolingRequested(true);
//         }
//         catch (error) {}
//         finally {
//             setActivatePoolingButtonLoading(false);
//         }

//     };


//     const { showBoundary } = useErrorBoundary();

//     useEffect(() => {
//         const getMyPoolsData = async () => {
//             try {

//                 const response = await myAxiosInstance.get('/api/home/');

//                 setDataArray(response.data.todo_pools || []);
//                 setHomeUserDetails(response.data.user_details);
//                 setSuggestedPoolArray(response.data.todo_pools);
//                 setSuggestedUserArray(response.data.daily_picks);
//                 setMeetingsArray(response.data.todo_meetings);
//                 setUpdatesArray(response.data.updates);

//             } catch (error) {
//                 showBoundary(error);
//             } finally {
//                 setIsBottomLoading(false);
//             }
//         };
//         getMyPoolsData();
//     }, [reload, bottomReload]);

//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });

//     return (
//         isBottomLoading ? <Loading /> : <>

//             <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
//                 <div className='home-joyrride mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>

//                     {poolingRequested && isMdOrLarger ? <><div><StyledH5 className="mt-4"><strong>Welcome, {homeUserDetails.first_name}</strong></StyledH5></div>

//                         <StyledCard>
//                             <StyledCard.Body>
//                                 <Container fluid>

//                                     <Row>
//                                         <Col className="d-flex flex-column justify-content-center align-items-center">
//                                             <div><StyledH4><strong>{homeUserDetails.number_of_pools}</strong></StyledH4></div>
//                                         </Col>
//                                         <Col className="d-flex flex-column justify-content-center align-items-center">
//                                             <div><StyledH4><strong>{homeUserDetails.portfolio_count}</strong></StyledH4></div>
//                                         </Col>
//                                         <Col className="d-flex flex-column justify-content-center align-items-center">
//                                             <div><StyledH4><strong>${homeUserDetails.total_balance}</strong></StyledH4></div>
//                                         </Col>

//                                     </Row>
//                                     <Row >
//                                         <Col className="d-flex flex-column justify-content-center">

//                                             <div className='text-center' >Your Pools</div>
//                                         </Col>
//                                         <Col className="d-flex flex-column justify-content-center">
//                                             <div className='text-center' >Portfolio Size</div>  {/*AMOLI CHANGE */}
//                                         </Col>
//                                         <Col className="d-flex flex-column justify-content-center">
//                                             <div className='text-center' >Net Balance</div>
//                                         </Col>
//                                     </Row>
//                                 </Container>
//                             </StyledCard.Body>
//                         </StyledCard></> : <></>}

//                     {!isMdOrLarger && (
//                         <>
//                             <StyledH5 className="mb-3 mt-3"><strong>Daily Picks</strong></StyledH5>
//                             <StyledCard className="mt-3">
//                                 <StyledCard.Body>

//                                     <div style={{ display: 'flex', overflowX: 'scroll', paddingTop: '10px', paddingBottom: '10px' }}>
//                                         {suggestedUserArray.filter(otherUserInfo => otherUserInfo.first_name !== '').map((otherUserInfo, index) => (
//                                             <div className="mx-2" key={index}>
//                                                 <UserPreviewCard preview_object={otherUserInfo} buttonBoolean={true} bottomReload={bottomReload} setBottomReload={setBottomReload} />
//                                             </div>
//                                         ))}
//                                     </div>
//                                 </StyledCard.Body>
//                             </StyledCard>
//                         </>
//                     )}


//                     {/*!emailVerified || !universityEmailVerified ?
//                         <StyledCard className="mt-3">
//                             <StyledCard.Body>
//                                 <Container fluid>
//                                     <Row>
//                                         <div><StyledH5><strong>Email Verification</strong></StyledH5></div>
//                                     </Row>
//                                     <Row>
//                                         <StyledParagraph style={{ fontSize: '1.2rem' }} className="mt-3">
//                                             To join pools (and get a green checkmark), please verify your email(s).
//                                         </StyledParagraph>
//                                         {emailVerified ? null : (
//                                             <span
//                                                 style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
//                                                 className='mt-2'
//                                                 onClick={() => sendVerificationEmail(false)}
//                                             >
//                                                 {resentEmailVerification === 0 ? 'Resend Email Verification' :
//                                                     resentEmailVerification === 1 ? <Loading /> : 'Check Your Email'}
//                                             </span>
//                                         )}
//                                         {universityEmailVerified ? null : (
//                                             <span
//                                                 style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
//                                                 className='mt-2'
//                                                 onClick={() => sendVerificationEmail(true)}
//                                             >
//                                                 {resentUniversityEmailVerification === 0 ? 'Resend University Email Verification' :
//                                                     resentUniversityEmailVerification === 1 ? <Loading /> : 'Check Your University Email'}
//                                             </span>
//                                         )}

//                                     </Row>
//                                 </Container>
//                             </StyledCard.Body>
//                         </StyledCard> : <></>*/}
//                     {!poolingActivated && !poolingRequested && isMdOrLarger ? <>
//                         <div className="mt-3">
//                             <StyledH5><strong>Activate Pooling</strong></StyledH5>
//                         </div>
//                         <StyledCard style={{ position: 'relative' }}>
//                             <StyledCard.Body>
//                                 <div>
//                                     We are currently only allowing a select group of students to access success pools. If you are interested, click activate pooling, and we will screen your profile and reach out if you are selected.
//                                 </div>

//                                 {poolingRequested ? <StyledButton className='mt-1' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
//                                     Applied
//                                 </StyledButton> :
//                                     <StyledButton onClick={handleActivatePoolingClick} className='mt-2'>
//                                         {activatePoolingButtonLoading ? <Loading /> : <>Activate Pooling</>}
//                                     </StyledButton>}

//                             </StyledCard.Body>

//                         </StyledCard></> : <></>}


//                     <div className="mt-3">
//                         <StyledH5><strong>For You</strong></StyledH5>
//                     </div>
//                     <StyledCard style={{ position: 'relative' }}>
//                         {(meetingsArray.length == 0 &&  dataArray.length == 0) ? <StyledCard.Body style={{ fontSize: '1.2rem' }}>No portfolio requests or pool invites. Add people to your portfolio from Daily Picks.</StyledCard.Body> :
//                             <>
//                                 <StyledCard.Body className='d-flex' style={{
//                                     overflowX: 'auto',
//                                     whiteSpace: 'nowrap',
//                                     gap: '1rem',
//                                     paddingRight: '1.5rem',
//                                     minWidth: 0,
//                                     width: '100%'  // Ensure there's space on the right for the gradient
//                                 }}>
//                                     {meetingsArray.map((meeting) => (
//                                         <div className="mx-1 my-1">
//                                             <RequestPreviewCard meeting={meeting} bottomReload={bottomReload} setBottomReload={setBottomReload} />
//                                         </div>
//                                     ))}
//                                     {dataArray.map((pool) => (
//                                         <div className="mx-1 my-1">
//                                             <HomePoolPreviewCard data={pool} />
//                                         </div>
//                                     ))}
//                                 </StyledCard.Body>
//                                 {isMdOrLarger && themeMode.theme=="light" &&
//                                 <div style={{
//                                     position: 'absolute',
//                                     top: 0,
//                                     right: 0,
//                                     width: '50px',  // Adjust width as needed
//                                     height: '100%',
//                                     background: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
//                                     pointerEvents: 'none'  // Allow the scrollable content to be interacted with
//                                 }}></div>}</>}
//                     </StyledCard>



//                     {updatesArray.length == 0 ? <></> :
//                         <>
//                             <div className="mt-3"><StyledH5><strong>Updates</strong></StyledH5></div>
//                             {updatesArray.map((update) => (
//                                 <StyledCard
//                                     onClick={() => { navigate("/" + update.redirect_link); }}
//                                     className='my-2'
//                                     style={{
//                                         cursor: 'pointer', // Change cursor to pointer
//                                         transition: 'transform 0.3s ease', // Smooth transition for scaling
//                                     }}
//                                     onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.01)'} // Slightly larger on hover
//                                     onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'} // Back to original size on hover out
//                                 >
//                                     <div className="d-flex mx-2 my-2 px-1 py-1 align-items-center">
//                                         <img
//                                             src={update.profile_pic}
//                                             alt={"Preview"}
//                                             onError={(e) => { e.target.src = 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'; }}
//                                             style={{
//                                                 width: '3rem', // Adjust width for less height
//                                                 height: '3rem', // Adjust height for less height
//                                                 borderRadius: '50%',
//                                                 marginRight: '1.5rem'
//                                             }}
//                                         />
//                                         <div style={{ fontSize: '1.1rem' }}>
//                                             {update.message}

//                                         </div>
//                                     </div>


//                                 </StyledCard>

//                             ))}</>}




//                 </div>



//                 {/*isMdOrLarger && (
//                     <div className="mx-2">
//                         <StyledH5 className="mt-4"><strong>Daily Picks</strong></StyledH5>

//                         <StyledCard style={{ height: '80vh', overflowY: 'auto' }}>
//                             <StyledCard.Body>
//                                 {suggestedUserArray
//                                     .filter(otherUserInfo => otherUserInfo.first_name !== '')
//                                     .reduce((acc, curr, index) => {
//                                         if (index % 2 === 0) {
//                                             // Every even index, create a new row with two elements
//                                             acc.push([curr]);
//                                         } else {
//                                             // Add the current item to the last row
//                                             acc[acc.length - 1].push(curr);
//                                         }
//                                         return acc;
//                                     }, [])
//                                     .map((pair, pairIndex) => (
//                                         <div className="d-flex align-items-center justify-content-center" key={pairIndex}>
//                                             {pair.map((otherUserInfo, index) => (
//                                                 <div className="mx-1 my-2" key={index}>
//                                                     <UserPreviewCard preview_object={otherUserInfo} buttonBoolean={true} bottomReload={bottomReload} setBottomReload={setBottomReload} />
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     ))}

//                             </StyledCard.Body>
//                         </StyledCard>
//                     </div>


//                 )*/}



//             </div >

//         </>
//     );
// }

// export default Home;
