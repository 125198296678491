import { styled } from "styled-components";

const BannerContainer = styled.div`
    width: 100%;
    max-width: 1200px; /* Ensures banner stays within page */
    margin-top: 1.5rem; /* Centers the banner */
    background: url(${(props) => props.bgImage}) center/100% 100% no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 1.5rem;

    @media (max-width: 1024px) {
        padding: 1.5rem;
    }

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;


/* ✅ Inner FlexContainer */
const FlexContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @media (max-width: 1024px) {
    flex-wrap: wrap; /* Stack on smaller screens */
    justify-content: center;
  }
`;

/* ✅ Search Icon Wrapper with Background */
const SearchIconWrapper = styled.div`
  width: clamp(55px, 4.5vw, 70px);
  height: clamp(55px, 4.5vw, 70px);
  background: ${({ isMatched }) => (isMatched ? "#3BB75E" : "#3877FD")}; /* Green if matched, blue if not */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -0.5px 2px 1px rgba(255, 255, 255, 0.8);
  transition: background 0.3s ease-in-out;
`;

/* ✅ Left Section - Search Icon */
const SectionOne = styled.div`
  flex-basis: 85px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: clamp(65px, 6vw, 80px); /* Scales with screen */
    height: clamp(65px, 6vw, 80px);
  }

  @media (max-width: 1024px) {
    flex-basis: 60px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 0.5rem;
  }
`;


/* ✅ Middle Section - Title & Description */
const SectionTwo = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin: 0 0.5rem;
  min-width: 40%; /* Prevents excessive shrinking */

  h2 {
    font-size: clamp(1rem, 1.5vw, 1.125rem);
    font-weight: 500;
    white-space: normal;
    text-align: left;
    line-height: 1.4;
  }

  p {
    font-size: clamp(0.75rem, 1.2vw, 0.875rem);
    font-weight: 400;
    opacity: 0.64;
    text-align: left;
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    text-align: center;
    align-items: center;
  }
`;

/* ✅ Right Section - Buttons */
const SectionThree = styled.div`
  flex-basis: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: clamp(0.5rem, 1vw, 1rem);
  white-space: nowrap;

  @media (max-width: 1024px) {
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
  }
`;

const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem; /* Increase gap slightly */
  font-size: 0.875rem;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p {
    font-size: clamp(0.75rem, 1.2vw, 0.875rem);
    font-weight: 400;
  }

  strong {
    font-size: clamp(0.875rem, 1.3vw, 1rem);
    font-weight: 500;
  }
`;

/* ✅ Divider Line */
const Divider = styled.div`
  width: 1px;
  height: 2.5rem; /* Adjust height */
  background-color: rgba(255, 255, 255, 0.4); /* Subtle white */
  margin-right: 0rem; /* Adjust spacing */
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: clamp(1rem, 2vw, 2rem)
`;

/* ✅ Button Style */
const Button = styled.button`
  padding: clamp(0.4rem, 0.8vw, 0.6rem) clamp(0.8rem, 1.2vw, 1.3rem);
  border-radius: 2rem;
  font-size: clamp(11px, 1vw, 13px);
  font-weight: 500;
  cursor: pointer;
  border: ${({ outline }) => (outline ? "1px solid white" : "none")};
  background: ${({ outline }) => (outline ? "transparent" : "white")};
  color: ${({ outline }) => (outline ? "white" : "black")};

  @media (max-width: 768px) {
    padding: 0.4rem 1rem;
  }
`;

const Banner = ({ bannerType }) => {
    const isMatched = bannerType === "matched";

    return (
        <BannerContainer bgImage={isMatched ? "/assets/greenBanner.png" : "/assets/blueBanner.png"}>
            <FlexContainer>
                
                {/* Search Icon Section */}
                <SectionOne>
                    <SearchIconWrapper isMatched={isMatched}>
                        <img src={isMatched ? "/assets/bannerCheckIcon.svg" : "/assets/bannerSearchIcon.svg"}  alt="Icon" />
                    </SearchIconWrapper>
                </SectionOne>

                {/* Main Text Section */}
                <SectionTwo>
                    <h2>
                        {isMatched 
                            ? "YOU'VE BEEN MATCHED IN A POOL!" 
                            : "OUR TEAM IS WORKING HARD TO MATCH YOU WITH A PERFECT POOL"}
                    </h2>
                    <p>
                        {isMatched 
                            ? "Sign the contract to join the pool you've been matched to." 
                            : "Checking or updating your information is very important so we match you to the best pool for you that aligns perfectly with your goals and investment commitment!"}
                    </p>
                </SectionTwo>

                {/* Buttons */}
                <SectionThree>
                    {isMatched ? (
                        <>
                            <ButtonWrapper>
                                <TimerWrapper>
                                    <Divider />
                                    <img src="/assets/bannerTimer.svg" alt="Timer" />
                                    <div>
                                        <p>Time left:</p>
                                        <strong>1d 15h 37m</strong>
                                    </div>
                                </TimerWrapper>
                                <Button>SIGN THE CONTRACT</Button>
                            </ButtonWrapper>
                        </>
                    ) : (
                        <>
                            <Button outline>UPDATE INFO</Button>
                            <Button>VIEW A DEMO POOL</Button>
                        </>
                    )}
                </SectionThree>

            </FlexContainer>
        </BannerContainer>
    );
};

export default Banner;
