import React, { useState, useEffect, useRef } from 'react';
import { useErrorBoundary } from "react-error-boundary";
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';

import { capitalizeClubName, SurveyButton, SurveyNavigationButtons} from './SurveyComponents';
import { IntroPage, SimpleTextPage, SimpleInputTextField, SimpleChoicePage, TextWithImage, TextWithSideImage, MultiSelectPage, EndPage } from './SurveyScreens';
import Loading from '../common/Loading';
import { myAxiosInstance } from '../../api/axiosConfig';


import WhiteLogo from '../../assets/images/logo_white.png';
import PoolGraphic from '../../assets/images/pool_graphic.png';
import PlatformPicture from '../../assets/images/platform_picture.png';


function ZuckSurvey({ people, spreadsheet }) {
    // Survey variables
    const [stage, setStage] = useState(1);
    const [isTypingDone, setIsTypingDone] = useState(true);

    // Seed for random sampling
    const seedRef = useRef(Math.random()); // Generate a random seed once on mount

    // User Variables
    const name = useRef("");
    const [nameError, setNameError] = useState(false);

    const onePercentMatters = useRef("");
    const [onePercentMattersError, setOnePercentMattersError] = useState(false);

    const onePercentMattersRest = useRef("");
    const [onePercentMattersRestError, setOnePercentMattersRestError] = useState(false);

    const successPredictor = useRef("");
    const [successPredictorError, setSuccessPredictorError] = useState(false); 

    const poolingExcitesChoice = useRef("");
    const [poolingExcitesChoiceError, setPoolingExcitesChoiceError] = useState(false);

    const selectedPeople = useRef([]);
    const [selectedPeopleError, setSelectedPeopleError] = useState(false);

    const wouldBigClubPool = useRef("");
    const [wouldBigClubPoolError, setWouldBigClubPoolError] = useState(false);

    // Loading
    const [reload, setReload] = useState(1);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const { showBoundary } = useErrorBoundary();

    // Screen size 
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });

    // Animation Settings
    const animationSettings = {
        variants: {
            initial: { opacity: 0, y: 300 }, // Slide in from below
            animate: { opacity: 1, y: 0 },   // Slide to position
            exit: { opacity: 0, y: -300 },   // Slide out to the top
        },
        transition: {
            initial: { duration: 0.3 },      // Duration for entering animation
            exit: { duration: 1.2 },         // Duration for exiting animation
        },
    }

    const introAnimationSettings = {
        variants: {
            initial: { opacity: 0, y: -300 },
            animate: { opacity: 1, y: 0 },
            exit: { opacity: 0, y: -300 },
        },
        transition: {
            initial: { duration: 1.2 },
            exit: { duration: 1.2 },
        },
    }

    const typingSpeed = 85;

    // Get the club name from the URL
    const { school, club_name } = useParams();
    const navigate = useNavigate();

    // Function to generate a random sample based on the seed
    const getRandomSample = (arr, size, seed) => {
        const seededRandom = (seed) => {
        let x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
        };

        const shuffled = [...arr]
        .map((value, index) => ({
            value,
            rand: seededRandom(seed + index),
        }))
        .sort((a, b) => a.rand - b.rand)
        .map((obj) => obj.value);

        return shuffled.slice(0, size);
    };

    const peopleOptions = getRandomSample(people, 20, seedRef.current);

    const collectSurveyData = () => {
        const surveyData = {
            name: name.current || "",
            onePercentMatters: zuckFourthOptions[onePercentMatters.current] || "",
            onePercentMattersRest: zuckFifthOptions[onePercentMattersRest.current] || "",
            successPredictor: zuckSixthOptions[successPredictor.current] || "",
            poolingExcitesChoice: poolingExcitesOptions[poolingExcitesChoice.current] || "",
            selectedPeople: selectedPeople.current || [],
            wouldBigClubPool: bigClubPoolOptions[wouldBigClubPool.current] || "",
        };
    
        // Convert all values to strings
        const formattedData = Object.fromEntries(
            Object.entries(surveyData).map(([key, value]) => [
                key,
                Array.isArray(value) ? value.join(", ") : String(value),
            ])
        );    
    
        return formattedData;
    };

    // Set the background
    useEffect(() => {
        // Set background color for the body when the SurveyPage is rendered
        document.body.style.backgroundColor = "#02002B"; // Your desired background color

        // Cleanup function to reset the background color
        return () => {
            document.body.style.backgroundColor = ""; // Reset to default
        };
    }, []);

    // Keydown for Enter
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Enter" && isTypingDone) {
                handleNext();
            }
        };

        // Attach the event listener
        document.addEventListener("keydown", handleKeyDown);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isTypingDone]); // Re-run the effect if isTypingDone changes

    // Refresh
    useEffect(() => {
        const getSurvey = async () => {
            try {
                setStage(1);
                setIsTypingDone(true);
                setNameError(false);
                setOnePercentMattersError(false);
                setOnePercentMattersRestError(false);
                setSuccessPredictorError(false);
            } catch (error) {
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };
    
        // Fetch survey if navigation check passes
        getSurvey();

    }, [school, club_name, navigate, reload, showBoundary]);

    // Validating
    // Validate a string input
    const validateString = (value, setError) => {
        if (!value.trim()) {
            setError(true);
            return false;
        }
        setError(false);
        return true;
    };

    // Validate an option (for example, a dropdown or choice)
    const validateOption = (value, setError) => {
        if (!value) { // Adjust condition based on what a "valid" option is (e.g., not null or undefined)
            setError(true);
            return false;
        }
        setError(false);
        return true;
    };

    const validateMultipleSelect = (value, setError) => {
        if (value.length < 10) { // Adjust condition based on what a "valid" option is (e.g., not null or undefined)
            setError(true);
            return false;
        }
        setError(false);
        return true;
    }

    // Handle the next button click
    const handleNext = async () => {
        const currentStage = stages[stage - 1];

        // If there is a validate function for the current stage, call it
        if (currentStage.validate && !currentStage.validate()) {
            return; // Stop here if validation fails
        }

        // Submit
        if (stage === stages.length - 1) {
            // Api call to save the survey
            const response = await myAxiosInstance.post('/api/save-survey-data/', { spreadsheet_id: spreadsheet, survey_data: collectSurveyData() });

            // Reset Typing
            setIsTypingDone(true);
        } else {
            setIsTypingDone(false);
        }

        // Next stage
        if (stage === stages.length) {
            // Go to signup
            navigate("/signup");
        } else {
            setStage(stage+1);
        }
    };

    // Handle previous button click
    const handlePrev = () => {
        if (stage > 1) {
            if (stage === 2){
                setIsTypingDone(true);
            } else {
                setIsTypingDone(false)
            }
            setStage(stage-1);
            
        }
    }

    const handleTypingDone = () => {
        setIsTypingDone(true); // Update state in parent when typing is done
    };

    // User Interaction
    const handleSaveName = (value) => {
        name.current = value;
        setNameError(false);
    };

    const handleOnePercentMatters = (value) => {
        onePercentMatters.current = value;
        setOnePercentMattersError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleOnePercentMattersRest = (value) => {
        onePercentMattersRest.current = value;
        setOnePercentMattersRestError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleSuccessPredictor = (value) => {
        successPredictor.current = value;
        setSuccessPredictorError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handlePoolingExcitesChoice = (value) => {
        poolingExcitesChoice.current = value;
        setPoolingExcitesChoiceError(false);

        // Automatically move to the next one
        handleNext();
    };

    const handleSelectingPeople = (person) => {
        if (selectedPeople.current.includes(person)) {
            // Remove the person from the array
            selectedPeople.current = selectedPeople.current.filter((p) => p !== person);
        } else {
            // Add the person to the array
            selectedPeople.current = [...selectedPeople.current, person];
        }
    }

    const handleBigClubPoolChoice = (value) => {
        wouldBigClubPool.current = value;
        setWouldBigClubPoolError(false);

        // Automatically move to the next one
        handleNext();
    };

    ///// The structure
    // 1) Intro page
    // 2) FirstPage
    const firstPageText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Let's introduce ourselves. We're ", wrapper: "span", style: {} },
                { content: "Prospinity", wrapper: "strong", style: {} },
                { content: ", a fintech and social startup that has raised funding from the investors behind ", wrapper: "span", style: {} },
                { content: "Robinhood, Venmo, Solana, and Sam Altman.", wrapper: "strong", style: {} },
            ],
        },
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} },
                { content: "We've operated invite-only in the Ivy League so far, but are stepping out of stealth mode and launching at colleges on the West Coast.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 3) Second Page
    const secondPageText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "What do we do? ", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
                { content: "We allow people to ", wrapper: "span", style: {} },
                { content: "invest in the future earnings of their college network.", wrapper: "i", style: {} },            ],
        },
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} },
                { content: "Depending on your personality, this may sound cool, meh, or totally unappealing. But before we unpack what it means, let's back up...", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 4) Name Page
    const namePageText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "First, we want to know more about you. ", wrapper: "span", style: {} },
                { content: 500, wrapper: "span", style: {} },
                { content: "What's your name?", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ]
        },
    ];
    // const namePageText = [
    //     "First, we want to know more about you. What's your name?"
    // ]

    // 5) Zuck Intro
    const zuckIntroText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: `Okay, ${name.current}. `, wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "We want you to imagine you are in college with Mark Zuckerberg, back in 2004. ", wrapper: "span", style: {} },
                { content: "You know him decently well - he's a somewhat annoying but ambitious kid.", wrapper: "span", style: {} }, 
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 6) Zuck 2nd Page
    const zuckSecondText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "An enterprising student in your CS 121 class, which Mark happens to be part of, convinces everyone to ", wrapper: "span", style: {} },
                { content: "make a deal.", wrapper: "strong", style: {} },
            ],
        },
        {
            sequences: [
                { content: 900, wrapper: "span", style: {} },
                { content: "Everyone in the class, after they graduate, commits to evenly splitting 1% of what they earn every year.", wrapper: "i", style: {} },
            ],
        },
        {
            sequences: [
                { content: 900, wrapper: "span", style: {} },
                { content: "It's weird, but what the hell - it's 1%. ", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
                { content: "Most of the class, including Zuck, agree to join the deal.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 7) Zuck 3rd Page
    const zuckThirdText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Fast forward a few years later. ", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "Zuck is a billionaire, ", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "and because of that deal, you're a... multi-millionaire.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 8) Zuck 4rd Page
    const zuckFourthText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Do you think that 1% matters materially to Zuck?", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];
    // const zuckFourthText = [
    //     "Do you think that 1% matters materially to Zuck?"
    // ]
    const zuckFourthOptions = {
        "A": "Are you kidding? 99% of billions is still billions.",
        "B": "Yeah, it was a real blow for him.",
    };

    // 9) Zuck 5th Page
    const zuckFifthText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Do you think that 1% matters materially to the rest of the class?", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];
    const zuckFifthOptions = {
        "A": "Absolutely. Now they can retire rich.",
        "B": "Nah, that money is peanuts.",
    };

    // 10) Zuck 6th Page
    const zuckSixthText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Last question. How much of major financial success ($10m+) do you think is predicted by GPA/internships vs being at the right place at the right time?", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];
    const zuckSixthOptions = {
        "A": "It's mostly measurable credentials.",
        "B": "It's mostly being at the right place at the right time.",
    };

    // 11) Transition
    const transitionText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Now, let's talk about how we made this real today.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 12) Prospinity Intro (text with image)
    const prospinityIntroText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "With Prospinity, we've created a platform where students can make this very same deal with the people they choose and with the terms they want.", wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "We call these groups ", wrapper: "span", style: {} },
                { content: "Success Pools.", wrapper: "strong", style: {} },
            ],
        },
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Selected students from East Coast schools (MIT, Harvard, Princeton), with offers from companies such as Goldman Sachs, Citadel, and McKinsey, have already joined pools. ", wrapper: "span", style: {} },
                { content: 1000, wrapper: "span", style: {} },
                { content: "Now, you can do the same.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];
    
    // 13) Success pool intro
    const successPoolIntroText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "A success pool is a ", wrapper: "span", style: {} },
                { content: "group of students ", wrapper: "strong", style: {} },
                { content: "that make a deal with each other to ", wrapper: "span", style: {} },
                { content: "exchange a small percentage of their future earnings.", wrapper: "strong", style: {} },
            ],
        },
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Each year after graduation, ", wrapper: "span", style: {} },
                { content: "for a set period (e.g., 10 years), ", wrapper: "span", style: {} },
                { content: "members contribute a small percentage of their earnings into a shared pool. ", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "The pool is then immediately distributed evenly among all members.", wrapper: "span", style: {} },
            ],
        },
    ];

    // 14) Pooling excites question
    const poolingExcitesText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "What aspect of joining a success pool excites you most?", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];
    const poolingExcitesOptions = {
        "A": "The chance of someone in your group hitting it big.",
        "B": "Joining a network where members are directly invested in each other and incentivized to help you succeed.",
        "C": 'Building a diversified “career portfolio” of people taking different life paths and journeys.',
    };

    // 15) Club pool transition
    const clubPoolTransitionText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "You're close to the $10 bonus!", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "And even closer to building your ideal success pool.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 16) Club pool details
    const clubPoolDetailsText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "Imagine having the opportunity to join a Success Pool with the following terms: ", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
                { content: "1% over 10 years.", wrapper: "strong", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Here's an easy way to picture it: ", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "for the next ten years, you'll be 99% invested in yourself and 1% invested in the potential of a select group.", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "This isn't just any community. ", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "Your pool is fully invested in your potential and committed to helping you succeed every step of the way.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];

    // 17) Select People
    const selectPeopleText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: `Here are 20 random people from ${capitalizeClubName(club_name)}. `, wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
                { content: "Check the ", wrapper: "span", style: {} },
                { content: "10 ", wrapper: "strong", style: {} },
                { content: "speople that you would most want to join a pool with (and that you think would also want to join a pool with you).", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
    ];

    // 18) Big Club Pool
    const bigClubPoolText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: `Imagine you had the chance to join a pool with everyone in ${capitalizeClubName(club_name)}. `, wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
                { content: "Would you be interested?", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];
    const bigClubPoolOptions = {
        "A": "Definitely - I'd be interested",
        "B": "No, I'd prefer a smaller pool.",
    };

    // 19) Final Step Page
    const finalStepText = [
        {
            sequences: [
                { content: 700, wrapper: "span", style: {} }, // WAITING
                { content: "As the final step of the partnership, you'll create an account on Prospinity.", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Creating an account ", wrapper: "span", style: {} },
                { content: "does not mean ", wrapper: "strong", style: {} },
                { content: "joining a pool.", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "However, having an account gives you the opportunity to be invited to a pool or to create one. You have complete control over which pools to join and how many.", wrapper: "span", style: {} },
                { content: 700, wrapper: "span", style: {} },
            ],
        },
        {
            sequences: [
                { content: "Once your account is set up, you can redeem your $10 partnership bonus directly on the platform using Venmo or PayPal.", wrapper: "span", style: {} },
                { content: 300, wrapper: "span", style: {} },
            ],
        },
    ];


    const stages = [
        { component: (<IntroPage />), validate: null, buttonName: "Get Started", animation: introAnimationSettings, },
        { component: (<SimpleTextPage paragraphs={firstPageText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={secondPageText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleInputTextField paragraphs={namePageText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} inputRef={name} handleInput={handleSaveName} inputError={nameError} secondaryText="(First name only...)" />), validate: () => validateString(name.current, setNameError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={zuckIntroText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={zuckSecondText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={zuckThirdText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={zuckFourthText} options={zuckFourthOptions} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={onePercentMatters} handleChoice={handleOnePercentMatters} choiceError={onePercentMattersError} />), validate: () => validateOption(onePercentMatters.current, setOnePercentMattersError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={zuckFifthText} options={zuckFifthOptions} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={onePercentMattersRest} handleChoice={handleOnePercentMattersRest} choiceError={onePercentMattersRestError} />), validate: () => validateOption(onePercentMattersRest.current, setOnePercentMattersRestError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={zuckSixthText} options={zuckSixthOptions} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={successPredictor} handleChoice={handleSuccessPredictor} choiceError={successPredictorError} />), validate: () => validateOption(successPredictor.current, setSuccessPredictorError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={transitionText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<TextWithImage paragraphs={prospinityIntroText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} image={PlatformPicture} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<TextWithSideImage paragraphs={successPoolIntroText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} image={PoolGraphic} secondaryText="See the example graphic." />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={poolingExcitesText} options={poolingExcitesOptions} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={poolingExcitesChoice} handleChoice={handlePoolingExcitesChoice} choiceError={poolingExcitesChoiceError} secondaryText="There's no right or wrong answer." />), validate: () => validateOption(poolingExcitesChoice.current, setPoolingExcitesChoiceError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={clubPoolTransitionText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={clubPoolDetailsText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
        { component: (<MultiSelectPage paragraphs={selectPeopleText} options={peopleOptions} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} selectionRef={selectedPeople} handleSelect={handleSelectingPeople} selectError={selectedPeopleError} />), validate: () => validateMultipleSelect(selectedPeople.current, setSelectedPeopleError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleChoicePage paragraphs={bigClubPoolText} options={bigClubPoolOptions} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} choiceRef={wouldBigClubPool} handleChoice={handleBigClubPoolChoice} choiceError={wouldBigClubPoolError} />), validate: () => validateOption(wouldBigClubPool.current, setWouldBigClubPoolError), buttonName: "Continue", animation: animationSettings },
        { component: (<SimpleTextPage paragraphs={finalStepText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Finish Tutorial", animation: animationSettings },
        { component: (<EndPage />), animation: introAnimationSettings, buttonName: "Create Your Account" },
    ];
    

    return (
        isBottomLoading ?
            <Loading />
            :
            <>
                <div
                    className="d-flex justify-content-center align-items-start"
                    style={{
                        backgroundColor: "#02002B",
                        width: "100%",
                        height: "100vh",
                        overflowY: "auto", // Ensures content scrolls if it overflows the viewport
                    }}
                >
                    <div
                        className="home-joyride d-flex justify-content-center"
                        style={{ width: "100%", position: "relative" }}
                    >
                        {/* Logo Fixed in Position */}
                        <div
                            className="d-flex justify-content-start align-items-center"
                            style={{
                                position: "fixed", // Fix the logo position
                                top: isMdOrLarger ? "3rem" : "1.5rem", // Adjust location for screen size
                                left: isMdOrLarger ? "3rem" : "1.5rem",
                                zIndex: 10, // Ensure it stays above other elements
                            }}
                        >
                            <img
                                src={WhiteLogo}
                                alt="Preview"
                                onError={(e) => {
                                    e.target.src =
                                        "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp";
                                }}
                                style={{
                                    width: isMdOrLarger ? "14rem" : "10rem",
                                }}
                            />
                        </div>

                        {/* Content Expanding Downwards */}
                        <div
                            className="d-flex flex-column"
                            style={{
                                width: isMdOrLarger ? "60%" : "80%",
                                color: "white",
                                marginTop: isMdOrLarger ? "12rem" : "7rem", // Ensure content starts below the logo
                                marginBottom: "3rem",
                            }}
                        >
                            <div
                                style={{
                                    color: "white",
                                    fontSize: isMdOrLarger ? "1.6rem" : "1.2rem",
                                }}
                            >
                                
                                <div>
                                    <AnimatePresence mode="wait">
                                        {stages[stage - 1] && (
                                            <motion.div
                                                key={`stage-${stage}`} // Ensure unique key for each stage
                                                variants={stages[stage - 1].animation.variants}
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                transition={stages[stage - 1].animation.transition}
                                            >
                                                {stages[stage - 1].component}
                                            </motion.div>
                                        )}
                                                   
                                        {/* Button aligned to the left, starting where the text starts */}
                                        {isTypingDone && (
                                            <>
                                            <motion.div
                                                key="button"
                                                variants={isMdOrLarger ? {
                                                        initial: { opacity: 0, y: 300 }, // Start off-screen and fade in
                                                        animate: { opacity: 1, y: 0 }, // Slide to position
                                                        exit: { opacity: 0, y: -300 }, // Fade out and slide up
                                                    } :
                                                    {
                                                        initial: { opacity: 0, y: 0 }, // Start off-screen and fade in
                                                        animate: { opacity: 1, y: 0 }, // Slide to position
                                                        exit: { opacity: 0, y: 0 }
                                                    }
                                            
                                                }
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                transition={{
                                                    initial: { duration: 1.2 }, // Duration for entering animation
                                                    exit: { duration: 1.2 },     // Duration for exiting animation
                                                }}
                                            >
                                                <div className={`d-flex ${stage === stages.length ? 'justify-content-center' : 'justify-content-start'}`} style={{ marginTop: isMdOrLarger ? '1.3rem' : '1rem', marginBottom: '1rem' }}>
                                                    <SurveyButton text={stages[stage - 1].buttonName} handleNext={handleNext} isFirstPage={stage === 1} handleBack={handlePrev} />
                                                    {isMdOrLarger && (
                                                        <span
                                                            style={{
                                                                marginLeft: "1rem",
                                                                display: "flex", // Use flexbox to align all items
                                                                alignItems: "center", // Ensures vertical alignment
                                                                color: "white",
                                                                fontSize: "0.9rem", // Adjust font size as needed
                                                            }}
                                                        >
                                                            press 
                                                            <strong style={{ margin: "0 0.3rem"}}>Enter</strong>
                                                            <span
                                                                style={{
                                                                    fontFamily: "monospace",
                                                                    fontSize: "1.2rem", // Match the text size
                                                                    lineHeight: "1", // Ensure consistent line height for alignment
                                                                    marginBottom: "0.25rem",
                                                                }}
                                                            >
                                                                ↵
                                                            </span>
                                                        </span>
                                                    )}
                                                </div>
                                                
                                            </motion.div>
                  
                                            {stage !== stages.length && isMdOrLarger && <motion.div
                                                key="button"
                                                variants={{
                                                    initial: { opacity: 0, y: 0 }, // Start off-screen and fade in
                                                    animate: { opacity: 1, y: 0 }, // Slide to position
                                                    exit: { opacity: 0, y: -0 }, // Fade out and slide up
                                                }}
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                transition={{
                                                    initial: { duration: 1.2 }, // Duration for entering animation
                                                    exit: { duration: 1.2 },     // Duration for exiting animation
                                                }}
                                            >
                                                <SurveyNavigationButtons onNextClick={handleNext} onPrevClick={handlePrev} />
                                            </motion.div>}
                                            </>
                                        )}
                                    </AnimatePresence>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>


    );

}

export default ZuckSurvey;