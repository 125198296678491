import styled from 'styled-components';

export const PrimaryButton = styled.button`
  border: none;
  padding: 0.625rem 1.7rem;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3.125rem;
  background: ${({ theme }) => theme.buttonPrimary};
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: 'transform 0.3s ease';

  &:hover {
    opacity: 0.94;
  }
`;

export const SecondaryButton = styled.button`
  border: ${({ theme }) => `1px solid ${theme.textPrimary}`};
  padding: 0.55rem 1.7rem;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3.125rem;
  background: transparent;
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: 'transform 0.3s ease';

  &:hover {
    opacity: 0.8;
  }
`;