import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    /* ✅ Define Font Faces */
    @font-face {
        font-family: "Neue Montreal";
        src: url("/fonts/NeueMontreal/NeueMontreal-Regular.otf") format("opentype");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Neue Montreal";
        src: url("/fonts/NeueMontreal/NeueMontreal-Medium.otf") format("opentype");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Neue Montreal";
        src: url("/fonts/NeueMontreal/NeueMontreal-Bold.otf") format("opentype");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Neue Montreal";
        src: url("/fonts/NeueMontreal/NeueMontreal-Light.otf") format("opentype");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Neue Montreal";
        src: url("/fonts/NeueMontreal/NeueMontreal-Italic.otf") format("opentype");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Neue Montreal";
        src: url("/fonts/NeueMontreal/NeueMontreal-LightItalic.otf") format("opentype");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "Neue Montreal";
        src: url("/fonts/NeueMontreal/NeueMontreal-MediumItalic.otf") format("opentype");
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: "Neue Montreal";
        src: url("/fonts/NeueMontreal/NeueMontreal-BoldItalic.otf") format("opentype");
        font-weight: 700;
        font-style: italic;
    }

    /*  Apply globally */
    body {
        font-family: "Neue Montreal", sans-serif;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    }
`;

export default GlobalStyle;
