import styled from "styled-components";

// ✅ Main Wrapper
const SectionWrapper = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 1.5rem;
  padding: 2rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

// ✅ Section Title
const SectionHeader = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textSecondary};
`;

// ✅ Container for Cards
const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: nowrap;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

// ✅ Individual Card
const InfoCard = styled.div`
  border-radius: 1.2rem;
  overflow: hidden;
  width: 100%;
  max-width: 360px;
  text-align: left;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.backgroundLight}; 
`;

// ✅ Image Wrapper
const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1.3;
  background-size: cover;
  background-position: center;
`;

// ✅ Content Wrapper
const ContentWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: ${({ theme }) => theme.backgroundLight}; 
`;

// ✅ Card Title
const CardTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textPrimary};
`;

// ✅ Card Description
const CardDescription = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.textSecondary};
`;

// ✅ Component
const ThreeThingsSection = () => {
  const cards = [
    {
      title: "Build a Smart Portfolio by Diversifying Careers",
      description:
        "Diversify your opportunities by investing in multiple career paths, reducing risk and maximizing long-term success.",
      image: "/assets/buildPortfolio.png",
    },
    {
      title: "Unlock Powerful Connections with Shared Goals",
      description:
        "Connect with like-minded professionals in a network where everyone benefits from shared success and aligned goals.",
      image: "/assets/connection.png",
    },
    {
      title: "Maximize Your Potential with High-Value Opportunities",
      description:
        "Gain access to high-upside opportunities—when one hits big, everyone wins.",
      image: "/assets/opportunity.png",
    },
  ];

  return (
    <SectionWrapper>
      <SectionHeader>3 THINGS TO KNOW BEFORE YOU START</SectionHeader>
      <CardContainer>
        {cards.map((card, index) => (
          <InfoCard key={index}>
            <ImageWrapper style={{ backgroundImage: `url(${card.image})` }} />
            <ContentWrapper>
              <CardTitle>{card.title}</CardTitle>
              <CardDescription>{card.description}</CardDescription>
            </ContentWrapper>
          </InfoCard>
        ))}
      </CardContainer>
    </SectionWrapper>
  );
};

export default ThreeThingsSection;
