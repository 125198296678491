import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faRedo } from "@fortawesome/free-solid-svg-icons";

const MainPoolContainer = styled.div`
  background-color: #ffffff;
  border-radius: 1.5rem;
  padding: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  color: #1d1d26;
  margin: 0;
`;

const Description = styled.p`
  font-size: 0.875rem;
  color: #717173;
  margin-top: 0.5rem;
  line-height: 1.25rem;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FieldRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f7f8fa;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1d1d26;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatusAndMembers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #1d1d26;
`;

const ActiveIndicator = styled.div`
  width: 8px;
  height: 8px;
  background-color: #28a745;
  border-radius: 50%;
`;

const Members = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const MemberAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
`;

const PlaceholderChart = styled.div`
  width: 160px;
  height: 160px;
  background: conic-gradient(#007bff 40%, #ffc107 20%, #28a745 12%, #fd7e14 9%);
  border-radius: 50%;
`;

const MainPoolPreview = () => {
  return (
    <MainPoolContainer>
      <Header>
        <div>
          <Title>YALE UNIVERSITY 2026 POOL</Title>
          <Description>
            Investing into the members of this pool over the next 10 years will
            see growth in your net earnings.
          </Description>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faChartPie}
            style={{ marginRight: "1rem", color: "#007bff", fontSize: "1.5rem" }}
          />
          <FontAwesomeIcon
            icon={faRedo}
            style={{ color: "#fd7e14", fontSize: "1.5rem" }}
          />
        </div>
      </Header>

      <Content>
        <LeftSection>
          <FieldRow>
            <span>Medicine</span>
            <span>40%</span>
          </FieldRow>
          <FieldRow>
            <span>Technology</span>
            <span>20%</span>
          </FieldRow>
          <FieldRow>
            <span>Engineering</span>
            <span>12%</span>
          </FieldRow>
          <FieldRow>
            <span>Politics</span>
            <span>11%</span>
          </FieldRow>
        </LeftSection>

        <RightSection>
          <PlaceholderChart />
        </RightSection>
      </Content>

      <StatusAndMembers>
        <Status>
          <ActiveIndicator /> Active
        </Status>
        <Members>
          <MemberAvatar src="https://images.prospinity.com/profile_pictures/main_profile_picture_sam-antonyan-lj42_20250117025949.webp" alt="Member 1" />
          <MemberAvatar src="https://images.prospinity.com/profile_pictures/main_profile_picture_sam-antonyan-lj42_20250117025949.webp" alt="Member 2" />
          <MemberAvatar src="https://images.prospinity.com/profile_pictures/main_profile_picture_sam-antonyan-lj42_20250117025949.webp" alt="Member 3" />
          +3
        </Members>
      </StatusAndMembers>
    </MainPoolContainer>
  );
};

export default MainPoolPreview;
