import { useTheme } from "styled-components";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// From my app
import { RegistrationContainer } from "../components/common/Containers";
import FormInput from "../components/forms/FormInput";
import { Form } from "../components/forms/Form";
import { PrimaryButton } from "../components/common/Buttons";
import { RegistrationHeader, Subtitle, PromptWrapper, Prompt } from "../components/forms/MiscComponents";
import { myAxiosInstance } from "../api/axiosConfig";
import { useAuth } from "../contexts/AuthContext";

function PasswordReset() {
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [errors, setErrors] = useState({ password1: "", password2: "" });
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false);

    // Get the url poparams
    const { verificationKey, slug } = useParams();

    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { password1: "", password2: ""};
    
        // Frontend validation
        if (password1.trim().length < 4) {
            newErrors.password1 = "Please enter a valid password.";
        } else if (password1 !== password2) {
            newErrors.password2 = "Passwords don't match.";
        }

        // Stop execution if frontend validation fails
        if (newErrors.password1 || newErrors.password2) {
            setErrors(newErrors);
            return; 
        }
    
        try {
            // Send API request
            setLoading(true);
            const response = await myAxiosInstance.post('/prospinity-auth/password/reset/confirm/', { new_password1: password1, new_password2: password2, slug: slug, token: verificationKey });
            setChanged(true);

        } catch (error) {
            setErrors({ ...newErrors, password2: "Something went wrong. Please try again." });

        } finally {
            setLoading(false);
        }
    };
    

    return (
        <RegistrationContainer isMobile={isMobile}>
            <RegistrationHeader isMobile={isMobile}>SET A NEW PASSWORD</RegistrationHeader>

            {changed ?
                <Subtitle>
                    Password successfully changed
                </Subtitle>
            :
                <Form onSubmit={handleSubmit}>
                    <div style={{ marginTop: "2rem" }}>
                        <FormInput
                            label="Password" 
                            type="password" 
                            value={password1}
                            onChange={(e) => {
                                setPassword1(e.target.value);
                                setErrors((prev) => ({ ...prev, password1: "" })); // Clear error when typing
                            }} 
                            showEyeIcon
                            error={errors.password1}
                        />
                    </div>

                    <div style={{ marginTop: "2rem" }}>
                        <FormInput 
                            label="Confirm Password" 
                            type="password" 
                            value={password2}
                            onChange={(e) => {
                                setPassword2(e.target.value);
                                setErrors((prev) => ({ ...prev, password2: "" })); // Clear error when typing
                            }} 
                            showEyeIcon
                            error={errors.password2}
                        />
                    </div>

                    <PrimaryButton type="submit" disabled={loading} style={{ padding: "1rem", marginTop: "2rem" }} >{loading ? "Setting password..." : "RESET PASSWORD"}</PrimaryButton>
                </Form>
            } 

            

            <PromptWrapper changed={changed} >
                <Prompt theme={theme}>
                    Back to <Link to="/join/signin">Login</Link>
                </Prompt>
            </PromptWrapper>
            
        </RegistrationContainer>
    );
}


export default PasswordReset;


// import React, { useState, useEffect, useRef } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col, Container, InputGroup, Dropdown } from 'react-bootstrap';
// import { useTheme } from 'styled-components';
// import { StyledButton, StyledCard, StyledFormControl, StyledFormGroup, StyledFormLabel, StyledForm, StyledParagraph } from '../components/common/StyledBootstrap';
// import logo_white from '../assets/images/logo_white.png';
// import logo_navy from '../assets/images/logo_navy.png';
// import axios from 'axios';
// import EmailVerificationSent from '../components/common/EmailVerificationSent';

// function PasswordReset() {
//     const themeMode = useTheme();
//     const { verificationKey, slug } = useParams();
//     const [message, setMessage] = useState("");
//     const [changed, setChanged] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);

//     const [password, setPassword] = useState('');
//     const [verifyPassword, setVerifyPassword] = useState('');

//     const [errors, setErrors] = useState({ new_password1: false, new_password2: false, non_field_errors: false });


//     const firstInputRef = useRef(null);
//     useEffect(() => {
//         // Focus the appropriate input field based on the step
//         if (firstInputRef.current) {
//             firstInputRef.current.focus();
//         }
//     }, []);

//     async function sendPasswordVerificationKey(e) {
//         e.preventDefault();
//         try {
//             setIsLoading(true);
//             const response = await axios.post('/prospinity-auth/password/reset/confirm/', { new_password1: password, new_password2: verifyPassword, slug: slug, token: verificationKey });
//             setMessage("Password Changed. Please login again with new your new password.");
//             setChanged(true);
//         } catch (error) {
//             if (error.response && error.response.data) {
//                 // Construct the error list JSX and pass it to setMessage
//                 let tempErrors = { new_password1: false, new_password2: false, non_field_errors: false }
//                 for (const [key, value] of Object.entries(error.response.data)) {
//                     tempErrors = { ...tempErrors, [key]: true };
//                 }
//                 setErrors(tempErrors);

//                 if (tempErrors.non_field_errors) {
//                     setMessage(error.response.data.non_field_errors);
//                 }

//             }
//             else {
//                 setMessage("Cannot send reset password.");
//             }

//         }
//         finally {
//             setIsLoading(false);
//         }
//     }


//     return (
//         changed ? <EmailVerificationSent><StyledParagraph className='mt-3'>Password changed. Please <Link to="/signin">sign in</Link> with your new password.</StyledParagraph></EmailVerificationSent> :
//             <div>
//                 <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
//                     <Row className='justify-content-center'>
//                         <Col className='col-lg-4 col-md-6'>

//                             <StyledCard>
//                                 <StyledCard.Body>
//                                     <Row className='justify-content-center mb-4'>
//                                         <Col md={10} className="text-center">
//                                             <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" />
//                                         </Col>
//                                     </Row>
//                                     <StyledForm onSubmit={(e) => sendPasswordVerificationKey(e)}>
//                                         <StyledFormGroup controlId="formBasicPassword">
//                                             <StyledFormControl
//                                                 type="password"
//                                                 placeholder="New Password"
//                                                 value={password}
//                                                 onChange={(e) => setPassword(e.target.value)}
//                                                 ref={firstInputRef}
//                                                 hasError={errors.new_password1}
//                                                 onClick={() => setErrors({ ...errors, new_password1: false })}
//                                             />
//                                             {errors.new_password1 ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid password.</StyledParagraph> : <></>}

//                                         </StyledFormGroup>
//                                         <StyledFormGroup controlId="formVerifyPassword">
//                                             <StyledFormControl
//                                                 type="password"
//                                                 placeholder="Verify New Password"
//                                                 value={verifyPassword}
//                                                 onChange={(e) => setVerifyPassword(e.target.value)}
//                                                 hasError={errors.new_password2}
//                                                 onClick={() => setErrors({ ...errors, new_password2: false })}
//                                             />
//                                             {errors.new_password2 ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please ensure that this matches your new password.</StyledParagraph> : <></>}
//                                         </StyledFormGroup>
//                                         <StyledButton variant="primary" type="submit" className="mt-1 w-100">
//                                             Change Password
//                                         </StyledButton>
//                                     </StyledForm>
//                                     <div className='text-center'>
//                                         {isLoading && <StyledParagraph>Changing Password...</StyledParagraph>}
//                                         {message && <StyledParagraph>{message}</StyledParagraph>}
//                                     </div>
//                                 </StyledCard.Body>
//                             </StyledCard>
//                         </Col>
//                     </Row>
//                 </Container>

//             </div>);
// }


// export default PasswordReset;
