import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2.5rem; /* Consistent padding */
    height: 100%;
    box-sizing: border-box;
    font-size: 1rem; /* Base font size for rem scaling */
    color: ${({ theme }) => theme.textPrimary}; /* Default text color */
    background-color: inherit; /* Matches dashboard background */
    overflow-y: auto; /* Scrollable content */
    touch-action: manipulation; /* For smoother scrolling */
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: ${({ justify }) => justify || 'flex-start'};
    align-items: ${({ align }) => align || 'stretch'};
    flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
    gap: ${({ gap }) => gap || '0'};
`;

export const RegistrationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ isMobile }) => isMobile ? "center" : "flex-start"};
    justify-content: center;
    width: 100%;
    max-width: 500px;
`;

export const StyledHeader = styled.h1`
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.5rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.textPrimary};
`;
