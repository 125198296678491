import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";

import { myAxiosInstance } from '../../api/axiosConfig';
import { PrimaryButton, SecondaryButton } from '../common/Buttons';



// old
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { StyledBorderCard, StyledButton } from '../common/StyledBootstrap';
import { useTracking } from 'react-tracking';
import { ProfilePicture } from '../common/MiscComponents';
import MeetingTimer from '../meetings/MeetingTimer';


const UserCardContainer = styled.div`
  border-radius: 1.5rem;
  overflow: hidden;
  background: ${({ theme }) => theme.white};
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02); /* Slightly enlarges */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }
`;

const MotionUserCardContainer = motion(UserCardContainer); // For smoothe animation

const Header = styled.div`
  position: relative;
  height: 5rem; /* ✅ Updated height */
  background-size: cover;
  background-position: center;
`;

const BlurOverlay = styled.div`
  position: absolute;
  inset: 0;
  backdrop-filter: blur(1.25rem);
  background: rgba(0, 0, 0, 0.4);
`;

const ProfileImage = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  position: absolute;
  top: -0.5rem; /* ✅ Moves closer to the top */
  left: 2rem;
`;

const DividerLine = styled.hr`
  width: 95%;
  height: 1px; /* Thin line */
  border: none;
  background: linear-gradient(90deg, 
    ${({ theme }) => theme.divider} 0%, 
    ${({ theme }) => theme.divider} 15%, 
    ${({ theme }) => theme.divider} 50%, 
    ${({ theme }) => theme.divider} 85%, 
    ${({ theme }) => theme.divider} 100%
  );
  opacity: 1; /* Ensure visibility */
  margin: 1rem auto; /* Adjust spacing as needed */
`;

const DegreeTag = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.primaryLight};
  background: ${({ theme }) => theme.primaryLightActive};
  padding: 0.25rem 0.625rem;
  border-radius: 2.75rem;
  display: inline-block;
  margin-top: 0.3rem;
  margin-right: 0.5rem;
`;

const MutualImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.125rem solid #fff;
  margin-left: -0.5rem;
  z-index: ${({ index }) => index};
`;

const MutualCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  height: 2rem;
  padding-right: 0.15rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.textSecondary};
  background: ${({ theme }) => theme.background};
  border-radius: 50%;
  border: 0.125rem solid #fff;
  margin-left: -0.5rem;
  z-index: 10;
`;

const Tagline = styled.p`
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  color: ${({ theme }) => theme.textPrimary};
  margin: 0.7rem 0; /* ✅ More space before the dashed line */
  text-align: left;
`;

const PortfolioCardContainer = styled.div`
  background: ${({ theme }) => theme.backgroundDarker};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
  position: relative;

  &:hover {
    transform: scale(1.02); /* Slightly enlarges */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }
`;

const PortfolioImageContainer = styled.div`
  width: 100%;
  /* height: 15.4375rem; */
  overflow: hidden; /* Ensures no overflow */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PortfolioProfileImage = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover; /* Fills the container while maintaining aspect ratio */
  border-radius: 1.5rem 1.5rem 0 0;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.2rem;
  gap: 0.75rem;
`;

const PortfolioUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`;

const PortfolioUserName = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textPrimary};
  white-space: nowrap;  
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;  /* Ensure it doesn’t overflow */
  display: block; /* Prevents flex issues */
`;

const PortfolioUserUniversity = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.textSecondary};
  margin: 0;
  white-space: nowrap;  
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;  /* Ensure it doesn’t overflow */
  display: block; /* Prevents flex issues */
`;

const PortfolioButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.6rem;
  width: 100%;
`;

const PortfolioDropdownContainer = styled.div`
  position: absolute;
  bottom: 15%; /* Moves it upwards instead of down */
  right: 1rem; /* Aligns it with the three dots button */
  background: ${({ theme }) => theme.white};
  border-radius: 1rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  width: 55%;
  padding: 0.5rem 0;
  z-index: 100; /* Ensures it's always on top */
  overflow: hidden; /* Prevents the hover effect from overflowing */
`;

const PortfolioDropdownOption = styled.button`
  width: 100%;
  padding: 0.6rem 0.7rem;
  font-size: 0.875rem;
  background: none;
  border: none;
  color: ${({ theme }) => theme.textPrimary};
  text-align: left;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.backgroundDarker}; /* Apply hover */
  }
`;


export function HomeUserPreviewCard({ user }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [portfolioStatus, setPortfolioStatus] = useState(user.portfolio_status);


  const profilePicture = user.profile_picture?.trim() !== ""
      ? user.profile_picture
      : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg";

  // Button Actions
  const handleSendRequest = async (e) => {
    e.stopPropagation(); // Prevents navigation when clicking the button

    try {
      await myAxiosInstance.post(`/api/make-portfolio-request/`, {
        receiving_user: user.slug,
      });

      setPortfolioStatus("portfolio_request_sent");

    } catch (error) {
      console.error("Error sending request:", error);
    }

  };

  const handleUndoRequest = async (e) => {
    e.stopPropagation();

    try {
      await myAxiosInstance.post(`/api/manage-portfolio-request/${user.slug}/`);
  
      // Reset state back to not_portfolio
      setPortfolioStatus("not_porfolio");
  
    } catch (error) {
      console.error("Error undoing request:", error);
    }
  }

  const renderActionButton = () => {
    switch (portfolioStatus) {
      case "self":
      case "in_portfolio":
      case "portfolio_request_received":
        return (
          <PrimaryButton theme={theme} style={{ padding: "0.625rem 2rem", marginLeft: "0.3rem" }} onClick={() => navigate(`/dashboard/user/${user.slug}`)}>
            VIEW
          </PrimaryButton>
        );

      case "not_porfolio":
        return (
          <PrimaryButton style={{ marginLeft: "0.3rem" }} theme={theme} onClick={handleSendRequest}>
            <img src="/assets/addIcon.svg" />
            ADD
          </PrimaryButton>
        );

      case "portfolio_request_sent":
        return (
          <SecondaryButton style={{ marginLeft: "0.3rem" }} theme={theme} onClick={handleUndoRequest}>
            SENT
          </SecondaryButton>
        );

      default:
        return null;
    }
  };


  return (
    <UserCardContainer onClick={() => { navigate(`/dashboard/user/${user.slug}`); }} theme={theme}>
      <Header style={{ backgroundImage: `url(${profilePicture})` }}>
        <BlurOverlay />
      </Header>

      <div style={{ padding: "2rem 2rem 1rem 2rem", marginTop: "-1.5rem", position: "relative" }}>
        
        {/* Profile Image & Name */}
        <ProfileImage src={profilePicture} alt="Profile" />

        <div style={{ marginLeft: "7.2rem" }}>
          <h1 style={{ fontSize: "1.5rem", fontWeight: "500", lineHeight: "2rem", margin: "0", color: theme.textPrimary }}>
            {user.first_name} {user.last_name}
          </h1>
          <p style={{ fontSize: "1rem", fontWeight: "400", lineHeight: "1.5rem", margin: "0", color: theme.textSecondary }}>
            {user.university} <span style={{ color: theme.divider }}> | </span> {user.graduation_date}
          </p>
          {user.major_or_grad_program.slice(0, 2).map((degree, index) => (
            <DegreeTag key={index} theme={theme}>
              {degree}
            </DegreeTag>
          ))}
        </div>

        {/* First Divider Line */}
        <DividerLine theme={theme} />

        {/* Tagline left-aligned */}
        <Tagline theme={theme}>"User tagline goes here."</Tagline>

        {/* Second Divider Line */}
        <DividerLine theme={theme} />

        {/* Mutual Connections & Button on the same line */}
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}> 
          {/* Mutuals (LEFT SIDE) */}
          <div style={{ display: "flex", alignItems: "center", paddingLeft: "0.5rem" }}>
            {user.mutual_connections.slice(0, 3).map((mutual, index) => (
              <MutualImage
                src={mutual.profile_picture}
                alt={`Mutual ${index + 1}`}
                key={index}
                index={index + 1}
              />
            ))}
            {user.mutual_connections.length > 3 && (
              <MutualCount theme={theme}>+{user.mutual_connections.length - 3}</MutualCount>
            )}
            <span style={{ fontSize: "1rem", fontWeight: "400", color: theme.textSecondary, marginLeft: "0.5rem" }}>
              {user.mutual_connections.length} mutuals
            </span>
          </div>

          {/* Dynamic Action Button */}
          {renderActionButton()}
        </div>
      </div>
    </UserCardContainer>
  );
}


export function RequestingUserPreviewCard({ user, onAccept, onReject }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const profilePicture = user.profile_picture?.trim() !== ""
    ? user.profile_picture
    : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg";

  const handleAccept = (e) => {
    e.stopPropagation();
    onAccept(user);
  };
  
  const handleReject = (e) => {
    e.stopPropagation();
    onReject(user);
  };

  return (
      <MotionUserCardContainer
          onClick={() => { navigate(`/dashboard/user/${user.slug}`); }}
          theme={theme}
          style={{ maxWidth: "22rem" }}
          initial={false}  // Disables initial animation when first appearing
          animate={{ opacity: 1}}  // Normal state (static)
          exit={{ opacity: 0 }}  // Runs animation only when removed
          transition={{ duration: 0.3 }}
      >
      <Header style={{ backgroundImage: `url(${profilePicture})`, height: "4rem", }}>
        <BlurOverlay />
      </Header>

      <div style={{ padding: "0.8rem 1.5rem 1.5rem 1.5rem", position: "relative", background: theme.backgroundDarker }}>
        {/* Profile Image & Name */}
        <ProfileImage src={profilePicture} alt="Profile" style={{ width: "4.5rem", height: "4.5rem", top: "-1rem", left: "1.5rem" }} />

        <div style={{ marginLeft: "5.6rem" }}>
          <h1 style={{ fontSize: "1rem", fontWeight: "500", margin: "0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {user.first_name} {user.last_name}
          </h1>
          <p style={{ fontSize: "0.875rem", fontWeight: "400", color: theme.textSecondary, margin: "0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {user.university} <span style={{ color: theme.divider }}> | </span> {user.graduation_date}
          </p>
        </div>

        <DividerLine theme={theme} style={{ marginBottom: "0.9rem" }} />

        {/* Degree */}
        {user.major_or_grad_program.slice(0, 2).map((degree, index) => (
          <DegreeTag key={index} theme={theme} style={{ fontSize: "0.75rem", padding: "0.2rem 0.6rem", marginTop: "0rem", marginBottom: "0.1rem" }}>
            {degree}
          </DegreeTag>
        ))}

        {/* Tagline */}
        <Tagline theme={theme}>{user.tagline || `"User tagline goes here."`}</Tagline>

        {/* Mutual Connections (Moved Above Divider) */}
        <div style={{ display: "flex", alignItems: "center", marginTop: "1rem", marginLeft: "0.5rem" }}>
          {user.mutual_connections.slice(0, 3).map((mutual, index) => (
            <MutualImage
              src={mutual.profile_picture}
              alt={`Mutual ${index + 1}`}
              key={index}
              index={index + 1}
            />
          ))}
          {user.mutual_connections.length > 3 && (
            <MutualCount theme={theme} style={{ width: "2rem", height: "2rem" }}>+{user.mutual_connections.length - 3}</MutualCount>
          )}
          <span style={{ fontSize: "0.875rem", fontWeight: "400", color: theme.textSecondary, marginLeft: "0.5rem" }}>
            {user.mutual_connections.length} mutual connections
          </span>
        </div>

        <DividerLine theme={theme} />

        {/* Full-Width Buttons */}
        <div style={{ display: "flex", gap: "0.8rem", marginTop: "1rem" }}>
          <SecondaryButton
            style={{ flex: 1 }}
            onClick={handleReject}
          >
            REMOVE
          </SecondaryButton>

          <PrimaryButton
            style={{ flex: 1 }}
            onClick={handleAccept}
          >
            <img src="/assets/addIcon.svg" />
            ADD
          </PrimaryButton>
        </div>
      </div>
    </MotionUserCardContainer>
  );
}


export function PortfolioPreviewCard({ user, onRemove }) {
    const theme = useTheme();
    const navigate = useNavigate();

    // dropdown menue
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const profilePicture = user.profile_picture?.trim() !== ""
    ? user.profile_picture
    : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg";

    // Removing from your portfolio
    const handleRemove = (e) => {
      e.stopPropagation();
      onRemove(user);   
      setMenuOpen(false);   
    };

    // Close dropdown when clicking outside
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setMenuOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <PortfolioCardContainer onClick={() => { navigate(`/dashboard/user/${user.slug}`); }} theme={theme}>
            <PortfolioImageContainer>
                <PortfolioProfileImage 
                    src={profilePicture} 
                    alt={`${user.first_name} ${user.last_name}`} 
                />
            </PortfolioImageContainer>

            <BottomSection>
                <PortfolioUserInfo>
                    <PortfolioUserName>{user.first_name} {user.last_name}</PortfolioUserName>
                    <PortfolioUserUniversity>{user.university}</PortfolioUserUniversity>
                </PortfolioUserInfo>

                <PortfolioButtonsContainer>
                    <SecondaryButton style={{ padding: "0.5rem 1rem", fontSize: "11px", flex: "1" }}>
                        <img src={"/assets/messageIcon.svg"} alt="Message" />
                        MESSAGE
                    </SecondaryButton>
                    <SecondaryButton 
                      style={{  borderRadius: "50%", width: "2.2rem", height: "2.2rem", padding: "0" }}
                      onClick={(e) => {
                          e.stopPropagation(); // Prevent navigating when clicking dots
                          setMenuOpen(!menuOpen);
                      }}
                      ref={buttonRef}
                    >
                        <img src={"/assets/threeDots.svg"} alt="More Options" />
                    </SecondaryButton>

                    {/* Dropdown Menu */}
                    {menuOpen && (
                        <PortfolioDropdownContainer theme={theme} ref={menuRef}>
                            <PortfolioDropdownOption onClick={handleRemove}>Remove</PortfolioDropdownOption>
                            <PortfolioDropdownOption onClick={() => console.log("A")}>Something Else</PortfolioDropdownOption>
                        </PortfolioDropdownContainer>
                    )}

                </PortfolioButtonsContainer>

            </BottomSection>
        </PortfolioCardContainer>
    );
}



function UserPreviewCard({ preview_object, buttonBoolean, bottomReload, setBottomReload, nonUser }) {
    const themeMode = useTheme();
    const { userSlug, reload, setReload, setActiveChannel, setChatVisible } = useAuth();
    const [isIndividualMessageLoading, setIsIndividualMessageLoading] = useState(false);

    const slug = preview_object.slug;
    const fullName = nonUser ? preview_object.full_name : `${preview_object.first_name} ${preview_object.last_name}`;
    
    const majors = nonUser ? "Student" : Array.isArray(preview_object.major_or_grad_program)
        ? preview_object.major_or_grad_program.join(', ')
        : preview_object.major_or_grad_program;
    const university = preview_object.university;
    const company = nonUser ? "Student" : preview_object.company;

    const [pictureSize, setPictureSize] = useState(0);
    const cardRef = useRef(null);
    const navigate = useNavigate();

    const titleSize = pictureSize * 0.15;
    const majorSize = pictureSize * 0.1;

    const handleUserClick = () => {
        !nonUser && navigate('/dashboard/user/' + preview_object.slug);
    }

    const handleMessageButtonClick = async () => {
        try {
            setIsIndividualMessageLoading(true);
            const response = await myAxiosInstance.post('/api/create-channel/', { recipient_slug: slug });
            setActiveChannel(response.data.channel_id);
            setChatVisible(true);

        }
        catch (error) {
}
        finally {
            setIsIndividualMessageLoading(false);
        }
    };

    const handleUserPreviewButtonClick = () => {

        if (preview_object.meeting_status !== "not_meeting")
        {
            navigate('/dashboard/meeting/' + preview_object.meeting_slug);            
        }
        else if (preview_object.portfolio_status === "in_portfolio"){
            // Chat
            handleMessageButtonClick();
        }
        else{
            navigate('/dashboard/user/' + preview_object.slug, { state: { addToPortfolio: true } });
        }
    }

    const buttonLabels = ["Add", "Sent", "Chat"];

    useEffect(() => {
        const updateCardSize = () => {
            if (cardRef.current) {
                const cardWidth = cardRef.current.offsetWidth;
                const size = Math.min(cardWidth, cardWidth * 1.6) * 0.7; // Adjusted size when not hovered
                setPictureSize(size);
            }
        };

        updateCardSize();
        window.addEventListener('resize', updateCardSize);

        return () => window.removeEventListener('resize', updateCardSize);
    }, []);

    return (
        <>
            <StyledBorderCard
                ref={cardRef}
                
                style={{
                    width: '8rem',
                    height: buttonBoolean ? '14.3rem' : '12rem', // Fixed size when not hovered
                    transition: 'transform 0.3s ease',
                    transform: 'scale(1)',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    marginBottom: '0rem', // Adjusted margin bottom to reduce space between cards
                    
                }}
                onMouseEnter={() => {
                    cardRef.current.style.transform = 'scale(1.04)';
                    cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
                }}
                onMouseLeave={() => {
                    cardRef.current.style.transform = 'scale(1)';
                    cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
                }}
            >
                <StyledBorderCard.Body style={{ textAlign: 'center'}}>
                    <div onClick={handleUserClick}>
                    <div className='d-flex justify-content-center'>
                        <ProfilePicture name={fullName} size={pictureSize} graduation_date={preview_object.graduation_date} picture_link={preview_object.profile_picture} />
                    </div>
                    <StyledBorderCard.Title
                        style={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: `${titleSize}px`,
                            marginTop: '0.3em'
                        }}>
                        {fullName}
                    </StyledBorderCard.Title>
                    <StyledBorderCard.Text
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: `${majorSize}px`,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            lineHeight: '1.2em',
                        }}>
                        {company === "" ? majors || 'Not Specified' : company || 'Not Specified'}
                    </StyledBorderCard.Text>
                    <StyledBorderCard.Text
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: `${majorSize}px`,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            lineHeight: '1.2em',
                        }}>
                        {university || preview_object.email}
                    </StyledBorderCard.Text>
                    
                    </div>
                    {buttonBoolean ? 
                    <StyledButton className='mt-2' onClick={handleUserPreviewButtonClick} style={{ lineHeight: '1', fontSize: '0.75rem', minWidth: '95%', backgroundColor: preview_object.meeting_status === "you_requested" || preview_object.meeting_status === "confirmed" || preview_object.meeting_status === "you_rescheduled" ? themeMode.background : themeMode.primary, color: preview_object.meeting_status === "you_requested" || preview_object.meeting_status === "confirmed" || preview_object.meeting_status === "you_rescheduled" ? themeMode.onBackground : themeMode.onPrimary}}>
                        {
                            preview_object.portfolio_status === "you_requested" ? buttonLabels[1] :
                            preview_object.portfolio_status === "you_received" ? buttonLabels[2] :
                            preview_object.portfolio_status === "confirmed" ? <MeetingTimer meetingTime={preview_object.meeting_date} onTime={() => { setBottomReload(bottomReload + 1) }} /> :
                            preview_object.portfolio_status === "you_rescheduled" ? buttonLabels[4] :
                            preview_object.portfolio_status === "other_user_rescheduled" ? buttonLabels[5] :
                            preview_object.portfolio_status === "in_progress" ? buttonLabels[6] :
                            preview_object.portfolio_status === "in_portfolio" ? buttonLabels[7] : 
                            preview_object.portfolio_status === "not_meeting" ? buttonLabels[0] : <></>
                        }
                    </StyledButton> : <></>}
                </StyledBorderCard.Body>
            </StyledBorderCard>
        </>
    );
}

export default UserPreviewCard;