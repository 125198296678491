import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


// Main Pages
import Home from './pages/Home';
import MyPools from './pages/MyPools';
import PortfolioPage from './pages/PortfolioPage';
import Payments from './pages/Payments';
import FAQs from './pages/FAQs';
import UserProfile from './pages/UserProfile';
import MeetingPage from './pages/MeetingPage';
import PoolPage from './pages/PoolPage';
import StreamLinedCreatePool from './pages/StreamLinedCreatePool';
import Settings from './pages/Settings';
import NotificationsPageMobile from './pages/NotificationsPageMobile';
import ChatPageMobile from './pages/ChatPageMobile';
import SigningCongratulations from './pages/SigningCongratulations';
import NotFoundPage from './pages/NotFoundPage';

// Regiatration Pages
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import FinishRegistration from './pages/UpdatedFinishRegistration'; 
import PasswordReset from './pages/PasswordReset';
import PasswordResetEmail from './pages/PasswordResetEmail';
import EmailVerification from './pages/EmailVerification';

// Legal Pages
import TermsofUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy'
import MemberCodeOfConduct from './pages/MemberCodeofConduct';
import ConfidentialityAndIPAcknowledgment from './pages/ConfidentialityAndIPAcknowledgment';

// Survey + Application
import SurveyPage from './components/survey/SurveyPage.js';
import Application from './components/application/Application.js';
import ApplicationRedirect from './components/application/ApplicationRedirect';
import BerkeleyRedirect from './routes/Redirect.js';
import ScreenOne from './components/application/ScreenOne.js';
import ScreenTwo from './components/application/ScreenTwo.js';
import ScreenThree from './components/application/SectionThree.js';
import ScreenReadOne from './components/application/ReadScreenOne.js';
import ScreenReadTwo from './components/application/ReadScreenTwo.js';
import ScreenReadThree from './components/application/ReadScreenThree.js';
import ScreenReadFour from './components/application/ReadScreenFour.js';
import ScreenEight from './components/application/ScreenEight.js';
import ScreenNine from './components/application/ScreenNine.js';
import VideoScreen from './components/application/VideoScreen.js';
import ThankYouScreen from './components/application/ThankYouScreen.js';

// Layouts
import Dashboard from './layouts/Dashboard';
import RegistrationLayout from './layouts/RegistrationLayout.js';
import ApplicationLayout from './layouts/ApplicationLayout.js';

// Other
import { MainLoading, UnderMaintenance } from './components/common/Loading';
import { StyledButton, StyledH4 } from './components/common/StyledBootstrap';
import { ProtectedRoute } from './routes/ProtectedRoute.js';

// Contexts
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';

import { ErrorBoundary } from 'react-error-boundary';
import { useTheme } from 'styled-components';
import { useTracking } from 'react-tracking';
import { Mixpanel } from './Mixpanel';
import GlobalStyle from './styles/GlobalStyle';

import './api/axiosConfig';


function ErrorFallback({ error, resetErrorBoundary }) {
    const themeMode = useTheme();

    const errorLogout = () => {

        localStorage.removeItem('userToken');
        window.location.href = '/signin';

    }
    return (
        <div className='d-flex flex-column justify-content-center align-items-center text-center vh-100 w-100' style={{ backgroundColor: themeMode.background }}>
            <StyledH4>Whoops! Something went wrong.
            </StyledH4>
            <StyledButton onClick={resetErrorBoundary} className='my-3'>Go Back to Your Dashboard</StyledButton>
            <StyledButton onClick={errorLogout}>Logout</StyledButton>
        </div>
    );
}




function App() {



    const dispatchTrackingEvent = data => {
        const { event, ...dataWithoutEvent } = data;
        Mixpanel.track(event, dataWithoutEvent);
        /*myAxiosInstance.post('/api/track-frontend/', {"email": "", "content": JSON.stringify(data)})
          .then(response => {
          })
          .catch(error => {
          });*/
      };

    const { Track } = useTracking(
        {},
        { dispatch: data => dispatchTrackingEvent(data) }
      );


    return (
        <Track>
            <ThemeProvider>
                <GlobalStyle />
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={() => {
                        // Logic to reset the app's state
                        window.location.href = '/dashboard';
                    }}
                >

                    <AuthProvider>
                        
                        <RouterWithRoutes />
                        
                    </AuthProvider>

                </ErrorBoundary>
            </ThemeProvider>
        </Track>
    );
}


function RouterWithRoutes() {
    const { isLoading } = useAuth();

    if (isLoading) {
        return <MainLoading />;
    }

    return (
        <Router>
            <Routes>
                {/* Dashboard Redirects */}
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/home" element={<Navigate to="/dashboard" />} />

                {/* Registration Redirects */}
                <Route path="/join" element={<Navigate to="/join/signup" />} />
                <Route path="/signup" element={<Navigate to="/join/signup" replace />} />
                <Route path="/signin" element={<Navigate to="/join/signin" replace />} />
                <Route path="/password-reset-email" element={<Navigate to="/join/password-reset-email" replace />} />
                <Route path="/password-reset/confirm/:slug/:verificationKey" element={<Navigate to="/join/password-reset/confirm/:slug/:verificationKey" replace />} />

                {/* Signing out */}
                <Route path="/signing-out" element={<MainLoading />} />

                {/* Registration Routes */}
                <Route path="/join" element={<RegistrationLayout />}>
                    <Route path="signin" element={<ProtectedRoute requiredStage={0} signin={true}><SignIn /></ProtectedRoute>} />
                    <Route path="signup" element={<ProtectedRoute requiredStage={0} signin={true}><SignUp /></ProtectedRoute>} />
                    <Route path="password-reset-email" element={<ProtectedRoute requiredStage={0} signin={true}><PasswordResetEmail /></ProtectedRoute>} />
                    <Route path="password-reset/confirm/:slug/:verificationKey" element={<ProtectedRoute requiredStage={0} signin={true}><PasswordReset /></ProtectedRoute>} />
                </Route>

                <Route path="/finish-registration" element={<UnderMaintenance />} />
                <Route path="/dashboard" element={<UnderMaintenance />} />

                {/*
                <Route path="/password-reset/confirm/:slug/:verificationKey" element={<ProtectedRoute requiredStage={0} signin={true}><PasswordReset /></ProtectedRoute>} />
                <Route path="/verify/:verificationKey" element={<EmailVerification />} />
                <Route path="/finish-registration" element={
                    <ProtectedRoute requiredStage={1}>
                        <FinishRegistration />
                    </ProtectedRoute>
                } />
                
                {/* Dashboard Routes */}
                {/* <Route path="/dashboard" element={
                    <ProtectedRoute requiredStage={3}>
                        <Dashboard />
                    </ProtectedRoute>
                }>
                    <Route index element={<Home/>} />
                    <Route path="home" element={<Home />} />
                    <Route path="payments" element={<Payments />} />
                    <Route path="user/:slug" element={<UserProfile />} />
                    <Route path="pool/:slug" element={<PoolPage />} />
                    <Route path="meeting/:slug" element={<MeetingPage />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="create-pool" element={<StreamLinedCreatePool />} />
                    <Route path="faqs" element={<FAQs />} />
                    <Route path="portfolio" element={<PortfolioPage />} />
                    <Route path="pools" element={<MyPools />} />
                    <Route path="chat-page" element={<ChatPageMobile />} />
                    <Route path="notifications-page" element={<NotificationsPageMobile />} />
                    <Route path="member-code-of-conduct" element={<MemberCodeOfConduct />} />                    
                </Route> */}

                
                {/* Application, Survey, Banners */}
                <Route path="/partnerships/:school/:club_name" element={<SurveyPage />} />
                {/* <Route path="/berkeley/application" element={<Application />} /> */}
                <Route path="/ref/:referal_code" element={<ApplicationRedirect />} />
                <Route path="/banner" element={<BerkeleyRedirect />} />

                <Route path="/berkeley/application" element={<ApplicationLayout />}>
                    <Route index element={<ScreenOne />} />
                    <Route path="1" element={<ScreenOne />} />
                    <Route path="2" element={<ScreenTwo />} />
                    <Route path="3" element={<ScreenThree />} />
                    <Route path="4" element={<ScreenReadOne />} />
                    <Route path="5" element={<ScreenReadTwo />} />
                    <Route path="6" element={<ScreenReadThree />} />
                    <Route path="7" element={<ScreenReadFour />} />
                    <Route path="8" element={<ScreenEight />} />
                    <Route path="9" element={<ScreenNine />} />
                    <Route path="video" element={<VideoScreen />} />
                    <Route path="thank-you" element={<ThankYouScreen />} />
                    <Route path="start" element={<Application />} />
                </Route>

                {/* Terms, privacy ... routes */}
                <Route path="/termsofuse" element={<TermsofUse />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/signed-success-pool" element={<SigningCongratulations />} />
                <Route path="/agreement-confidentiality" element={<ConfidentialityAndIPAcknowledgment />} />
                <Route path="*" element={<NotFoundPage />} />

                <Route path="/berkeley/application" element={<Application />} />
                <Route path="/ref/:referal_code" element={<ApplicationRedirect />} />
                <Route path="/banner" element={<BerkeleyRedirect />} />

            </Routes>
        </Router>
        
    );
}


export default App;


