import {StyledH3, StyledParagraph, StyledButton} from '../components/common/StyledBootstrap';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

function NotFoundPage() {
    const themeMode = useTheme();
    const navigate = useNavigate();
    return (
        <div className='d-flex flex-column justify-content-center align-items-center text-center vh-100 w-100' style={{ backgroundColor: themeMode.background }}>
            <StyledH3>Prospinity 404 - Page Not Found</StyledH3>
            <StyledParagraph>Sorry, the page you are looking for does not exist.</StyledParagraph>
            <StyledButton onClick={() => navigate('/dashboard')}>Go Back to Dashboard</StyledButton>
        </div>
    );
}

export default NotFoundPage;
