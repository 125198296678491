import styled, { useTheme } from "styled-components";
import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default function FormInput({ label, type, value, placeholder, onChange, showEyeIcon, error }) {
    const [showPassword, setShowPassword] = useState(false);
    const inputType = showEyeIcon ? (showPassword ? "text" : "password") : type;

    // Get the theme
    const theme = useTheme();
    
    return (
        <InputContainer>
            <InputWrapper theme={theme}>
                <StyledInput 
                    type={inputType}
                    value={value} 
                    onChange={onChange}
                    theme={theme} 
                    placeholder={placeholder}
                />
                {showEyeIcon && (
                    <EyeIcon onClick={() => setShowPassword(!showPassword)} theme={theme}>
                        {showPassword ? <EyeSlash /> : <Eye />}
                    </EyeIcon>
                )}
                <FloatingLabel theme={theme}>{label}</FloatingLabel>
            </InputWrapper>

            {error && <ErrorText>{error}</ErrorText>}

        </InputContainer>
    );
}

// Styled Components
const InputContainer = styled.div`
    width: 100%;
    position: relative;
`;

const InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 1rem;
    outline: none;
    background: ${({ theme }) => theme.white}; 
    color: ${({ theme }) => theme.textPrimary};
    position: relative;
    z-index: 1;

    letter-spacing: ${({ type, value }) => (type === "password" && value ? "0.4rem" : "normal")};

    ::placeholder {
        color: ${({ theme }) => theme.textSecondary};
        opacity: 0.6;
        letter-spacing: normal;
    }
`;

const FloatingLabel = styled.label`
    position: absolute;
    left: 1.5rem;
    transform: translateY(-50%);
    padding: 0 0.3rem;
    background: ${({ theme }) => theme.white};
    font-size: 0.75rem;
    color: ${({ theme }) => theme.textSecondary};
    z-index: 2;
`;

const EyeIcon = styled.div`
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.1rem;
    color: ${({ theme }) => theme.textSecondary};
    z-index: 3;
`;

const ErrorText = styled.span`
    color: ${({ theme }) => theme.red};
    font-size: 0.75rem;
    margin-top: 0.2rem;
`;