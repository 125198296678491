import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";

import { SimpleTextPage } from "./SurveyScreens";
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";


export default function ScreenOne() {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const navigate = useNavigate();
  const typingSpeed = 85;
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    let uniqueId = Cookies.get("unique_id");

    if (!uniqueId) {
      uniqueId = uuidv4();
      Cookies.set("unique_id", uniqueId, { expires: 30 });    
    }

    const referralCode = Cookies.get("referral_code") || localStorage.getItem("referral_code") || "";

    // Use sendBeacon if supported for better reliability
    const formData = new FormData();
    formData.append("unique_id", uniqueId);
    formData.append("referral_code", referralCode);
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, formData);

  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "black";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleTypingDone = () => {
    setIsTypingDone(true);
  };

  const handleYes = () => {
    setIsFadingOut(true);

    // Track
    let uniqueId = Cookies.get("unique_id");

    if (!uniqueId) {
      uniqueId = uuidv4();
      Cookies.set("unique_id", uniqueId, { expires: 30 });    
    }

    // Use sendBeacon if supported for better reliability
    const formData = new FormData();
    formData.append("unique_id", uniqueId);
    formData.append("screen1", "Berkeley");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, formData);

    setTimeout(() => navigate("/berkeley/application/3"), 600); // Wait for animation before navigating
  };

  const handleNo = () => {
    setIsFadingOut(true);

    // Track
    let uniqueId = Cookies.get("unique_id");

    if (!uniqueId) {
      uniqueId = uuidv4();
      Cookies.set("unique_id", uniqueId, { expires: 30 });    
    }

    // Use sendBeacon if supported for better reliability
    const formData = new FormData();
    formData.append("unique_id", uniqueId);
    formData.append("screen1", "NOT Berkeley");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, formData);

    setTimeout(() => window.location.href = 'https://prospinity.com', 600); // Wait for animation before navigating
  };

  const firstPageText = [
    {
        sequences: [
          { content: 800, wrapper: "span", style: {} },  // Extra spacing before this line
          { content: "Welcome to ", wrapper: "span", style: {} },
          { content: "Prospinity@Berkeley…", wrapper: "strong", style: {} }, // Italicized text
        ],
    },
    {
        sequences: [
          { content: 800, wrapper: "span", style: {} },  // Extra spacing before this line
          { content: "Just to confirm, are you a Berkeley student?", wrapper: "span", style: { display: "block", marginTop: "2rem" } }, // Italicized text
          { content: 500, wrapper: "span", style: {} },
        ],
    }
  ];
  
  return (
    <AnimatePresence>
      {!isFadingOut && (
        <motion.div
          key="screen-one"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} // Smooth fade-out
          transition={{ duration: 0.6, ease: "easeOut" }} // Smooth easing
          style={{ width: "80%" }}
        >
          {/* Text content */}
          <SimpleTextPage
            paragraphs={firstPageText}
            typing={true}
            typingSpeed={typingSpeed}
            onTypingDone={handleTypingDone} // Ensures we track typing completion
          />
  
          {/* Buttons (only appear after typing is done) */}
          {isTypingDone && (
            <motion.div
              key="buttons"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }} // Ensure buttons fade with text
              transition={{ duration: 0.6 }}
              style={{ width: "100%" }}
            >
              <SurveyOptionContainer isMdOrLarger={isMdOrLarger}>
                <SurveyOptionButton
                  key="YES"
                  onClick={handleYes}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"YES"}
                </SurveyOptionButton>
                <SurveyOptionButton
                  key="NO"
                  onClick={handleNo}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"NO"}
                </SurveyOptionButton>
              </SurveyOptionContainer>
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}  
