import React, { useState, useEffect, useRef } from 'react';
import { useErrorBoundary } from "react-error-boundary";
import { useParams, useNavigate } from 'react-router-dom';
import ZuckSurvey from './ZuckSurvey';
import CommunitySurvey from './CommunitySurvey';


function SurveyPage() {

    // Get the club name from the URL
    const { school, club_name } = useParams();
    const navigate = useNavigate();

    // Loading
    const [reload, setReload] = useState(1);
    const { showBoundary } = useErrorBoundary();

    // Partnership
    const [partnership, setPartnership] = useState(null);

    // Define valid partnershi[s]
    const validPartnerships = {
        "berkeley": {
            "zeta": {
                people: ["Alex Ma", "Dave Bro", "Mr. Debe", "AZ JZ", "SAS ASA", "Howdy Prospinity", "Patty C", "Alex Sheeesh", "Howdy Stranger", "Cap Jack", "Davy Jones", "Lunam Paloma", "Mr. Breast", "Jay Cutler", "The Rocky", "Brah Dude", "I Speed"],
                survey: ZuckSurvey,
                spreadsheet: "1IMMqFuTSJpYlFtXnXNbN5VRbGU5E_J4on5PYUDRafZs",
            },
            "brazilian-association": {
                people: ["Arthur Rodrigues Baruzzi Lopes", "Ana Catharina Resende Quartiero", "Bruno Vieira", "Daniel Bernath Liao", "Lucas Camargo", "Mariana Moretti Ierardi", "Matheus Esposito Almeida Ribeiro", "Nicole Elmor", "Brisa Brown ", "Luiz Felipe Barbosa", "Bruno José Fonseca", "Leonardo Guimarães Soffiatti", "Thais DUBOST ", "Joe Wang", "Roni Kriger", "Marina Bortoletto Sant'Anna", "Lara Gomes Chieppe", "Tiffanie Guichard ", "Sophia Isabella Vannucci", "Gabriel Abreu", "Sinead Bermudez"],
                survey: CommunitySurvey,
                spreadsheet: "1dnc3wKKAWLdRXLRglCG5U3WvXgzSfOKqTB5erfL2esY",
            },
            "middle-eastern-business-association": {
                people: ["Jana Nassar", "Isa Jailani", "Alhasan Alwahab", "Miranda Crumm", "Isabella Mashhour", "Cyrus Gilani", "Dunia Hakim", "Vista Mehrshahi", "Farouk Najjar", "Zaeem Lone", "Malak Issa", "Nazeli Aghajanian", "Grant Ishkhanian", "Sammy Boulazreg", "Abdulrahman AlNuwaif", "Yehia Samy Abdelhafez", "Charbel El Khoury Hanna", "Yezzen Joudeh", "Kenza Achki", "Shiraz Mghirbi", "Neeka Khosravi", "Reda Aboukhalil", "Lea Mastrih", "Diana Suvorová", "Turki Aldulami", "Daniella Elie", "Leona Katibah", "Anamaria Abnusy", "Aaraynaa Lohia", "Yasmeen Benmohamed", "Sophia Papazian", "Victor Shammas", "Nareg Simitian", "Ali Rastegar", "Sybil Saeed", "Ghassan Azizeh", "Kayla Dizechi ", "Ayaan Ali", "Dara Kashayar", "Izba Haq", "Kourosh Foroutanaliabad", "Hassan Selim", "Fred Ramses", "Marc Zoghby", "Yara Hassan", "Rami Mrad", "Tamara Yaghi", "Mohammad Khalil"],
                survey: CommunitySurvey,
                spreadsheet: "1YK4oeb9ORVhfotrswkgjKxPzG46D15xogYoDLoBeooE",
            },
        },
    };

    useEffect(() => {
        // Check if the school exists in validPartnerships
        const schoolExists = validPartnerships.hasOwnProperty(school);

        // Check if the club_name exists within the school
        const clubExists = schoolExists && validPartnerships[school].hasOwnProperty(club_name);

        if (!schoolExists || !clubExists) {
            navigate('/dashboard'); // Redirect if the school or club doesn't exist
        }
    }, [school, club_name, navigate, validPartnerships]);

    const SurveyComponent = validPartnerships[school]?.[club_name]?.survey;

    return (
        <>
            {SurveyComponent && (
                <SurveyComponent
                    people={validPartnerships[school][club_name].people}
                    spreadsheet={validPartnerships[school][club_name].spreadsheet}
                />
            )}
        </>
    );
}

export default SurveyPage;
