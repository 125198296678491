import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { AiOutlineCloudUpload } from "react-icons/ai"; // Cloud icon
import { FaRegFile } from "react-icons/fa"; // File icon
import { IoMdClose } from "react-icons/io"; // Close icon for removing file
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";

import WhiteLogo from "../../assets/images/logo_white.png";
import { MidQuestionTransition } from "./QuotePage";
import { myAxiosInstance } from '../../api/axiosConfig';

// Styled components
const PageContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
  overflow: hidden;
  touch-action: none; /* Disables touch scrolling */
  overscroll-behavior: none; /* Prevents overscroll bounce */
`;

const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  img {
    width: ${({ isMdOrLarger }) => (isMdOrLarger ? "16rem" : "10rem")};
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ isMdOrLarger }) => (isMdOrLarger ? "60%" : "100%")};;
`;

const QuestionText = styled(motion.div)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.8rem" : "1.2rem")};
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
  color: rgba(255, 255, 255, 0.9);
`;

const SurveyInput = styled(motion.input)`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.5rem" : "1rem")};
  padding: 0.5rem 0;
  width: 100%;
  color: white;
  outline: none;

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    border-bottom: 2px solid white;
  }
`;

const HintText = styled(motion.div)`
  margin-top: 0.5rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
`;

const ErrorText = styled(motion.div)`
  margin-top: 0.5rem;
  font-size: 1rem;
  color: red;
`;

const NavigationButtons = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  width: 100%;
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.4rem" : "1.2rem")};
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  span {
    position: relative;
    transition: color 0.2s ease, transform 0.2s ease;
    text-decoration: none; /* Subtle underline by default */
    text-underline-offset: 1px;
  }

  span:hover {
    color: white;
    font-weight: 530;
  }

  span::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.7);
    transition: background-color 0.2s ease, height 0.2s ease;
  }

  span:hover::after {
    background-color: white;
  }
`;

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ isMdOrLarger }) => (isMdOrLarger ? "50vh" : "30vh")};
  border: 2px dashed rgba(255, 255, 255, 0.5); /* Dashed border */
  //border-radius: 1rem; /* Rounded corners */
  background-color: #2a2840; /* Dark background */
  color: rgba(255, 255, 255, 0.7); /* Subtle text color */
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;

  &:hover {
    border-color: white; /* Highlight border on hover */
    background-color: #3c3b58; /* Slightly brighter background */
  }
`;

const FilePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 90%; /* Match container size */
  max-width: 300px;
  background-color: #3c3b58; /* File preview background */
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  transition: background-color 0.3s ease; /* Add smooth transition for background color */

  &:hover {
    background-color: #4a496b; /* Slightly brighter background color on hover */
  }
`;


const FileIcon = styled(FaRegFile)`
  font-size: 4rem;
  color: white;
  margin-bottom: 0.5rem;
`;

const FileName = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  text-align: center;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveFileButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #ff6b6b;
  }
`;

const UploadText = styled.p`
  margin: 0;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.7); /* Subtle text color */
`;

const FileInput = styled.input`
  display: none; /* Hidden input for file selection */
`;

const LoaderContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const LoaderDot = styled(motion.div)`
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  margin: 0 4px;
`;

const loaderVariants = {
  animate: {
    opacity: [0.2, 1, 0.2], // Glow effect
    transition: {
      repeat: Infinity,
      duration: 1,
      ease: "easeInOut",
    },
  },
};

// Framer Motion Animations
const fadeVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

// Component
export default function ApplicationQuestions({ navigateToFinish }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [errors, setErrors] = useState({}); // Track errors dynamically
  const answers = useRef({}); // Ref for storing answers
  const [isOptionalSection, setIsOptionalSection] = useState(false); // Track optional section
  const [showTransition, setShowTransition] = useState(false); // Track if transition page is shown
  const [fadeOutMain, setFadeOutMain] = useState(false); // Track fade-out before transition
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasTyped, setHasTyped] = useState(false);
  const navigate = useNavigate();

  const questions = [
    { id: 1, text: "Name (first and last)*", placeholder: "Type your name here..." },
    { id: 2, text: "University Email*", placeholder: "Type your university email here..." },
    { id: 3, text: "Phone Number? We will communicate interview invites via text.*", placeholder: "Type your phone number here..." }, // Move phone here
    { id: 4, text: "Major (undecided is fine)*", placeholder: "Type your major here..." },
    { id: 5, text: "Graduation Year*", placeholder: "Type your graduation year here..." },
    { id: 6, text: "Resume (optional)", type: "file" }, 
  ];
   
  // Track resume file using useRef
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setUploadedFile(file); // Update state with the selected file
    }
  };
  
  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input dialog
    }
  };
  
  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default behavior for drag-and-drop
  };
  
  const handleDrop = (e) => {
    e.preventDefault(); // Prevent default behavior for drop event
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setUploadedFile(file); // Update state with the dropped file
    }
  };
  
  const removeFile = () => {
    setUploadedFile(null); // Clear the uploaded file
  };

  // Handle Validation
  // Validation Function
  const validateInput = () => {
    const currentAnswer = answers.current[currentQuestionIndex];

    // Validate university email
    if (currentQuestionIndex === 1 && (!currentAnswer || !currentAnswer.endsWith("berkeley.edu"))) {
      setErrors((prev) => ({
        ...prev,
        [currentQuestionIndex]: "Must be a valid Berkeley email.",
      }));
      return false;
    }

    // Validate graduation year
    if (currentQuestionIndex === 4 && (!/^\d{4}$/.test(currentAnswer))) {
      setErrors((prev) => ({
        ...prev,
        [currentQuestionIndex]: "Graduation year must be a 4-digit number.",
      }));
      return false;
    }

    // Ensure required fields (questions 1–4) are not empty
    if (!currentAnswer || currentAnswer.trim() === "") {
      if (!isOptionalSection || currentQuestionIndex < 5) {
        setErrors((prev) => ({
          ...prev,
          [currentQuestionIndex]: "This field is required.",
        }));
        return false;
      }
    }

  // Clear error if input is valid
  setErrors((prev) => {
    const updatedErrors = { ...prev };
    delete updatedErrors[currentQuestionIndex];
    return updatedErrors;
  });

  return true; // Validation passed
};


  // Handle Input Change
  const handleAnswerChange = (e) => {
    const value = e.target.value;
    answers.current[currentQuestionIndex] = value;

    // Clear error if user starts typing
    if (errors[currentQuestionIndex]) {
      setErrors((prev) => {
        const updatedErrors = { ...prev };
        delete updatedErrors[currentQuestionIndex];
        return updatedErrors;
      });
    }

    if (!hasTyped && value.trim().length > 0) {
      setHasTyped(true);
    } else if (hasTyped && value.trim().length === 0) {
      setHasTyped(false);
    }

  };

  // Navigate to Next Question
  const handleNext = async () => {
    if (!validateInput()) {
      return; // Stop if validation fails
    }

    // Fade out and transition after the 5th question
    if (currentQuestionIndex === 4 && !isOptionalSection) {
      setIsOptionalSection(true);
      setCurrentQuestionIndex(5);
      // setFadeOutMain(true);
      // setTimeout(() => setShowTransition(true), 500); // Delay to match fade-out duration
      return;
    }

    // Track
    let uniqueId = Cookies.get("unique_id");

    if (!uniqueId) {
      uniqueId = uuidv4();
      Cookies.set("unique_id", uniqueId, { expires: 30 });    
    }

    // Use sendBeacon if supported for better reliability
    const formData = new FormData();
    formData.append("unique_id", uniqueId);
    const BASE_URL = process.env.REACT_APP_API_URL
    if (currentQuestionIndex === 0) {
      formData.append("full_name", answers.current[0] || "");
    } else if (currentQuestionIndex === 1) {
      formData.append("university_email", answers.current[1] || "");
    } else {
      const fields = ["full_name", "university_email", "phone_number", "major", "graduation_year", "resume", "linkedin_url", "most_impressive", "future_vision", "extra_links"];
      formData.append(fields[currentQuestionIndex], "Here");
    }
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, formData);

    // On the last question, navigate to intro for testing
    if (currentQuestionIndex === questions.length - 1) {
        // Gather answers into an object
        const formData = new FormData();

        // Get referral from storage
        const referralCode = Cookies.get("referral_code") || localStorage.getItem("referral_code") || "";
    
        const applicationData = {
          full_name: answers.current[0] || "", // Name
          university_email: answers.current[1] || "", // Email
          major: answers.current[3] || "", // Major
          graduation_year: answers.current[4] || "", // Grad Year
          linkedin_url: "", // LinkedIn
          most_impressive: "", // Most impressive thing
          future_vision: "", // Future goal
          extra_links: "", // Links
          pool_recommendations: "", // People for the pool
          additional_info: "", // Extra information
          phone_number: answers.current[2] || "", // Phone number
          referral_code: referralCode, // Referal cide
        };

        // Append text fields to FormData
        Object.keys(applicationData).forEach((key) => {
          formData.append(key, applicationData[key]);
        });

        // Attach the resume file if uploaded
        if (uploadedFile) {
          formData.append("resume", uploadedFile);
        }

        // Set loading state
        setIsSubmitting(true);
    
        try {
          // Send request using Axios
          const response = await myAxiosInstance.post('api/submit-application/', formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        } catch (error) {

        } finally {
          // Remove loading state
          setIsSubmitting(false);
        }

      navigateToFinish(applicationData.university_email);
      return;
    }

    // Move to the next question
    if (currentQuestionIndex < questions.length - 1) {
      setHasTyped(false);
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };

  // Navigate to Previous Question
  const handleBack = () => {
    setErrors((prev) => {
      const updatedErrors = { ...prev };
      delete updatedErrors[currentQuestionIndex];
      return updatedErrors;
    });

    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
  };

  const handleExit = () => {
    setTimeout(() => navigate("/berkeley/application/9"), 200);
  };

  const handleGlobalKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior
      handleNext(); // Call the same logic as handleNext
    } else if (e.key === "ArrowRight") {
      e.preventDefault();
      handleNext(); // Move forward
    } else if (e.key === "ArrowLeft") {
      e.preventDefault();
      currentQuestionIndex === 0 ? handleExit() : handleBack(); // Move backward
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleGlobalKeyPress);
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyPress);
    };
  }, [currentQuestionIndex]);

  // Render transition page
  if (showTransition) {
    return (
      <MidQuestionTransition
        onComplete={() => {
          setShowTransition(false);
          setIsOptionalSection(true);
          setCurrentQuestionIndex(5); // Start with LinkedIn question
          setFadeOutMain(false);
        }}
      />
    );
  }

  return (
      <FormSection isMdOrLarger={isMdOrLarger}>
        <AnimatePresence mode="wait">
          <QuestionText
            key={`question-${currentQuestionIndex}`}
            isMdOrLarger={isMdOrLarger}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeVariants}
            transition={{ duration: 0.6 }}
          >
              {questions[currentQuestionIndex].text}
          </QuestionText>

          {currentQuestionIndex === 5 ? (
            <FileUploadContainer
              as={motion.div}
              key={`file-upload-${currentQuestionIndex}`}
              onClick={handleContainerClick}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6 }}
              isMdOrLarger={isMdOrLarger}
            >
              {!uploadedFile ? (
                <>
                  <AiOutlineCloudUpload
                    style={{
                      fontSize: "4rem",
                      color: "rgba(255, 255, 255, 0.7)",
                      marginBottom: "0.5rem",
                    }}
                  />
                  <UploadText>Choose file or drag here</UploadText>
                  <UploadText>Size limit: 10MB</UploadText>
                  <FileInput
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                </>
              ) : (
                <FilePreviewContainer>
                  <FileIcon />
                  <FileName>{uploadedFile.name}</FileName>
                  <RemoveFileButton onClick={removeFile}>
                    <IoMdClose />
                  </RemoveFileButton>
                </FilePreviewContainer>
              )}
            </FileUploadContainer>
          ) :
            <SurveyInput
              key={`input-${currentQuestionIndex}`}
              autoFocus
              placeholder={questions[currentQuestionIndex].placeholder}
              isMdOrLarger={isMdOrLarger}
              defaultValue={answers.current[currentQuestionIndex] || ""}
              onChange={handleAnswerChange}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeVariants}
              transition={{ duration: 0.6 }}
            />
            }

          <HintText
            key={`hint-${currentQuestionIndex}`}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeVariants}
            transition={{ duration: 0.6 }}
          >
            (Press Enter to continue)
          </HintText>

          <AnimatePresence>
            {errors[currentQuestionIndex] && (
              <ErrorText
                key={`error-${currentQuestionIndex}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {errors[currentQuestionIndex]}
              </ErrorText>
            )}
          </AnimatePresence>

          <motion.div
              key="buttons"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeVariants}
              transition={{ duration: 0.6 }}
              style={{ width: "100%" }}
            >
              <SurveyOptionContainer isMdOrLarger={isMdOrLarger} style={{ "flexDirection": "row" }}>
                <SurveyOptionButton
                  key="YES"
                  onClick={isSubmitting ? null : (currentQuestionIndex === 0 && !isOptionalSection ? handleExit : handleBack)}
                  isMdOrLarger={isMdOrLarger}
                >
                  {currentQuestionIndex === 0 && !isOptionalSection ? (
                    <span style={{ opacity: isSubmitting ? 0.5 : 1 }}>EXIT</span>
                  ) : (
                    <span style={{ opacity: isSubmitting ? 0.5 : 1 }}>BACK</span>
                  )}
                </SurveyOptionButton>
                <SurveyOptionButton
                  key="NO"
                  onClick={isSubmitting ? null : handleNext}
                  isMdOrLarger={isMdOrLarger}
                >
                  <span style={{ opacity: isSubmitting ? 0.5 : 1 }}>
                    {isSubmitting ? (
                      <LoaderContainer>
                        <LoaderDot variants={loaderVariants} animate="animate" />
                        <LoaderDot variants={loaderVariants} animate="animate" style={{ animationDelay: "0.2s" }} />
                        <LoaderDot variants={loaderVariants} animate="animate" style={{ animationDelay: "0.4s" }} />
                      </LoaderContainer>
                    ) : (
                      currentQuestionIndex < questions.length - 1 ? (
                        (currentQuestionIndex < 5 ? "NEXT" :
                          (currentQuestionIndex === 5 && uploadedFile) || (5 < currentQuestionIndex && hasTyped) ? "NEXT" : "SKIP" 
                        )
                      )
                      : "FINISH"
                    )}
                  </span>
                </SurveyOptionButton>
              </SurveyOptionContainer>
            </motion.div>

          {/* <NavigationButtons
            key={`buttons-${currentQuestionIndex}`}
            isMdOrLarger={isMdOrLarger}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeVariants}
            transition={{ duration: 0.5 }}
          >
            {currentQuestionIndex === 0 && !isOptionalSection ? (
              <span onClick={isSubmitting ? null : handleExit} style={{ opacity: isSubmitting ? 0.5 : 1 }}>← exit</span>
            ) : (
              <span onClick={isSubmitting ? null : handleBack} style={{ opacity: isSubmitting ? 0.5 : 1 }}>← back</span>
            )}
            
            <span onClick={isSubmitting ? null : handleNext} style={{ opacity: isSubmitting ? 0.5 : 1 }}>
              {isSubmitting ? (
                <LoaderContainer>
                  <LoaderDot variants={loaderVariants} animate="animate" />
                  <LoaderDot variants={loaderVariants} animate="animate" style={{ animationDelay: "0.2s" }} />
                  <LoaderDot variants={loaderVariants} animate="animate" style={{ animationDelay: "0.4s" }} />
                </LoaderContainer>
              ) : (
                currentQuestionIndex < questions.length - 1 ? (
                  (currentQuestionIndex < 5 ? "next →" :
                    (currentQuestionIndex === 5 && uploadedFile) || (5 < currentQuestionIndex && hasTyped) ? "next →" : "skip →" 
                  )
                )
                : "finish →"
              )}
            </span>
          </NavigationButtons> */}
        </AnimatePresence>
      </FormSection>
  );
}
