import React, { useEffect, useState, useRef } from 'react';
import { useTheme, styled } from 'styled-components';
import { useErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from 'react-responsive';
import { AnimatePresence } from "framer-motion";

// From My Application
import { myAxiosInstance } from "../api/axiosConfig";
import Loading from "../components/common/Loading";
import { MainContainer } from '../components/common/Containers';
import { RequestingUserPreviewCard, PortfolioPreviewCard } from "../components/users/UserPreviewCard";
import { LeftArrow, RightArrow } from '../assets/icons/Icons';


const PortfolioPage = () => {

    // States
    const [isLoading, setIsLoading] = useState(true);
    const [portfolio, setPortfolio] = useState([]);
    const [portfolioRequests, setPortfolioRequests] = useState([]);

    // Errors
    const { showBoundary } = useErrorBoundary();
  
    // Get the theme
    const theme = useTheme();

    // Portfolio accept and reject (defined here for correct rerendering)
    const handleAcceptRequest = async (user) => {
      try {
          await myAxiosInstance.post(`/api/manage-portfolio-request/${user.slug}/`, { accept: true });
          setPortfolioRequests((prev) => prev.filter((u) => u.slug !== user.slug)); // Remove
          setPortfolio((prev) => [...prev, user]); // Add with a delay
          
      } catch (error) {
          console.error("Failed to accept request:", error);
      }
   };
    
    const handleRejectRequest = async (user) => {
        try {
            await myAxiosInstance.post(`/api/manage-portfolio-request/${user.slug}/`, { accept: false });
    
            setPortfolioRequests((prev) => prev.filter((u) => u.slug !== user.slug)); // Just remove from requests
        } catch (error) {
            console.error("Failed to reject request:", error);
        }
    };

    const handleRemove = async (user) => {
      try {
          await myAxiosInstance.post(`/api/remove-from-portfolio/${user.slug}/`);
  
          setPortfolio((prev) => prev.filter((u) => u.slug !== user.slug)); // Just remove from requests
      } catch (error) {
          console.error("Failed to reject request:", error);
        }
    };

    // Get the api data
    useEffect(() => {
      async function fetchData() {
          try {
              const [portfolioRes, portfolioRequestsRes] = await Promise.all([
                  myAxiosInstance.get("/api/user-portfolio/"),
                  myAxiosInstance.get("/api/user-portfolio-requests/"),
              ]);
              setPortfolio(portfolioRes.data);
              setPortfolioRequests(portfolioRequestsRes.data);
          } catch (error) {
            showBoundary(error);
          } finally {
              setIsLoading(false);
          }
      }
      fetchData();
    }, []);

    if (isLoading) {
      return <Loading />;
    }

    return (
        <MainContainer theme={theme} >
            <UserCarousel users={portfolioRequests} onAccept={handleAcceptRequest} onReject={handleRejectRequest} />
            <UserPortfolio users={portfolio} onRemove={handleRemove} />
        </MainContainer>
    );

}



// Main Wrapper
const SectionWrapper = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 1.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ isEmpty }) => isEmpty ? "0" : "0.8rem"};
  width: calc(100vw - 260px - 5rem);

`;

// Title
const SectionHeader = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textSecondary};
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between text and count */
`;

const ArrowContainer = styled.div`
  display: flex;
  gap: 1.75rem;
  align-items: center;
  margin-right: 0.3rem;
`;

// Circular Badge for Count
const ConnectionCount = styled.span`
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.secondaryPrimary};
  font-size: 1rem;
  font-weight: 500;
  padding: 0.3rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;


const CardWrapper = styled.div`
  width: 100%;
  max-width: 100%; /* Make sure it never overflows */
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: flex-start; /* Ensures cards are always centered */
  padding: ${({ isEmpty }) => isEmpty ? "0 0" : "0.7rem 0"};
`;

const CardContainer = styled.div`
  display: flex;
  gap: 1rem;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 20}%)`};
`;


// Requesting users carousel
const UserCarousel = ({ users, onAccept, onReject }) => {

    // Screen
    const isMdOrLarger = useMediaQuery({ minWidth: 1024 });
  
    // Get the theme
    const theme = useTheme();

    // Track the carousel state
    const isAnimating = useRef(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const cardWrapperRef = useRef(null);
    const leftSentinelRef = useRef(null);
    const rightSentinelRef = useRef(null);

    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);


    useEffect(() => {
      if (!cardWrapperRef.current) return;
    
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.target === leftSentinelRef.current) {
              setCanScrollLeft(!entry.isIntersecting);
            }
            if (entry.target === rightSentinelRef.current) {
              setCanScrollRight(!entry.isIntersecting);
            }
          });
        },
        { root: cardWrapperRef.current, threshold: 0.9 } // Slightly lower threshold to avoid false positives
      );
    
      if (leftSentinelRef.current) observer.observe(leftSentinelRef.current);
      if (rightSentinelRef.current) observer.observe(rightSentinelRef.current);
    
      return () => observer.disconnect();
    }, []);


    const handleNext = () => {
      if (isAnimating.current || !canScrollRight) return;
      isAnimating.current = true;
      setCurrentIndex(prev => Math.min(prev + 1, users.length - 1));
      
      setTimeout(() => {
        isAnimating.current = false;
      }, 250); // Matches transition time
    };
    
    const handlePrev = () => {
      if (isAnimating.current || !canScrollLeft) return;
      isAnimating.current = true;
      setCurrentIndex(prev => Math.max(prev - 1, 0));
    
      setTimeout(() => {
        isAnimating.current = false;
      }, 250);
    };

    return (
        <SectionWrapper isEmpty={users.length == 0}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SectionHeader theme={theme}>
                    CONNECTION REQUESTS
                    <ConnectionCount theme={theme}>{users.length}</ConnectionCount>
                </SectionHeader>
                { users.length > 0 && 
                  <ArrowContainer>
                    <button 
                        onClick={handlePrev} 
                        disabled={!canScrollLeft}
                        style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}>
                        <LeftArrow theme={theme} clickable={canScrollLeft} />
                      </button>

                      <button 
                        onClick={handleNext} 
                        disabled={!canScrollRight}
                        style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}>
                        <RightArrow theme={theme} clickable={canScrollRight} />
                      </button>
                  </ArrowContainer>
                }
            </div>
            <CardWrapper ref={cardWrapperRef} isEmpty={users.length == 0} >
                <CardContainer currentIndex={currentIndex} totalUsers={users.length} >
                    <div ref={leftSentinelRef} style={{ width: "1px", height: "1px", display: "block" }} />

                    <AnimatePresence>
                      {users.map((user, index) => (
                          <div key={index} style={{ width: isMdOrLarger ? "21.2rem" : "18.1rem" }}>
                              <RequestingUserPreviewCard 
                                key={user.slug} 
                                user={user} 
                                onAccept={() => onAccept(user)} 
                                onReject={() => onReject(user)} 
                              />
                          </div>
                      ))}
                    </AnimatePresence>

                    <div ref={rightSentinelRef} style={{ width: "1px", height: "1px", display: "block" }} />
                </CardContainer>
            </CardWrapper>
        </SectionWrapper>
    );
}



const UserPortfolio = ({ users, onRemove }) => {
  const isMdOrLarger = useMediaQuery({ minWidth: 1024 });

  const theme = useTheme();

  return (
    <SectionWrapper isEmpty={users.length === 0} style={{ marginTop: "2rem" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <SectionHeader theme={theme}>YOUR PORTFOLIO</SectionHeader>
        <ConnectionCount theme={theme} style={{ color: theme.textSecondary }}>{users.length}</ConnectionCount>
      </div>

      <PortfolioGrid>
        {users.map((user, index) => (
          <PortfolioPreviewCard key={index} user={user} onRemove={onRemove} />
        ))}
      </PortfolioGrid>
    </SectionWrapper>
  );
};

// Grid layout for portfolio cards
const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr)); /* 4 cards per row initially */
  gap: 1rem;
  width: 100%;
  justify-content: center;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, minmax(200px, 1fr)); /* 3 cards per row */
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr)); /* 2 per row */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* Full width */
  }
`;


export default PortfolioPage;



// import Loading from "./Loading";
// import { useErrorBoundary } from "react-error-boundary";
// import UserPreviewCard from "./UserPreviewCard";
// import { myAxiosInstance } from "./axiosConfig";
// import { Row, Col } from "react-bootstrap";
// import { useEffect, useState } from "react";
// import { StyledCard, StyledH5 } from "./StyledBootstrap";
// import { useTheme } from "styled-components";
// import { useMediaQuery } from "react-responsive";
// import { useAuth } from "./AuthContext";


// const PortfolioPage = () => {


//     const [isBottomLoading, setIsBottomLoading] = useState(true);
//     const { portfolioSize } = useAuth();
//     const [portfolio, setPortfolio] = useState([]);
//     const [suggestedUserArray, setSuggestedUserArray] = useState([]);
//     const [bottomReload, setBottomReload] = useState(0);
//     const themeMode = useTheme();
//     const { showBoundary } = useErrorBoundary();
//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });



//     useEffect(() => {
//         const getPortfolio = async () => {
//             try {
//                 setIsBottomLoading(true);

//                 const response = await myAxiosInstance.get('/api/user-portfolio/');
//                 setPortfolio(response.data.portfolio);
//                 setSuggestedUserArray(response.data.daily_picks);


//             } catch (error) {
//                 showBoundary(error);
//             } finally {
//                 setIsBottomLoading(false);
//             }
//         };

//         getPortfolio();


//     }, [bottomReload]);


//     return (
//         isBottomLoading ? <Loading />
//             :
//             <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%'  }}>
//                 <div className='home-joyrride mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>

//                     <div><StyledH5 className="mt-4"><strong>Portfolio Size: {portfolioSize}</strong></StyledH5></div>

//                     <StyledCard>
//                         <StyledCard.Body>


                            
//                             <div className="d-flex flex-wrap justify-content-center">
//                                 {portfolio.length > 0 ? portfolio.map((member, index) => (
//                                     <div className="mx-1 my-1">
//                                         <UserPreviewCard preview_object={member}></UserPreviewCard>
//                                     </div>
//                                 )) : <div>Discover and meet other students from across the country. If someone catches your eye, send them a 10-minute meeting request. Once you’ve connected, you’ll be added to each other’s portfolios. Think of the Prospinity Portfolio as your personal collection of meaningful contacts you can always access right away whenever you need something or propose to join a pool together.</div>}
//                             </div>
//                         </StyledCard.Body>
//                     </StyledCard>
//                 </div>
//                 {/*isMdOrLarger && (
//                     <div className="mx-2">
//                         <StyledH5 className="mt-4"><strong>Daily Picks</strong></StyledH5>

//                         <StyledCard style={{ height: '80vh', overflowY: 'auto' }}>
//                             <StyledCard.Body>
//                                 {suggestedUserArray
//                                     .filter(otherUserInfo => otherUserInfo.first_name !== '')
//                                     .reduce((acc, curr, index) => {
//                                         if (index % 2 === 0) {
//                                             // Every even index, create a new row with two elements
//                                             acc.push([curr]);
//                                         } else {
//                                             // Add the current item to the last row
//                                             acc[acc.length - 1].push(curr);
//                                         }
//                                         return acc;
//                                     }, [])
//                                     .map((pair, pairIndex) => (
//                                         <div className="d-flex align-items-center justify-content-center" key={pairIndex}>
//                                             {pair.map((otherUserInfo, index) => (
//                                                 <div className="mx-1 my-2" key={index}>
//                                                     <UserPreviewCard preview_object={otherUserInfo} buttonBoolean={true} bottomReload={bottomReload} setBottomReload={setBottomReload} />
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     ))}

//                             </StyledCard.Body>
//                         </StyledCard>
//                     </div>


//                 )*/}
//             </div>

//     )

// }



// export default PortfolioPage;