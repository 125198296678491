import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

export default function ApplicationRedirect() {
  const { referal_code } = useParams(); // Get referral code from URL
  const navigate = useNavigate();

  useEffect(() => {
    if (referal_code) {
      Cookies.set("referral_code", referal_code, { expires: 30 });
      localStorage.setItem("referral_code", referal_code); // Save in localStorage
    }
    
    // Redirect to application
    window.location.href = `https://o0bf65aidiq.typeform.com/to/FzndHNoR#source=${referal_code}`;
  }, [referal_code, navigate]);

  return null; // No UI needed
}