import styled, { useTheme } from 'styled-components';
import { FlexContainer } from '../common/Containers';


// ✅ Header Styling (Updated)
const TaskHeader = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textSecondary}; /* ✅ textSecondary */
`;

// ✅ Task Container (Flexbox for horizontal layout)
const TaskContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 2rem;
  padding: 0 2rem;

  @media (max-width: 1024px) {
    flex-wrap: wrap; /* ✅ Allows content to flow properly */
    justify-content: center;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

// ✅ Full Dashed Line Behind Icons (Always Stays Centered)
const TaskLine = styled.div`
  position: absolute;
  top: 1.25rem; /* ✅ Half the icon height (50px) */
  width: 80%; /* ✅ Matches the container width */
  height: 1px;
  background: repeating-linear-gradient(
    to right,
    ${({ theme }) => theme.divider} 0px,
    ${({ theme }) => theme.divider} 5px,
    transparent 5px,
    transparent 10px
  );
  
  /* ✅ Ensures the line is centered inside the container */
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (max-width: 1024px) {
    display: none; /* ✅ Hide when wrapping */
  }
`;

// ✅ Individual Task Item (With Correct Dashed Line)
const TaskItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
`;


// ✅ Task Icon
const TaskIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${({ completed }) => (completed ? "#3BB75E" : "#E5E7EB")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

// ✅ Task Label
const TaskLabel = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0.4rem;
  color: ${({ theme }) => theme.textSecondary};
`;

// ✅ Task Description
const TaskDescription = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.textTertiary};
  font-weight: 400;
`;

// ✅ TaskProgress Component
const TaskProgress = () => {
  const tasks = [
    { label: "Set up your profile", description: "Lorem ipsum dolor sit amet", completed: true, icon: "/assets/profileTaskNotDone.svg" , completedIcon: "/assets/profileTask.svg"},
    { label: "Add people to your portfolio", description: "Lorem ipsum dolor sit amet", completed: true, icon: "/assets/portfolioTaskNotDone.svg" , completedIcon: "/assets/portfolioTask.svg"},
    { label: "Review your matched pool", description: "Lorem ipsum dolor sit amet", completed: false, icon: "/assets/reviewPoolNotDone.svg" , completedIcon: "/assets/reviewPool.svg"},
    { label: "Join your first success pool", description: "Lorem ipsum dolor sit amet", completed: false, icon: "/assets/joinPoolNotDone.svg" , completedIcon: "/assets/joinPool.svg"},
  ];

  // Get the theme
  const theme = useTheme();

  return (
    <FlexContainer direction="column" gap="1.5rem" style={{ background: theme.white, borderRadius: "1.5rem", padding: "2rem 2rem 1rem 2rem", marginTop: "1.5rem" }}>
      <TaskHeader theme={theme}>FINISH YOUR ONBOARDING</TaskHeader>
      <TaskContainer>
        <TaskLine theme={theme} />
        {tasks.map((task, index) => (
          <TaskItem key={index}>
            <img src={task.completed ? task.completedIcon : task.icon} style={{width: "2.5rem"}}></img>
            <TaskLabel>{task.label}</TaskLabel>
            <TaskDescription>{task.description}</TaskDescription>
          </TaskItem>
        ))}
      </TaskContainer>
    </FlexContainer>
  );
};

export default TaskProgress;
