import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { FaRegCopy } from "react-icons/fa"; // FontAwesome copy icon
import md5 from "crypto-js/md5"; // Hashing library for uniqueness

import WhiteLogo from "../../assets/images/logo_white.png"; // Import your logo here

import { FaShareAlt } from "react-icons/fa";
import { IoShareOutline } from "react-icons/io5";




function ShareButton({ link }) {
  const [copied, setCopied] = useState(false);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: link,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback: Copy to clipboard
      navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <CopyableContainer onClick={handleShare}>
      <IoShareOutline />
      <span>Share</span>
      {copied && <CopiedMessage>Copied!</CopiedMessage>}
    </CopyableContainer>
  );
}

// **Styled Components**
const ShareContainer = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  background: none;
  border: none;
  font-size: 1rem;
  position: relative;

  &:hover {
    color: white;
  }

  svg {
    font-size: 1.2rem;
  }
`;


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000; /* Pure black background */
  color: white;
  overflow: hidden;
  touch-action: none; /* Disables touch scrolling */
  overscroll-behavior: none; /* Prevents overscroll bounce */
`;

const Logo = styled.img`
  width: ${({ isMdOrLarger }) => (isMdOrLarger ? "20rem" : "13rem")};
  margin-bottom: 1rem;
`;

const ApplicationText = styled(motion.div)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.7rem" : "1.2rem")};
  line-height: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.6" : "1.2")};
  text-align: left;
  color: rgba(255, 255, 255, 0.9); /* Slightly softer white */
  padding: ${({ isMdOrLarger }) => (isMdOrLarger ? "1rem" : "0rem")};
`;

const NavigationButtons = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.4rem" : "1.2rem")};
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  span {
    position: relative;
    transition: color 0.2s ease, transform 0.2s ease;
    text-decoration: none; /* Subtle underline by default */
    text-underline-offset: 1px;
  }

  span:hover {
    color: white;
    font-weight: 530;
  }

  span::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.7);
    transition: background-color 0.2s ease, height 0.2s ease;
  }

  span:hover::after {
    background-color: white;
  }
`;

const CopyableContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-weight: bold;
  position: relative;

  &:hover {
    color: white; /* Matches 'exit' button hover style */
  }
`;

const CopyIcon = styled(FaRegCopy)`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.3s;

  ${CopyableContainer}:hover & {
    color: white; /* Changes icon color on hover */
  }
`;

const CopiedMessage = styled.span`
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

export default function FinishPage({ navigateToIntro, navigateToExplain, email }) {
  const [fadeOut, setFadeOut] = useState(false); // For fading out the page
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const [copied, setCopied] = useState(false); // Track if copied message should show

  // Generate unique referral link
  const getReferralLink = (email) => {
    if (!email) return "https://app.prospinity.com/ref/";

    const username = email.split("@")[0]; // Extracts part before '@'
    const hash = md5(email).toString().slice(0, 4); // Shortened hash for uniqueness
    return `https://app.prospinity.com/ref/${username}-${hash}`;
  };

  const forwardingLink = useRef(getReferralLink(email)); // Store generated referral link

  const handleNavigateToExplain = () => {
    setFadeOut(true); // Trigger fade-out animation
    setTimeout(() => {
      navigateToExplain(); // Navigate only after fade-out finishes
    }, 750); // Match the fade-out duration
  };

  const handleNavigateToIntro = () => {
    setFadeOut(true); // Trigger fade-out animations
    setTimeout(() => {
      navigateToIntro(); // Navigate only after fade-out finishes
    }, 750); // Match the fade-out duration
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(forwardingLink.current).then(() => {
      setCopied(true); // Show "Copied!" message
      setTimeout(() => setCopied(false), 2000); // Hide after 2 seconds
    });
  };

  return (

      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }} // Smooth fade-in for logo and text
          style={{ textAlign: "center" }}
        >
         
          <ApplicationText isMdOrLarger={isMdOrLarger}>
            <p>Thank you for applying. We will reach out with next steps if you are selected.</p>
            <p>Since you made it to the end, you've entered in our $2000 campus giveaway.</p>
            <p>If you know someone who might be interested, share this link with them.</p>
            <p>For every application, you'll get an extra entry in our giveaway.</p>
            <p>
              Your unique forwarding link:
            </p>
            <CopyableContainer onClick={handleCopy}>
                <CopyIcon />{forwardingLink.current}
                <CopiedMessage show={copied}>Copied</CopiedMessage>
            </CopyableContainer>
            <br />
            <ShareButton link={forwardingLink.current} />
          </ApplicationText>
        </motion.div>
      </>
  );
}
