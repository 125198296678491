import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function BerkeleyRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to application
    window.location.href = "https://prospinity.com/berkeley";
  }, [navigate]);

  return null; // No UI needed
}