import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";

import { SimpleTextPage } from "./SurveyScreens";
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";


export default function ScreenNine() {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const navigate = useNavigate();
  const typingSpeed = 85;
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });


  useEffect(() => {
    document.body.style.backgroundColor = "black";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleTypingDone = () => {
    setIsTypingDone(true);
  };

  const handleYes = () => {
    setIsFadingOut(true);

    // Track
    let uniqueId = Cookies.get("unique_id");

    if (!uniqueId) {
      uniqueId = uuidv4();
      Cookies.set("unique_id", uniqueId, { expires: 30 });    
    }

    // Use sendBeacon if supported for better reliability
    const formData = new FormData();
    formData.append("unique_id", uniqueId);
    formData.append("screen9", "Interested");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, formData);

    setTimeout(() => navigate("/berkeley/application/start"), 600); // Wait for animation before navigating
  };

  const handleNo = () => {
    setIsFadingOut(true);

    // Track
    let uniqueId = Cookies.get("unique_id");

    if (!uniqueId) {
      uniqueId = uuidv4();
      Cookies.set("unique_id", uniqueId, { expires: 30 });    
    }

    // Use sendBeacon if supported for better reliability
    const formData = new FormData();
    formData.append("unique_id", uniqueId);
    formData.append("screen9", "Not Interested");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, formData);

    setTimeout(() => navigate("/berkeley/application/thank-you"), 600); // Wait for animation before navigating
  };

  const firstPageText = [
    {
        sequences: [
            { content: 700, wrapper: "span", style: {} },
            { content: "The Prospinity platform is invite-only", wrapper: "bold", style: { fontWeight: "500" } },
            { content: ", but applying takes just ", wrapper: "span", style: {} },
            { content: "five minutes", wrapper: "bold", style: { fontWeight: "500" } },
            { content: "—anyone can do it, no matter experience.", wrapper: "span", style: {} },
            { content: 500, wrapper: "span", style: {} },
        ],
    },
    {
        sequences: [
            { content: 500, wrapper: "span", style: {} },
            { content: "Students from Yale, Harvard, and Princeton and even some from Berkeley have already joined.", wrapper: "span", style: {} },
        ],
    },
    {
        sequences: [
            { content: 500, wrapper: "span", style: {} },
            { content: "If shortlisted, you’ll have a brief interview. After that, if selected, you can:", wrapper: "span", style: {} },
        ],
    },
    {
        sequences: [
            { content: "A) Join a curated pool with top students.", wrapper: "span", style: {} },
            { content: 300, wrapper: "br", style: {} },
            { content: "B) Create your own pool with friends.", wrapper: "span", style: {} },
        ],
    },
    {
        sequences: [
          { content: 800, wrapper: "span", style: {} },  // Extra spacing before this line
          { content: "Want access to success pools at Cal?", wrapper: "span", style: { display: "block", marginTop: "2rem" } }, // Italicized text
          { content: 500, wrapper: "span", style: {} },
        ],
    }
  ];
  
  return (
    <AnimatePresence>
      {!isFadingOut && (
        <motion.div
          key="screen-one"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} // Smooth fade-out
          transition={{ duration: 0.6, ease: "easeOut" }} // Smooth easing
        >
          {/* Text content */}
          <SimpleTextPage
            paragraphs={firstPageText}
            typing={true}
            typingSpeed={typingSpeed}
            onTypingDone={handleTypingDone} // Ensures we track typing completion
          />
  
          {/* Buttons (only appear after typing is done) */}
          {isTypingDone && (
            <motion.div
              key="buttons"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }} // Ensure buttons fade with text
              transition={{ duration: 0.6 }}
              style={{ width: "100%" }}
            >
              <SurveyOptionContainer isMdOrLarger={isMdOrLarger}>
                <SurveyOptionButton
                  key="Yes"
                  onClick={handleYes}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"Yes, I might be interested"}
                </SurveyOptionButton>
                <SurveyOptionButton
                  key="No"
                  onClick={handleNo}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"No"}
                </SurveyOptionButton>
              </SurveyOptionContainer>
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
 }  
