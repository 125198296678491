import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import Logo from '../assets/images/logo_white.png';

export default function ApplicationLayout() {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const navigate = useNavigate();
    const location = useLocation();

    // Hide back button on the first page
    const showBackButton = location.pathname !== "/berkeley/application";

    return (
      <LayoutContainer isMdOrLarger={isMdOrLarger}>
        {/* Back button for desktop (separate from logo) */}
        {isMdOrLarger && showBackButton && (
          <BackButtonDesktop onClick={() => navigate(-1)}>←</BackButtonDesktop>
        )}

        <Header isMdOrLarger={isMdOrLarger}>
          {/* Back button for mobile (inline with logo) */}
          {!isMdOrLarger && showBackButton && (
            <BackButtonMobile onClick={() => navigate(-1)}>←</BackButtonMobile>
          )}

          {/* Logo (Left-aligned on Desktop, Centered on Mobile) */}
          <LogoContainer isMdOrLarger={isMdOrLarger}>
            <img src={Logo} alt="Prospinity Logo" />
          </LogoContainer>
        </Header>

        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </LayoutContainer>
    );
}

// Styled Components
const LayoutContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: black;
  color: white;
  padding-top: ${({ isMdOrLarger }) => (isMdOrLarger ? "10vh" : "5vh")};
  padding-left: ${({ isMdOrLarger }) => (isMdOrLarger ? "20vh" : "5vh")};
  padding-right: ${({ isMdOrLarger }) => (isMdOrLarger ? "15vh" : "5vh")};
  overflow-y: auto;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: ${({ isMdOrLarger }) => (isMdOrLarger ? "flex-start" : "center")};
  margin-bottom:  ${({ isMdOrLarger }) => (isMdOrLarger ? "3.5rem" : "2.5rem")};
`;

const BackButtonDesktop = styled.button`
  position: absolute;
  top: 2rem;
  left: 2rem;
  font-size: 2.2rem;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const BackButtonMobile = styled.button`
  font-size: 1.6rem;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s ease;
  position: absolute;
  left: 0.1rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isMdOrLarger }) => (isMdOrLarger ? "flex-start" : "center")};
  width: 100%;

  img {
    height: ${({ isMdOrLarger }) => (isMdOrLarger ? "4.2rem" : "2.2rem")};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
`;

