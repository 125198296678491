import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { StyledCard, StyledH5, StyledH4, StyledH3, StyledH2, StyledParagraph, StyledButton, Container, StyledFormGroup, StyledFormLabel, StyledFormSelect, StyledFormControl, StyledFormControlNoFocus} from "../common/StyledBootstrap";
import { AiOutlineCloudUpload } from "react-icons/ai"; // Cloud icon
import { FaRegFile } from "react-icons/fa"; // File icon
import { IoMdClose } from "react-icons/io"; // Close icon for removing file

export function capitalizeClubName(club_name) {
    return club_name
        .split('-') // Split on dashes
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' '); // Join words with spaces
}

export const SurveyButton = ({ text = "I'm ready", handleNext, isFirstPage, handleBack }) => {   
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });

    return isMdOrLarger ? (
        <div>
            {/* Button for larger screens */}

            <button
                style={{
                    backgroundColor: '#DCE6F4',
                    color: '#3367D6',
                    border: 'none',
                    borderRadius: '30px',
                    padding: '10px 20px',
                    fontSize: '1.06rem',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    zIndex: '10',
                }}
                onClick={handleNext}
            >
                {text}
            </button>

        </div>
    ) : (
        <>
            {/* Container for Buttons */}
            <div
                style={{
                    position: "fixed",
                    bottom: "0", // Attach to the bottom of the screen
                    left: "0", // Stretch to the left edge
                    width: "100%", // Stretch across the entire screen
                    display: "flex", // Align buttons horizontally
                    alignItems: "center",
                    justifyContent: "space-between", // Space buttons out evenly
                    padding: "0.5rem 1rem", // Add padding around the buttons
                    backgroundColor: "transparent", // No background color for button container
                    zIndex: "10", // Ensure buttons are above hover overlay
                }}
            >
                {/* Back Button */}
                {!isFirstPage && (
                    <button
                        onClick={handleBack}
                        style={{
                            backgroundColor: "#DCE6F4", // Light blue
                            color: "#3367D6", // Dark blue for icon
                            border: "none",
                            borderRadius: "50%", // Circular shape
                            width: "3.5rem", // Fixed size
                            height: "3.5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow
                            cursor: "pointer",
                        }}
                    >
                        <span style={{ fontSize: "1.5rem" }}>←</span>
                    </button>
                )}

                {/* Main Button */}
                <button
                    onClick={handleNext}
                    style={{
                        backgroundColor: "#DCE6F4", // Light blue
                        color: "#3367D6", // Dark blue for text
                        border: "none",
                        borderRadius: "30px", // Rounded edges for the main button
                        flex: "1", // Make this button stretch across
                        padding: "0.8rem", // Vertical padding
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        marginLeft: isFirstPage ? "auto" : "0.5rem", // Space between buttons
                        cursor: "pointer",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow
                    }}
                >
                    Continue
                </button>
            </div>

            {/* Hover Overlay */}
            <div
                style={{
                    backgroundColor: "#02002B", // Dark overlay color
                    opacity: 0.7, // Reduce opacity for a subtle effect
                    width: "100%", // Stretch overlay across the entire screen
                    height: "12vh", // Height of the hover overlay
                    position: "fixed",
                    zIndex: "9", // Ensure it’s below the buttons
                    bottom: "0", // Attach to the very bottom of the screen
                    left: "0", // Start from the left edge
                }}
            />
        </>
    );
};

export const SurveyNavigationButtons = ({onNextClick, onPrevClick}) => {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 }); // Only show on larger screens

    if (!isMdOrLarger) return null; // Return nothing for small screens

    return (
        <div
            style={{
                position: "fixed",
                bottom: "1.5rem", // Distance from the bottom
                right: "1.5rem", // Distance from the right edge
                display: "flex",
                alignItems: "center", // Align buttons horizontally
                backgroundColor: "#DCE6F4", // Light blue background
                borderRadius: "20px", // Smaller rounded edges
                overflow: "hidden", // Clip content to rounded edges
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
                zIndex: 1000, // Ensure it's above other content
                height: "2.3rem", // Adjust height
                width: "4.6rem", // Adjust width for the entire container
            }}
        >
            {/* Left Button: Navigate to Previous Page */}
            <button
                onClick={onPrevClick} // Go back to the previous page
                style={{
                    backgroundColor: "transparent", // Match container color
                    border: "none",
                    flex: 1, // Equal width for both buttons
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <span style={{ fontSize: "1.2rem", color: "#3367D6" }}><strong>↑</strong></span>
            </button>

            {/* Divider */}
            <div
                style={{
                    width: "1px", // Thin vertical line
                    backgroundColor: "#B0C4DE", // Light divider color
                    height: "100%", // Adjust height relative to the container
                }}
            ></div>

            {/* Right Button: Placeholder */}
            <button
                onClick={onNextClick}
                style={{
                    backgroundColor: "transparent", // Match container color
                    border: "none",
                    flex: 1, // Equal width for both buttons
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <span style={{ fontSize: "1.2rem", color: "#3367D6" }}><strong>↓</strong></span>
            </button>
        </div>
    );
};

export const SurveyParagraph = styled(StyledParagraph)`
    color: white;
    text-align: left;
    margin-top: "0.6rem";
    font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.9rem" : "1.2rem")};
`;

export const SecondaryParagraph = styled(StyledParagraph)`
    color: rgba(255, 255, 255, 0.5);
    text-align: left;
    font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.4rem" : "1rem")};
`;

export const SurveyInput = styled.input`
    background-color: transparent; // Blend with the background
    border: none; // No border
    border-bottom: 2px solid rgba(255, 255, 255, 0.5); // Subtle underline
    outline: none; // Remove default focus style
    font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.8rem" : "1.2rem")};
    color: white; // White text for dark background
    text-align: left; // Align the text to the left
    width: auto; // Make the input as wide as the content
    padding: 0.5rem; // Add padding for spacing
    caret-color: white; // Visible white blinking cursor
    ::placeholder {
        color: rgba(255, 255, 255, 0.5); // Subtle placeholder color
    }
    &:focus {
        border-bottom: 2px solid white; // Highlight underline when focused
    }
    width: 100%; // Ensures input has a reasonable starting width
`;

export const SurveyOptionContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMdOrLarger }) => (isMdOrLarger ? "row" : "column")};
  gap: 1rem;
  width: 100%; /* Ensure container spans full width */
  max-width: 100%;
  margin: 2rem 0 4rem 0;
`;

export const SurveyOptionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: ${({ isMdOrLarger }) => (isMdOrLarger ? "clamp(1.5rem, 3vw, 3rem) clamp(3rem, 6vw, 8rem)" : "2rem 1.5rem")};
  border: 2px solid #3b3b3b;
  background-color: transparent;
  color: white;
  border-radius: 1.5rem;
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "clamp(1rem, 2vw, 1.4rem)" : "1rem")};
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  text-align: center;
  width: 100%; /* Ensure buttons take full width */
  flex-grow: 1; /* Makes both buttons expand evenly */

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: white;
  }
`;



export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 100%;
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isMdOrLarger }) => (isMdOrLarger ? "0.9rem 1.3rem" : "0.7rem 1.3rem")};
  background-color: #2a2840;
  border-radius: ${({ isMdOrLarger }) => (isMdOrLarger ? "4rem" : "3rem")};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3c3b58;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const Label = styled.span`
  color: white;
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.1rem" : "0.9rem")};
`;

export const CheckboxContainer = styled.div`
  width: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.7rem" : "1.3rem")};
  height: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.7rem" : "1.3rem")};
  border: 2px solid white;
  border-radius: 0.33rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &.checked {
    background-color: white;

    svg {
      display: block;
    }
  }

  svg {
    display: none;
    width: 2rem;
    height: 2rem;
    fill: #2a2840;
  }
`;

export const SurveyError = styled.div`
    color: #FF6B6B;
    font-size: 0.9rem;
    margin-top: 0.5rem;
`;

export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  border: 2px dashed rgba(255, 255, 255, 0.5); /* Dashed border */
  border-radius: 1rem; /* Rounded corners */
  background-color: #2a2840; /* Dark background */
  color: rgba(255, 255, 255, 0.7); /* Subtle text color */
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;

  &:hover {
    border-color: white; /* Highlight border on hover */
    background-color: #3c3b58; /* Slightly brighter background */
  }
`;

export const FilePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 90%; /* Match container size */
  max-width: 300px;
  background-color: #3c3b58; /* File preview background */
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  transition: background-color 0.3s ease; /* Add smooth transition for background color */

  &:hover {
    background-color: #4a496b; /* Slightly brighter background color on hover */
  }
`;


export const FileIcon = styled(FaRegFile)`
  font-size: 4rem;
  color: white;
  margin-bottom: 0.5rem;
`;

export const FileName = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  text-align: center;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveFileButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #ff6b6b;
  }
`;

export const UploadText = styled.p`
  margin: 0;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.7); /* Subtle text color */
`;

export const FileInput = styled.input`
  display: none; /* Hidden input for file selection */
`;

export const FileUploadError = styled.div`
  color: #ff6b6b; /* Error red */
  text-align: center;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

export const FileUpload = ({ onFileUpload, onFileRemove, errorMessage, currentFile }) => {
  const [uploadedFile, setUploadedFile] = useState(currentFile);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setUploadedFile(file);
      onFileUpload(file);
    }
  };

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically trigger file input
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default drag behavior
  };

  const handleDrop = (e) => {
    e.preventDefault(); // Prevent default drop behavior
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setUploadedFile(file);
      onFileUpload(file);
    }
  };

  const removeFile = () => {
    setUploadedFile(null);
    onFileRemove();
  };

  return (
    <div>
      <FileUploadContainer
        onClick={handleContainerClick} // Trigger file input on click
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {!uploadedFile ? (
          <>
            <AiOutlineCloudUpload style={{ fontSize: "4rem", color: "rgba(255, 255, 255, 0.7)", marginBottom: "0.5rem" }} />
            <UploadText>Choose file or drag here</UploadText>
            <UploadText>Size limit: 10MB</UploadText>
            <FileInput
              type="file"
              ref={fileInputRef} // Reference to hidden file input
              onChange={handleFileChange}
            />
          </>
        ) : (
          <FilePreviewContainer>
            <FileIcon />
            <FileName>{uploadedFile.name}</FileName>
            <RemoveFileButton onClick={removeFile}>
              <IoMdClose />
            </RemoveFileButton>
          </FilePreviewContainer>
        )}
      </FileUploadContainer>
      {errorMessage && <FileUploadError>{errorMessage}</FileUploadError>}
    </div>
  );
};