import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence } from "framer-motion";
import QuotePage, {Transition} from "./QuotePage";
import ApplicationQuestions from './ApplicationQuestions';
import FinishPage from './FinishPage';

export default function Application() {
  const [currentPage, setCurrentPage] = React.useState("Application");
  const [userEmail, setUserEmail] = React.useState("");
  
  const navigateToApplicationQuestions = () => setCurrentPage(() => "Application");
  const navigateToFinish = (email) => {
    setUserEmail(email); // Store the email when navigating to Finish
    setCurrentPage(() => "FinishPage");
  };

  // Set the background
    useEffect(() => {
        // Set background color for the body when the SurveyPage is rendered
        document.body.style.backgroundColor = "#000000"; // Your desired background color

        // Cleanup function to reset the background color
        return () => {
            document.body.style.backgroundColor = ""; // Reset to default
        };
    }, []);

  return (
    <>
      <AnimatePresence mode="wait">  
        {currentPage === "Application" && (
          <ApplicationQuestions
            key="Application"
            navigateToFinish={navigateToFinish}
          />
        )}
        {currentPage === "FinishPage" && (
          <FinishPage
            key="FinishPage"
            email={userEmail}
          />
        )}
      </AnimatePresence>
    </>
  );
}




// import React, { useState, useEffect, useRef } from 'react';
// import { useErrorBoundary } from "react-error-boundary";
// import { useParams, useNavigate } from 'react-router-dom';
// import { useMediaQuery } from 'react-responsive';
// import { motion, AnimatePresence } from 'framer-motion';

// import { SurveyButton, SurveyNavigationButtons} from './ApplicationComponents';
// import { IntroPage, SimpleTextPage, SimpleInputTextField, SimpleChoicePage, TextWithImage, TextWithSideImage, MultiSelectPage, EndPage } from './ApplicationScreens';
// import Loading from '../Loading';
// import { myAxiosInstance } from '../axiosConfig';


// import WhiteLogo from '../Images/logo_white.png';
// import PoolGraphic from '../Images/pool_graphic.png';
// import PlatformPicture from '../Images/platform_picture.png';


// function Application() {
//     // Survey variables
//     const [stage, setStage] = useState(1);
//     const [isTypingDone, setIsTypingDone] = useState(true);

//     // Seed for random sampling
//     const seedRef = useRef(Math.random()); // Generate a random seed once on mount

//     // User Variables
//     const name = useRef("");
//     const [nameError, setNameError] = useState(false);


//     // Loading
//     const [reload, setReload] = useState(1);
//     const [isBottomLoading, setIsBottomLoading] = useState(true);
//     const { showBoundary } = useErrorBoundary();

//     // Screen size 
//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });

//     // Animation Settings
//     const animationSettings = {
//         variants: {
//             initial: { opacity: 0, y: 300 }, // Slide in from below
//             animate: { opacity: 1, y: 0 },   // Slide to position
//             exit: { opacity: 0, y: -300 },   // Slide out to the top
//         },
//         transition: {
//             initial: { duration: 0.3 },      // Duration for entering animation
//             exit: { duration: 1.2 },         // Duration for exiting animation
//         },
//     }

//     const introAnimationSettings = {
//         variants: {
//             initial: { opacity: 0, y: -300 },
//             animate: { opacity: 1, y: 0 },
//             exit: { opacity: 0, y: -300 },
//         },
//         transition: {
//             initial: { duration: 1.2 },
//             exit: { duration: 1.2 },
//         },
//     }

//     const typingSpeed = 85;

//     // Get the club name from the URL
//     const navigate = useNavigate();

   
//     const collectSurveyData = () => {
//         const surveyData = {
//             name: name.current || "",
//         };
    
//         // Convert all values to strings
//         const formattedData = Object.fromEntries(
//             Object.entries(surveyData).map(([key, value]) => [
//                 key,
//                 Array.isArray(value) ? value.join(", ") : String(value),
//             ])
//         );    
    
//         return formattedData;
//     };

//     // Set the background
//     useEffect(() => {
//         // Set background color for the body when the SurveyPage is rendered
//         document.body.style.backgroundColor = "#000000"; // Your desired background color

//         // Cleanup function to reset the background color
//         return () => {
//             document.body.style.backgroundColor = ""; // Reset to default
//         };
//     }, []);

//     // Keydown for Enter
//     useEffect(() => {
//         const handleKeyDown = (event) => {
//             if (event.key === "Enter" && isTypingDone) {
//                 handleNext();
//             }
//         };

//         // Attach the event listener
//         document.addEventListener("keydown", handleKeyDown);

//         // Cleanup the event listener when the component unmounts
//         return () => {
//             document.removeEventListener("keydown", handleKeyDown);
//         };
//     }, [isTypingDone]); // Re-run the effect if isTypingDone changes

//     // Refresh
//     useEffect(() => {
//         const getSurvey = async () => {
//             try {
//                 setStage(1);
//                 setIsTypingDone(true);
//                 setNameError(false);
//             } catch (error) {
//                 showBoundary(error);
//             } finally {
//                 setIsBottomLoading(false);
//             }
//         };
    
//         // Fetch survey if navigation check passes
//         getSurvey();

//     }, [navigate, reload, showBoundary]);

//     // Validating
//     // Validate a string input
//     const validateString = (value, setError) => {
//         if (!value.trim()) {
//             setError(true);
//             return false;
//         }
//         setError(false);
//         return true;
//     };

//     // Validate an option (for example, a dropdown or choice)
//     const validateOption = (value, setError) => {
//         if (!value) { // Adjust condition based on what a "valid" option is (e.g., not null or undefined)
//             setError(true);
//             return false;
//         }
//         setError(false);
//         return true;
//     };

//     const validateMultipleSelect = (value, setError) => {
//         if (value.length < 10) { // Adjust condition based on what a "valid" option is (e.g., not null or undefined)
//             setError(true);
//             return false;
//         }
//         setError(false);
//         return true;
//     }

//     // Handle the next button click
//     const handleNext = async () => {
//         const currentStage = stages[stage - 1];

//         // If there is a validate function for the current stage, call it
//         if (currentStage.validate && !currentStage.validate()) {
//             return; // Stop here if validation fails
//         }

//         // Submit
//         if (stage === stages.length - 1) {
//             // Api call to save the survey
//             //const response = await myAxiosInstance.post('/api/save-survey-data/', { spreadsheet_id: '', survey_data: collectSurveyData() });

//             // Reset Typing
//             setIsTypingDone(true);
//         } else {
//             setIsTypingDone(false);
//         }

//         // Next stage
//         if (stage === stages.length) {
//             // Go to signup
//             navigate("/signup");
//         } else {
//             setStage(stage+1);
//         }
//     };

//     // Handle previous button click
//     const handlePrev = () => {
//         if (stage > 1) {
//             if (stage === 2){
//                 setIsTypingDone(true);
//             } else {
//                 setIsTypingDone(false)
//             }
//             setStage(stage-1);
            
//         }
//     }

//     const handleTypingDone = () => {
//         setIsTypingDone(true); // Update state in parent when typing is done
//     };

//     // User Interaction
//     const handleSaveName = (value) => {
//         name.current = value;
//         setNameError(false);
//     };

    
//     ///// The structure
//     // 1) Intro page
//     // 2) FirstPage
//     const firstPageText = [
//         {
//             sequences: [
//                 { content: 700, wrapper: "span", style: {} }, // WAITING
//                 { content: "Let's introduce ourselves. We're ", wrapper: "span", style: {} },
//                 { content: "Prospinity", wrapper: "strong", style: {} },
//                 { content: ", a fintech and social startup that has raised funding from the investors behind ", wrapper: "span", style: {} },
//                 { content: "Robinhood, Venmo, Solana, and Sam Altman.", wrapper: "strong", style: {} },
//             ],
//         },
//         {
//             sequences: [
//                 { content: 700, wrapper: "span", style: {} },
//                 { content: "We've operated invite-only in the Ivy League so far, but are stepping out of stealth mode and launching at colleges on the West Coast.", wrapper: "span", style: {} },
//                 { content: 300, wrapper: "span", style: {} },
//             ],
//         },
//     ];


//     const stages = [
//         { component: (<IntroPage />), validate: null, buttonName: "Get Started", animation: introAnimationSettings, },
//         { component: (<SimpleTextPage paragraphs={firstPageText} typing={true} typingSpeed={typingSpeed} onTypingDone={handleTypingDone} />), validate: null, buttonName: "Continue", animation: animationSettings },
//         { component: (<EndPage />), animation: introAnimationSettings, buttonName: "Create Your Account" },
//     ];
    

//     return (
//         isBottomLoading ?
//             <Loading />
//             :
//             <>
//                 <div
//                     className="d-flex justify-content-center align-items-start"
//                     style={{
//                         backgroundColor: "#000000",
//                         width: "100%",
//                         height: "100vh",
//                         overflowY: "auto", // Ensures content scrolls if it overflows the viewport
//                     }}
//                 >
//                     <div
//                         className="home-joyride d-flex justify-content-center"
//                         style={{ width: "100%", position: "relative" }}
//                     >
//                         {/* Logo Fixed in Position */}
//                         <div
//                             className="d-flex justify-content-start align-items-center"
//                             style={{
//                                 position: "fixed", // Fix the logo position
//                                 top: isMdOrLarger ? "3rem" : "1.5rem", // Adjust location for screen size
//                                 left: isMdOrLarger ? "3rem" : "1.5rem",
//                                 zIndex: 10, // Ensure it stays above other elements
//                             }}
//                         >
//                             <img
//                                 src={WhiteLogo}
//                                 alt="Preview"
//                                 onError={(e) => {
//                                     e.target.src =
//                                         "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp";
//                                 }}
//                                 style={{
//                                     width: isMdOrLarger ? "14rem" : "10rem",
//                                 }}
//                             />
//                         </div>

//                         {/* Content Expanding Downwards */}
//                         <div
//                             className="d-flex flex-column"
//                             style={{
//                                 width: isMdOrLarger ? "60%" : "80%",
//                                 color: "white",
//                                 marginTop: isMdOrLarger ? "12rem" : "7rem", // Ensure content starts below the logo
//                                 marginBottom: "3rem",
//                             }}
//                         >
//                             <div
//                                 style={{
//                                     color: "white",
//                                     fontSize: isMdOrLarger ? "1.6rem" : "1.2rem",
//                                 }}
//                             >
                                
//                                 <div>
//                                     <AnimatePresence mode="wait">
//                                         {stages[stage - 1] && (
//                                             <motion.div
//                                                 key={`stage-${stage}`} // Ensure unique key for each stage
//                                                 variants={stages[stage - 1].animation.variants}
//                                                 initial="initial"
//                                                 animate="animate"
//                                                 exit="exit"
//                                                 transition={stages[stage - 1].animation.transition}
//                                             >
//                                                 {stages[stage - 1].component}
//                                             </motion.div>
//                                         )}
                                                   
//                                         {/* Button aligned to the left, starting where the text starts */}
//                                         {isTypingDone && (
//                                             <>
//                                             <motion.div
//                                                 key="button"
//                                                 variants={isMdOrLarger ? {
//                                                         initial: { opacity: 0, y: 300 }, // Start off-screen and fade in
//                                                         animate: { opacity: 1, y: 0 }, // Slide to position
//                                                         exit: { opacity: 0, y: -300 }, // Fade out and slide up
//                                                     } :
//                                                     {
//                                                         initial: { opacity: 0, y: 0 }, // Start off-screen and fade in
//                                                         animate: { opacity: 1, y: 0 }, // Slide to position
//                                                         exit: { opacity: 0, y: 0 }
//                                                     }
                                            
//                                                 }
//                                                 initial="initial"
//                                                 animate="animate"
//                                                 exit="exit"
//                                                 transition={{
//                                                     initial: { duration: 1.2 }, // Duration for entering animation
//                                                     exit: { duration: 1.2 },     // Duration for exiting animation
//                                                 }}
//                                             >
//                                                 <div className={`d-flex ${stage === stages.length ? 'justify-content-center' : 'justify-content-start'}`} style={{ marginTop: isMdOrLarger ? '1.3rem' : '1rem', marginBottom: '1rem' }}>
//                                                     <SurveyButton text={stages[stage - 1].buttonName} handleNext={handleNext} isFirstPage={stage === 1} handleBack={handlePrev} />
//                                                     {isMdOrLarger && (
//                                                         <span
//                                                             style={{
//                                                                 marginLeft: "1rem",
//                                                                 display: "flex", // Use flexbox to align all items
//                                                                 alignItems: "center", // Ensures vertical alignment
//                                                                 color: "white",
//                                                                 fontSize: "0.9rem", // Adjust font size as needed
//                                                             }}
//                                                         >
//                                                             press 
//                                                             <strong style={{ margin: "0 0.3rem"}}>Enter</strong>
//                                                             <span
//                                                                 style={{
//                                                                     fontFamily: "monospace",
//                                                                     fontSize: "1.2rem", // Match the text size
//                                                                     lineHeight: "1", // Ensure consistent line height for alignment
//                                                                     marginBottom: "0.25rem",
//                                                                 }}
//                                                             >
//                                                                 ↵
//                                                             </span>
//                                                         </span>
//                                                     )}
//                                                 </div>
                                                
//                                             </motion.div>
                  
//                                             {stage !== stages.length && isMdOrLarger && <motion.div
//                                                 key="button"
//                                                 variants={{
//                                                     initial: { opacity: 0, y: 0 }, // Start off-screen and fade in
//                                                     animate: { opacity: 1, y: 0 }, // Slide to position
//                                                     exit: { opacity: 0, y: -0 }, // Fade out and slide up
//                                                 }}
//                                                 initial="initial"
//                                                 animate="animate"
//                                                 exit="exit"
//                                                 transition={{
//                                                     initial: { duration: 1.2 }, // Duration for entering animation
//                                                     exit: { duration: 1.2 },     // Duration for exiting animation
//                                                 }}
//                                             >
//                                                 <SurveyNavigationButtons onNextClick={handleNext} onPrevClick={handlePrev} />
//                                             </motion.div>}
//                                             </>
//                                         )}
//                                     </AnimatePresence>
                                    
//                                 </div>

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </>


//     );

// }

// export default Application;
