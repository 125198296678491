import { Outlet } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import logoBlack from "../assets/icons/logo.svg";

export function RegistrationLayoutMobile() {
    const theme = useTheme(); // Get the theme

    return (
        <MobileContainer>
            {/* Fixed Logo & Divider */}
            <FixedTopSection theme={theme}>
                <Logo src={logoBlack} alt="Prospinity Logo" />
            </FixedTopSection>

            {/* Form Section */}
            <FormWrapper>
                <Outlet />
            </FormWrapper>
        </MobileContainer>
    );
}

// Styled Components
const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    overflow: hidden;  /* Prevent scrolling/moving */
    touch-action: none; /* Disables mobile drag interactions */
`;

const FixedTopSection = styled.div`
    position: fixed;  /* Pin it at the top */
    top: 0;
    left: 0;
    width: 100%;
    background: ${({ theme }) => theme.white}; /* Ensure background is consistent */
    border-bottom: ${({ theme }) => `1px solid ${theme.divider}`};
    padding: 1rem 2rem;
    z-index: 100;
`;

const Logo = styled.img`
    width: 140px;
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    margin-top: 4.1704rem; /* Adjusted so content doesn’t overlap with the fixed top */
`;

export default RegistrationLayoutMobile;
