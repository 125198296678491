
import EmailVerificationSent from '../components/common/EmailVerificationSent';
import { StyledParagraph } from '../components/common/StyledBootstrap';

function SigningCongratulations() {


    return (
        
        
        <EmailVerificationSent><StyledParagraph className='mt-3'>Congratulations! You have successfully signed your Success Pooling Agreement. Wait until the end of the signing period for the pool to activate.</StyledParagraph></ EmailVerificationSent>
        );
}

export default SigningCongratulations;


