import { useTheme } from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// From my app
import { RegistrationContainer } from "../components/common/Containers";
import FormInput from "../components/forms/FormInput";
import { Form } from "../components/forms/Form";
import { PrimaryButton } from "../components/common/Buttons";
import { RegistrationHeader, Subtitle, PromptWrapper, Prompt } from "../components/forms/MiscComponents";
import { myAxiosInstance } from "../api/axiosConfig";
import { useAuth } from "../contexts/AuthContext";

export function SignUp() {

    const [step, setStep] = useState(1); 

    // Step 1
    const [email, setEmail] = useState("");

    // Step 2
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    // Other
    const [errors, setErrors] = useState({ email: "", password1: "", password2: ""});
    const [loading, setLoading] = useState(false);

    // Login Auth
    const { handleLogin } = useAuth();

    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Email validation function
    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    // Handle email submission
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { email: "" };
    
        // Frontend validation
        if (!email.trim()) {
            newErrors.email = "Please enter a valid email address.";
        } else if (!isValidEmail(email)) {
            newErrors.email = "Please enter a valid email address.";
        }

        if (newErrors.email) {
            setErrors(newErrors);
            return; // Stop execution if frontend validation fails
        }
                  
        try {
            // Send API request
            setLoading(true);
            await myAxiosInstance.post("/prospinity-auth/registration/email-check/", { email });

            // Move on
            setStep(2);

        } catch (error) {
            // Handle API errors
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.email) {
                        setErrors({ ...newErrors, email: error.response.data.email[0] });
                    } else {
                        setErrors({ ...newErrors, email: "You don't have an invitation to join Prospinity." });
                    }
                } else {
                    setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };

    // Handle final submission
    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { password1: "", password2: "" };

        if (!password1.trim()) {
            newErrors.password1 = "Please enter a password.";
        } else if (password1.length < 8) {
            newErrors.password1 = "Password must be at least 8 characters.";
        }

        if (password1 !== password2) {
            newErrors.password2 = "Passwords do not match.";
        }

        if (newErrors.password1 || newErrors.password2) {
            setErrors(newErrors);
            return;
        }

        try {
            setLoading(true);
            await myAxiosInstance.post("/prospinity-auth/registration/", {
                email, 
                password1, 
                password2,
            });

            // Login
            handleLogin();

        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.password1) {
                        setErrors({ ...newErrors, password1: error.response.data.password1[0] });
                    } else if (error.response.data.password2) {
                        setErrors({ ...newErrors, password2: error.response.data.password2[0] });
                    } else {
                        setErrors({ ...newErrors, email: "Something went wrong. Please try again later." });
                    }
                } else {
                    setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };

    
    if (step === 1)
        return (
            <RegistrationContainer isMobile={isMobile}>
                <RegistrationHeader isMobile={isMobile}>WELCOME TO{isMobile ? " " : <br />}PROSPINITY</RegistrationHeader>
                <Subtitle theme={theme}>Enter your university email below</Subtitle>

                <Form onSubmit={handleEmailSubmit}>
                    <FormInput 
                        label="University email" 
                        type="email" 
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors((prev) => ({ ...prev, email: "" })); // Clear error when typing
                        }}
                        error={errors.email}
                    />

                    <PrimaryButton type="submit" disabled={loading} style={{ padding: "1rem", marginTop: "2rem" }} >{loading ? "Checking email..." : "GET STARTED"}</PrimaryButton>
                </Form>

                <PromptWrapper>
                    <Prompt theme={theme}>
                        Already have an account? <Link to="/join/signin">Login</Link>
                    </Prompt>
                </PromptWrapper>
                
            </RegistrationContainer>
        );
    
    else
        return (
            <RegistrationContainer isMobile={isMobile}>
                <RegistrationHeader isMobile={isMobile}>CREATE YOUR<br />PASSWORD</RegistrationHeader>
                <Subtitle theme={theme}>Your password must be at least 8 characters long</Subtitle>

                <Form onSubmit={handlePasswordSubmit}>
                    <FormInput 
                        label="Password" 
                        type="password" 
                        value={password1}
                        onChange={(e) => {
                            setPassword1(e.target.value);
                            setErrors((prev) => ({ ...prev, password1: "" })); // Clear error when typing
                        }}
                        showEyeIcon
                        error={errors.password1}
                    />

                    <div style={{ marginTop: "2rem" }}>
                        <FormInput 
                            label="Confirm Password" 
                            type="password" 
                            value={password2}
                            onChange={(e) => {
                                setPassword2(e.target.value);
                                setErrors((prev) => ({ ...prev, password2: "" })); // Clear error when typing
                            }} 
                            showEyeIcon
                            error={errors.password2}
                        />
                    </div>

                    <PrimaryButton type="submit" disabled={loading} style={{ padding: "1rem", marginTop: "2rem" }} >{loading ? "Checking password..." : "NEXT"}</PrimaryButton>
                </Form>

                <PromptWrapper>
                    <Prompt theme={theme}>
                        Signing up as {email}.{" "}
                        <Link 
                            to="#" 
                            onClick={(e) => { 
                                e.preventDefault(); // Prevents navigation
                                setStep(1); // Resets to email input
                            }}
                        >
                            Not you?
                        </Link>
                    </Prompt>
                </PromptWrapper>
                
            </RegistrationContainer>
        );
}

export default SignUp;



// import { useState, useRef, useEffect } from 'react';
// import { Navbar, Nav, NavDropdown, Button, Row, Col, Container, InputGroup, Dropdown, Card } from 'react-bootstrap';
// import axios from 'axios';
// import { StyledCard, StyledFormControl, StyledForm, StyledFormGroup, StyledFormLabel, StyledButton, StyledParagraph } from '../components/common/StyledBootstrap';
// import { useTheme } from 'styled-components';
// import logo_white from '../assets/images/logo_white.png';
// import logo_navy from '../assets/images/logo_navy.png';
// import { useNavigate } from 'react-router-dom';
// import EmailVerificationSent from '../components/common/EmailVerificationSent';
// import { useParams } from 'react-router-dom';
// import { useTracking } from 'react-tracking';
// import { errorString } from '../components/common/CriticalFunctions';
// import { useAuth } from '../contexts/AuthContext';
// import PasswordStrengthBar from 'react-password-strength-bar';
// import { myAxiosInstance } from '../api/axiosConfig';


// function SignUp() {
//     const themeMode = useTheme();
//     const { key } = useParams();

//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [verifyPassword, setVerifyPassword] = useState('');
//     const [prospinityKey, setProspinityKey] = useState(key || '');
//     const [sent, setSent] = useState(false);
//     const navigate = useNavigate();
//     //const { trackEvent, Track } = useTracking({ event: "Sign Up" });
//     const { handleLogin } = useAuth();
//     const [errors, setErrors] = useState({ email: false, password1: false, password2: false, non_field_errors: false });



//     const [isLoading, setIsLoading] = useState(false);
//     const [message, setMessage] = useState(null);


//     const firstInputRef = useRef(null);
//     useEffect(() => {
//         // Focus the appropriate input field based on the step

//         if (firstInputRef.current) {
//             firstInputRef.current.focus();
//         }
//     }, []);



//     const handleEmailSubmit = (e) => {
//         // Typically, you'd validate the email here or send it to an API before moving to the next step.
//         e.preventDefault();
//         authenticateInitialSignUp(email, password, verifyPassword, prospinityKey, setIsLoading, setMessage);
//     };

//     async function authenticateInitialSignUp(email, pw, vpw, proskey, setIsLoading, setMessage) {
//         setIsLoading(true);
//         setMessage(null);
//         let response = null;

//         try {
//             const credentials = { 'email': email, 'password1': pw, 'password2': vpw };
//             response = await axios.post('/prospinity-auth/registration/', credentials);
//             setSent(true);
//             //navigate("/verification-link-sent");
//             //setMessage("Please check your email for a verification link.")
//             //trackEvent({ email: email });
//             handleLogin(response);
//         }
//         catch (error) {
//             if (error.response && error.response.data) {
//                 // Construct the error list JSX and pass it to setMessage
//                 let tempErrors = { password1: false, email: false, password2: false, non_field_errors: false }
//                 for (const [key, value] of Object.entries(error.response.data)) {
//                     tempErrors = { ...tempErrors, [key]: true };
//                 }
//                 setErrors(tempErrors);

//                 if (tempErrors.non_field_errors) {
//                     setMessage(error.response.data.non_field_errors);
//                 }

//             }
//             else {
//                 setMessage("Unable to sign up with provided information.");
//             }
//             setMessage(Object.values(error.response.data)[0][0]);
//         }
//         finally {
//             setIsLoading(false);

//         }
//     }


//     return (
//         <>
//             {sent ? <EmailVerificationSent><StyledParagraph className='mt-3'>Verification link sent. Please check your email.</StyledParagraph></EmailVerificationSent> :
//                 <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
//                     <Row className='justify-content-center'>
//                         <Col className='col-lg-4 col-md-6'>

//                             <StyledCard>
//                                 <StyledCard.Body>
//                                     <Row className='justify-content-center mb-4'>
//                                         <Col md={10} className="text-center">
//                                             <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" />
//                                         </Col>
//                                     </Row>

//                                     <StyledForm onSubmit={(e) => handleEmailSubmit(e)}>
//                                         <StyledFormGroup controlId="formBasicEmail">
//                                             <StyledFormControl
//                                                 type="email"
//                                                 placeholder="University Email Address"
//                                                 value={email}
//                                                 onChange={(e) => setEmail(e.target.value)}
//                                                 ref={firstInputRef}
//                                                 hasError={errors.email}
//                                                 onClick={() => setErrors({ ...errors, email: false })}
//                                             />
//                                             {errors.email ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid university email.</StyledParagraph> : <></>}

//                                         </StyledFormGroup>
//                                         {/*<StyledFormGroup controlId="formVerifyPassword">
//                                     <StyledFormLabel>Prospinity Key</StyledFormLabel>
//                                     <StyledFormControl
//                                         type="password"
//                                         placeholder="Enter Your Prospinity Key"
//                                         value={prospinityKey}
//                                         onChange={(e) => setProspinityKey(e.target.value)}
//                                     />
//     </StyledFormGroup>*/}

//                                         <StyledFormGroup controlId="formBasicPassword">
//                                             <StyledFormControl
//                                                 type="password"
//                                                 placeholder="Password"
//                                                 value={password}
//                                                 onChange={(e) => setPassword(e.target.value)}
//                                                 hasError={errors.password1}
//                                                 onClick={() => setErrors({ ...errors, password1: false })}
//                                             />
//                                             {errors.password1 ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a strong password.</StyledParagraph> : <></>}


//                                         </StyledFormGroup>
//                                         {password.length != 0 ?
//                                             <PasswordStrengthBar password={password} /> : <></>}
//                                         <StyledFormGroup controlId="formVerifyPassword">
//                                             <StyledFormControl
//                                                 type="password"
//                                                 placeholder="Verify Password"
//                                                 value={verifyPassword}
//                                                 onChange={(e) => setVerifyPassword(e.target.value)}
//                                                 hasError={errors.password2}
//                                                 onClick={() => setErrors({ ...errors, password2: false })}
//                                             />
//                                             {errors.password2 ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please verify your password.</StyledParagraph> : <></>}

//                                         </StyledFormGroup>

//                                         <StyledButton variant="primary" type="submit" className="mt-1 w-100">
//                                             Get Started
//                                         </StyledButton>
//                                     </StyledForm>
//                                     <div className='text-center'>
//                                         {isLoading && <StyledParagraph className='mt-3'>Signing in...</StyledParagraph>}
//                                         {message && <StyledParagraph className='mt-3'>{message}</StyledParagraph>}
//                                         <StyledParagraph className='mt-3'> Have an account? <a href="/signin"> Log In.</a></StyledParagraph>
//                                         <StyledParagraph className='mt-3'> Don't have an invite? <a href="https://prospinity.com"> Apply here.</a></StyledParagraph>
//                                         <StyledParagraph className='mt-3'> By joining the platform, you consent to our <a href="/privacypolicy"> Privacy Policy</a> and <a href="/termsofuse"> Terms of Use</a>.</StyledParagraph>
//                                     </div>
//                                 </StyledCard.Body>
//                             </StyledCard>
//                         </Col>
//                     </Row>

//                 </Container>}
//         </>
//     );

// }




// export default SignUp;




