import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";

import { SimpleTextPage } from "./SurveyScreens";
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";


export default function ScreenReadOne() {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const navigate = useNavigate();
  const typingSpeed = 85;
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    // Track
    let uniqueId = Cookies.get("unique_id");

    if (!uniqueId) {
      uniqueId = uuidv4();
      Cookies.set("unique_id", uniqueId, { expires: 30 });    
    }

    // Use sendBeacon if supported for better reliability
    const formData = new FormData();
    formData.append("unique_id", uniqueId);
    formData.append("screen4", "Here");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, formData);

  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "black";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleTypingDone = () => {
    setIsTypingDone(true);
  };

  const handleClick = () => {
    setIsFadingOut(true);
    setTimeout(() => navigate("/berkeley/application/5"), 600); // Wait for animation before navigating
  };

  const firstPageText = [
    {
        sequences: [
          { content: 700, wrapper: "span", style: {} },
          { content: "A Success Pool is an ", wrapper: "span", style: {} },
          { content: "agreement", wrapper: "strong", style: {} },
          { content: " between a group of students.", wrapper: "span", style: {} },
        ],
    },
    {
        sequences: [
          { content: 500, wrapper: "span", style: {} },
          { content: "Every member of the group agrees to ", wrapper: "span", style: {} },
          { content: "evenly split a small percentage", wrapper: "strong", style: {} },
          { content: " of their ", wrapper: "span", style: {} },
          { content: "future earnings", wrapper: "strong", style: {} },
          { content: " with everyone else.", wrapper: "span", style: {} },
          { content: 500, wrapper: "span", style: {} },
        ]
    },
    {
      sequences: [
        { content: "The agreement kicks in ", wrapper: "span", style: {} },
        { content: "after graduation.", wrapper: "strong", style: {} },
        { content: 500, wrapper: "span", style: {} },

      ],
  },
  ];
  
  return (
    <AnimatePresence>
      {!isFadingOut && (
        <motion.div
          key="screen-one"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} // Smooth fade-out
          transition={{ duration: 0.6, ease: "easeOut" }} // Smooth easing
        >
          {/* Text content */}
          <SimpleTextPage
            paragraphs={firstPageText}
            typing={true}
            typingSpeed={typingSpeed}
            onTypingDone={handleTypingDone} // Ensures we track typing completion
          />
  
          {/* Buttons (only appear after typing is done) */}
          {isTypingDone && (
            <motion.div
              key="buttons"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }} // Ensure buttons fade with text
              transition={{ duration: 0.6 }}
              style={{ width: "100%" }}
            >
              <SurveyOptionContainer isMdOrLarger={isMdOrLarger}>
                <SurveyOptionButton
                  key="NEXT"
                  onClick={handleClick}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"OKAY..."}
                </SurveyOptionButton>
              </SurveyOptionContainer>
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
 }  
