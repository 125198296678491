import { useMediaQuery } from "react-responsive";
import { RegistrationLayoutDesktop } from "./RegistrationLayoutDesktop.js"
import { RegistrationLayoutMobile } from "./RegistrationLayoutMobile.js"

export function RegistrationLayout() {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return isMobile ? <RegistrationLayoutMobile /> : <RegistrationLayoutDesktop />;
}

export default RegistrationLayout;