import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import { faQuestion, faUser, faSignOut,faGear } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { styled, useTheme } from 'styled-components';
import { SearchIcon, NotificationsIcon, ArrowDown } from '../../assets/icons/Icons';

  
const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  width: calc(100% - 260px); /* Adjust for sidebar */
  height: 80px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  background-color: inherit;
  /* border-bottom: 1px solid ${({ theme }) => theme.divider}; */
  box-sizing: border-box;
`;

/* Search Bar */
const NavbarSearch = styled.div`
  display: flex;
  align-items: center;
  max-width: 650px;
  width: 100%;
  height: 48px;
  padding: 0 1.3rem;
  border-radius: 80px;
  margin-right: 1rem;
  background-color: ${({ theme }) => theme.white};
  box-sizing: border-box;
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.textSecondary};
  background-color: transparent;
  margin-left: 0.8rem;
`;

/* Right Section */
const NavbarRight = styled.div`
  display: flex;
  align-items: center;
  gap: 0rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
`;

/* Notification */
const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const NotificationIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 50%;
`;

const NotificationDot = styled.div`
  position: absolute;
  top: 29%;
  right: 29%;
  width: 0.6rem;
  height: 0.6rem;
  background-color: ${({ theme }) => theme.red};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.border};
  z-index: 1;
`;

/* User Profile */
const NavbarProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  cursor: pointer;
`;

const ProfilePictureWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.border};
  overflow: hidden;
  flex-shrink: 0;
`;

const ProfilePicture = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfileName = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textPrimary};
`;



export default function NavbarLargeScreen() {
    // Theme
    const theme = useTheme();

    return (
        <NavbarContainer theme={theme}>
            {/* Search Bar */}
            <NavbarSearch theme={theme}>
                <SearchIcon theme={theme} />
                <SearchInput type="text" placeholder="Search users" theme={theme} />
            </NavbarSearch>

            {/* Right Section */}
            <NavbarRight>
                {/* Notifications */}
                <NotificationWrapper>
                    <NotificationIconWrapper theme={theme}>
                        <NotificationsIcon theme={theme} />
                        <NotificationDot theme={theme} />
                    </NotificationIconWrapper>
                </NotificationWrapper>

                {/* User Profile */}
                <ProfileWithPopup />
            </NavbarRight>
        </NavbarContainer>
    );
}

function ProfileWithPopup() {
    // Auth Context
    const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate, profilePictureLink } = useAuth();

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const popupRef = useRef(null);

    const togglePopup = () => setIsOpen((prev) => !prev);
    const closePopup = () => setIsOpen(false);

    // Theme
    const theme = useTheme();

    const logOutNavigate = () => {
        handleLogout();
        navigate('/signing-out');

    }

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            closePopup();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div style={{ position: 'relative' }} ref={popupRef}>
            {/* Profile Trigger */}
            <NavbarProfile onClick={togglePopup}>
                <ProfilePictureWrapper theme={theme}>
                    <ProfilePicture
                        src={profilePictureLink}
                        alt={userFirstName}
                    />
                </ProfilePictureWrapper>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: "0.3rem" }} >
                    <ProfileName theme={theme}>John B.</ProfileName>
                    <ArrowDown theme={theme} />
                </div>
            </NavbarProfile>

            {/* Popup */}
            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '52px', // Adjusted to be further below
                        right: 0,
                        width: '230px', // Match the sidebar width
                        backgroundColor: theme.white,
                        borderRadius: '1.5rem',
                        zIndex: 1000,
                        padding: '1rem',
                        border: `1px solid ${theme.divider}`
                    }}
                >
                    {/* Links */}
                    <PopupLink label="Profile" icon={faUser} theme={theme} onClick={() => { navigate(`user/${userSlug}`); closePopup(); }} />
                    <PopupLink label="Settings" icon={faGear} theme={theme} onClick={() => { navigate('settings'); closePopup(); }} />
                    <PopupLink label="FAQs" icon={faQuestion} theme={theme} onClick={() => { navigate('faqs'); closePopup(); }} />
                    <PopupLink label="Logout" icon={faSignOut} theme={theme} onClick={() => { logOutNavigate(); closePopup(); }} />
                </div>
            )}
        </div>
    );
}

const PopupLink = ({ label, icon, theme, onClick }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.7rem 0.9375rem',
            borderRadius: '1.5rem',
            cursor: 'pointer',
            color: theme.textSecondary,
            transition: 'background-color 0.3s ease',
        }}
        onClick={onClick}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = theme.primaryLightHover)}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
    >
        <FontAwesomeIcon icon={icon} style={{ marginRight: '1rem' }} />
        <span>{label}</span>
    </div>
);




// import axios from 'axios';
// import React, { useState, useEffect, useRef } from 'react';
// import { Outlet, Link, useNavigate } from 'react-router-dom';
// import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
// import { useAuth } from './AuthContext';
// import './Dashboard.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
// import SearchUsers from './SearchUsers';
// import { faHome, faHandshake, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faChevronDown } from '@fortawesome/free-solid-svg-icons'; // Example icons
// import { useMediaQuery } from 'react-responsive';
// import { timeSince } from './CriticalFunctions';
// import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { useErrorBoundary } from "react-error-boundary";
// import { StyledNavbar, StyledDropdown, StyledButton, StyledH5, StyledNavLink } from './StyledBootstrap';
// import { styled, useTheme } from 'styled-components';
// import { myAxiosInstance } from './axiosConfig';
// import Notifications from './Notifications';

// import { SearchIcon, NotificationsIcon, ArrowDown } from './Icons';


// function NavbarLargeScreen({ setShowReferModal, setWhyModalShow }) {
//     const themeMode = useTheme();
//     const navigate = useNavigate();
//     const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate, profilePictureLink } = useAuth();

//     const logOutNavigate = () => {
//         handleLogout();
//         navigate('/signing-out');

//     }

//     const getBaseFontSize = () => {
//         // Get the font-size of the body element
//         const fontSize = window.getComputedStyle(document.body).fontSize;
//         // Parse the font size and return it as a number
//         return parseFloat(fontSize);
//     };



//     return (
//         <StyledNavbar expand="sm" sticky='top'>
//             <Container fluid>
//                 <div className='w-100 d-flex justify-content-between'>
                    
//                         <Navbar.Brand className="d-flex ml-2 align-items-center" onClick={() => navigate('/home')} style={{ cursor: 'pointer' }}>
//                             <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" className="d-inline-block align-top" alt="Logo" />
//                         </Navbar.Brand>
                    

//                     <div className='w-50'>
//                         <SearchUsers />
//                     </div>

//                     <div className="d-flex align-items-center">

//                         {/*<StyledButton onClick={() => setWhyModalShow(true)} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme==='dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme==='dark' ? themeMode.surface : themeMode.onPrimary}}>Why?</StyledButton>*/}
//                         {/*<StyledButton className='invite-joyrride' onClick={() => setShowReferModal(true)} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme === 'dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme === 'dark' ? themeMode.surface : themeMode.onPrimary }}>Invite</StyledButton>*/}
//                         <Notifications />
//                         <StyledDropdown >
//                             <StyledDropdown.Toggle as={Nav.Link} className="no-caret-icon profile-dropdown">
//                                 {/*<FontAwesomeIcon icon={faUser} />*/}
//                                 <div className='profile-dropdown-joyrride'>
//                                     <ProfilePicture name={userFirstName + " " + userLastName} size={getBaseFontSize() * 3} graduation_date={userGraduationDate} picture_link={profilePictureLink} />
//                                 </div>
//                             </StyledDropdown.Toggle>
//                             <StyledDropdown.Menu align={'end'}>
//                                 <StyledDropdown.Item as={Link} to={"user/" + userSlug} >
//                                     <Row>
//                                         <Col xs={2}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></Col>
//                                         <Col>Profile</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>

//                                 <StyledDropdown.Item as={Link} to="settings">
//                                     <Row>
//                                         <Col xs={2}><FontAwesomeIcon icon={faGear}></FontAwesomeIcon></Col>
//                                         <Col>Settings</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>

//                                 <StyledDropdown.Item as={Link} to="faqs">
//                                     <Row className='faqs-joyrride'>
//                                         <Col xs={2}><FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon></Col>
//                                         <Col>FAQs</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>
//                                 <StyledDropdown.Item onClick={logOutNavigate}>
//                                     <Row>
//                                         <Col xs={2}><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon></Col>
//                                         <Col>Logout</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>
//                             </StyledDropdown.Menu>
//                         </StyledDropdown>
//                     </div>
//                 </div>
//             </Container>

//         </StyledNavbar>);
// }
