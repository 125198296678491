export const lightTheme = {
    // Primary colors
    primary: "#2B00D4", // Primary Blue
    primaryHover: "#2700BF", // Slightly darker than primary
    primaryActive: "#2200AA", // Darker than primary hover

    primaryLight: "#3877FD",
    primaryLightHover: "#EBF1FF",
    primaryLightActive: "#EBF1FF",

    // Button Colors
    buttonPrimary: "#1D1D26",
    buttonPrimaryHover: "2F2F3C",
    buttonPrimaryFocused: "3E3E4D",
    buttonPrimaryDisabled: "F0F1F4",

    // Typography colors
    textPrimary: "#1D1D26",
    textSecondary: "#717173",
    textTertiary: "#99999A",
    greySecondary: "#8F95B2",

    border: "#E2E2ED",
    divider: "#E8E9ED",
    background: "#F0F1F4",
    backgroundDarker: "#F7F8FB",
    backgroundLight: "#F9FAFC",
    white: "#FFFFFF",

    // Status colors
    green: "#52BD94",
    greenHover: "#429777",
    greenActive: "#317159",

    yellow: "#FFB020",
    yellowHover: "#996A13",
    yellowActive: "#66460D",

    red: "#ff5722",
    redHover: "#A73636",
    redActive: "#7D2828",


    // old
    primaryVariant: "#3f4977", // Medium Blue

    secondary: "#F57C00", // Orange
    background: "#f1f2f5", // Almost White D6D4D3 f4f2ee f1f2f5
    surface: "#FFFFFF", // Pure White
    error: "#D32F2F", // Red
    onPrimary: "#FEFCFB", // Almost White on Primary
    onSecondary: "#FEFCFB", // Almost White on Secondary
    onBackground: "#000000", // Dark Blue on Background
    onSurface: "#000000", // Deep Blue on Surface
    onError: "#FEFCFB", // Almost White on Error
    onHover: "#ccc",
    form: "#f1f2f5",
    highlight: "#F57C00", // Orange for highlight
    theme: "light",
    sidebar: "#f7f7f7"
};

export const darkTheme = {
    primary: "#0c2144", // Deep Blueff6c2c 0c2144  gold:D4AF37
    primaryVariant: "#0A1128", // Dark Blue
    secondary: "#76FF03", // Neon Green
    background: "#2E2E2E", // Dark Grey0c2144  #2E2E2E
    surface: "#121212", // Darker Grey
    error: "#CF6679", // Soft Red
    onPrimary: "#FEFCFB", // Almost White on Primary FEFCFB
    onSecondary: "#0A1128", // Dark Blue on Secondary
    onBackground: "#121212",
    onSurface: "#FEFCFB", // Almost White on Surface
    onError: "#0A1128", // Dark Blue on Error
    form: "#2E2E2E",
    highlight: "#76FF03",
    theme: "dark"
};



const originalDarkTheme = {
    primary: "#001F54", // Deep Blue
        primaryVariant: "#0A1128", // Dark Blue
        secondary: "#76FF03", // Neon Green
        background: "#121212", // Dark Grey
        surface: "#2E2E2E", // Darker Grey
        error: "#CF6679", // Soft Red
        onPrimary: "#FEFCFB", // Almost White on Primary
        onSecondary: "#0A1128", // Dark Blue on Secondary
        onBackground: "#121212",
        onSurface: "#FEFCFB", // Almost White on Surface
        onError: "#0A1128", // Dark Blue on Error
};

const navyDarkTheme = {
    primary: "#001F54", // Deep Blue
        primaryVariant: "#0A1128", // Dark Blue
        secondary: "#76FF03", // Neon Green
        background: "#001F54", // Dark Grey
        surface: "#FFFFFF", // Darker Grey
        error: "#CF6679", // Soft Red
        onPrimary: "#FEFCFB", // Almost White on Primary
        onSecondary: "#0A1128", // Dark Blue on Secondary
        onBackground: "#FFFFFF", // Almost White on Background
        onSurface: "#000000", // Almost White on Surface
        onError: "#0A1128", // Dark Blue on Error
        form: "#001F54"
};


