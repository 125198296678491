import { StyledH2, StyledParagraph } from "../components/common/StyledBootstrap";
import { useTheme } from "styled-components";

function ConfidentialityAndIPAcknowledgment() {
    const themeMode = useTheme();
    return (
        <div style={{
            backgroundColor: themeMode.surface,
            minHeight: "100vh", // Ensures the div extends vertically to cover the whole viewport
            padding: "0 10%", // Adds horizontal padding
            display: "flex",
            flexDirection: "column",
            justifyContent: "center" // Optionally centers the content vertically
        }}>
            <StyledH2>Confidentiality and Intellectual Property Acknowledgment</StyledH2>
            <StyledParagraph>By accepting the invitation to access designated sections of Prospinity, you (the "Recipient") agree to the following terms:</StyledParagraph>
            <StyledParagraph><strong>Confidential Information:</strong> All content, materials, and information accessed in these sections, including but not limited to the Success Pooling Agreement ("Confidential Information"), are proprietary and confidential to Prospinity.</StyledParagraph>
            <StyledParagraph><strong>Protection of Confidentiality:</strong> The Recipient agrees to maintain the confidentiality of the Confidential Information using at least the same degree of care that you use to protect your own confidential information, but in no event less than reasonable care. You shall not disclose or permit others to disclose any Confidential Information to any third party without the prior written consent of Prospinity. Additionally, you shall not copy, reproduce, distribute, or use the Confidential Information for commercial purposes without the prior written consent of Prospinity. Use the Confidential Information solely in connection with the lawful use of Prospinity and not for any other purpose.</StyledParagraph>
            <StyledParagraph><strong>Intellectual Property:</strong> The Recipient acknowledges that all rights, title, and interest in the Confidential Information are and shall remain the exclusive property of Prospinity. No license or other rights to such Intellectual Property are granted or implied hereby except as expressly provided in this Acknowledgment.</StyledParagraph>
            <StyledParagraph><strong>Exclusions from Confidential Information:</strong> Confidential Information does not include information that: (a) was in the public domain at the time it was disclosed or has entered the public domain through no fault of the Recipient; (b) was known to the Recipient, without restriction, at the time of disclosure, as demonstrated by files in existence at the time of disclosure; (c) is disclosed with the prior written approval of Prospinity; (d) was independently developed by the Recipient without any use of the Confidential Information; or (e) comes into the Recipient's possession from a third party with a lawful right to disclose such information and who has not violated any confidentiality obligations by disclosing it.</StyledParagraph>
            <StyledParagraph><strong>No Binding Commitment:</strong> Acceptance of this invite and viewing of the Confidential Information does not constitute a binding legal commitment to any agreement or obligation except for the obligations stated in this Confidentiality and Intellectual Property Acknowledgment.</StyledParagraph>
            <StyledParagraph><strong>Duration of Confidentiality:</strong> The obligations herein shall continue indefinitely until the Confidential Information no longer qualifies as confidential or until Prospinity sends the Recipient written notice releasing them from this Acknowledgment.</StyledParagraph>
            <StyledParagraph><strong>Miscellaneous:</strong> This Acknowledgment shall be governed by and construed in accordance with the laws of Delaware. Any legal action or proceeding arising under this Acknowledgment will be brought exclusively in the courts of Delaware, and the parties hereby consent to personal jurisdiction and venue therein.</StyledParagraph>
        </div>
    );
}

export default ConfidentialityAndIPAcknowledgment;
