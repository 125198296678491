import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { motion, AnimatePresence } from "framer-motion";
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";
import { myAxiosInstance } from '../../api/axiosConfig';
import { FaShareAlt } from "react-icons/fa";
import { IoShareOutline } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import md5 from "crypto-js/md5"; // Hashing library for uniqueness
import Cookies from "js-cookie";

export default function ThankYouScreen() {
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState("");
  const forwardingLink = useRef("");

  const getReferralLink = (email) => {
    if (!email) return "https://app.prospinity.com/ref/";

    const username = email.split("@")[0]; // Extracts part before '@'
    const hash = md5(email).toString().slice(0, 4); // Shortened hash for uniqueness
    return `https://app.prospinity.com/ref/${username}-${hash}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(forwardingLink.current).then(() => {
      setCopied(true); // Show "Copied!" message
      setTimeout(() => setCopied(false), 2000); // Hide after 2 seconds
    });
  };

  const handleSubmit = async () => {
    if (!name.trim() || !email.trim()) {
      setError("Please fill in both fields.");
      return;
    } 
    if (!email.endsWith("berkeley.edu")){
      setError("We need your Berkeley email.");
      return;
    }
    setError("");
    
    // Gather answers into an object
    const formData = new FormData();

    // Get referral from storage
    const referralCode = Cookies.get("referral_code") || localStorage.getItem("referral_code") || "";

    const applicationData = {
      full_name: name || "__", // Name
      university_email: email || "__", // Email
      major: "NOT INTERESTED IN PROSPINITY", // Major
      graduation_year: "9999", // Grad Year
      linkedin_url: "", // LinkedIn
      most_impressive: "", // Most impressive thing
      future_vision: "", // Future goal
      extra_links: "", // Links
      pool_recommendations: "", // People for the pool
      additional_info: "", // Extra information
      phone_number: "", // Phone number
      referral_code: referralCode, // Referal cide
    };

    // Append text fields to FormData
    Object.keys(applicationData).forEach((key) => {
      formData.append(key, applicationData[key]);
    });


    try {
      // Send request using Axios
      const response = await myAxiosInstance.post('api/submit-application/', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {

    } finally {
      // Remove loading state

    }

    setSubmitted(true);
    forwardingLink.current = getReferralLink(email);
    return;


  };

  if (!submitted){
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.6 }}
          style={{ width: "100%" }}
        >
          <FormSection isMdOrLarger={isMdOrLarger}>
            <QuestionText isMdOrLarger={isMdOrLarger}>
              Thanks for your time.
            </QuestionText>
            <QuestionText isMdOrLarger={isMdOrLarger}>
            Since you made it to the end, you're eligible for our $2000 campus giveaway. Fill in below to enter into the raffle.
            </QuestionText>

            <SurveyInput
              isMdOrLarger={isMdOrLarger}
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <SurveyInput
              isMdOrLarger={isMdOrLarger}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            {error && <ErrorText>{error}</ErrorText>}

            <SurveyOptionContainer>
              <SurveyOptionButton onClick={handleSubmit} isMdOrLarger={isMdOrLarger}>
                Submit
              </SurveyOptionButton>
            </SurveyOptionContainer>
          </FormSection>
        </motion.div>
      </AnimatePresence>
    );
  } 
  else {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.6 }}
          style={{ width: "100%" }}
        >
          <ApplicationText isMdOrLarger={isMdOrLarger}>
            <p>You have successfully entered the raffle.</p>
            <p>If you know someone who might be interested, share this link with them.</p>
            <p>For every person who goes through the educational tutorial using your link, you'll get an extra entry in our <strong>$2000 campus-wide raffle</strong>.</p>
            <p>
              Your unique link:
            </p>
            <CopyableContainer onClick={handleCopy}>
                <CopyIcon />{forwardingLink.current}
                <CopiedMessage show={copied}>Copied</CopiedMessage>
            </CopyableContainer>
            <br />
            <ShareButton link={forwardingLink.current} />
          </ApplicationText>
        </motion.div>
      </AnimatePresence>
    );
  }
}


function ShareButton({ link }) {
  const [copied, setCopied] = useState(false);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: link,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback: Copy to clipboard
      navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <CopyableContainer onClick={handleShare}>
      <IoShareOutline />
      <span>Share</span>
      {copied && <CopiedMessage>Copied!</CopiedMessage>}
    </CopyableContainer>
  );
}

// Styled Components (Using your existing styles)
const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ isMdOrLarger }) => (isMdOrLarger ? "80%" : "100%")};
`;

const QuestionText = styled(motion.div)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.9rem" : "1.2rem")};
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
  color: rgba(255, 255, 255, 0.9);
`;

const SurveyInput = styled(motion.input)`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.5rem" : "1rem")};
  padding: 0.5rem 0;
  width: 100%;
  color: white;
  outline: none;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    border-bottom: 2px solid white;
  }
`;

const ErrorText = styled(motion.div)`
  margin-top: 0.5rem;
  font-size: 1rem;
  color: red;
`;


const ApplicationText = styled(motion.div)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.9rem" : "1.2rem")};
  line-height: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.6" : "1.4")};
  color: rgba(255, 255, 255, 0.9); /* Slightly softer white */
  padding: 1rem;
`;


const CopyableContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-weight: bold;
  position: relative;

  &:hover {
    color: white; /* Matches 'exit' button hover style */
  }
`;

const CopyIcon = styled(FaRegCopy)`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.3s;

  ${CopyableContainer}:hover & {
    color: white; /* Changes icon color on hover */
  }
`;

const CopiedMessage = styled.span`
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.3s ease;
`;